import React, { Component } from 'react';
import load from '../../ImageLoder';
import PageHOC from '../../PageHOC';
import Footer from '../Footer';
import CommonDatePicker from '../CommonDatePicker';
import moment from 'moment';
import {sendStat, getCalculatorResult} from '../../apis';
import { replaceText } from '../../resources/utils/stringUtils';
import { isDateValid, isNullOrEmpty } from '../../resources/utils/validator';
import { getTimestamp, getDateLabel, getDateObj, getNextYearObj, getDateString }from '../../resources/utils/dateUtils';
import { getCurrentLang, getVaccineCalculationStatus } from '../../resources/utils/commonUtils';
import AccessibilityWrapper from '../AccessibilityWrapper';

class VaccineCalResult extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showRecovery: false,
            isExemption: false,
            exemptionExpiryDate: {
                year: '',
                month: '',
                day: '',
            },
            isDiagnose: false,
            recoveryDate: {
                year: '',
                month: '',
                day: '',
            },
            numberOfDose: '',
            vaccinations: {
                0: '',
                1: '',
                2: '',
                3: '',
            },
            isShowDatePopup: false,
            lastVaccineDate: {
                year: '',
                month: '',
                day: '',
            },
            dateOfBirth: {
                year: '',
                month: '',
                day: '',
            },
            calculationDate: {
                year: '',
                month: '',
                day: '',
            },
            popupCalculationDate: {
                year: '',
                month: '',
                day: '',
            },
            serverDate: {
                year: '',
                month: '',
                day: '',
            },
            isFormValid: false,
            // Extra state for result page only
            vaccineDisplayStatement: '',
            exemptionDisplayStatement: '',
            vaccineQrStatus: '',
            openOtherInfo: false,
            recognisedVaccineUrl: '',
            bookVaccineUrl: '',
            vaccineInfoUrl: '',
            hcpSearchUrl: '',
            hasError: false,  //api error
            showError: false, //frontend form error
            errors: this.initialErrors(),
        };
        this.onStateDateChange = this.onStateDateChange.bind(this);
        this.formDataValidation = this.formDataValidation.bind(this);
        this.initialErrors = this.initialErrors.bind(this);
    }
    componentDidMount() {
        if (!this.props.vaccineCalcResult) {
            this.props.history.push('vaccinepasscalculator');
            return;
        }

        // get form input params from form page
        const formData = JSON.parse(sessionStorage.getItem('calculatorData'));
        const dateObj = moment(this.props.vaccineCalcResult.checkingDate, 'YYYY-MM-DD');
        
        this.setState({
            ...formData,
            ...this.props.vaccineCalcResult,
            bookVaccineUrl: this.props.vaccineCalculatorSettings && this.props.vaccineCalculatorSettings.bookVaccineUrl ?this.props.vaccineCalculatorSettings.bookVaccineUrl[0] : '',
            vaccineInfoUrl: this.props.vaccineCalculatorSettings && this.props.vaccineCalculatorSettings.VaccineInfoUrl ?this.props.vaccineCalculatorSettings.VaccineInfoUrl[0] : '',
            hcpSearchUrl: this.props.vaccineCalculatorSettings && this.props.vaccineCalculatorSettings.hcpSearchUrl ? this.props.vaccineCalculatorSettings.hcpSearchUrl[0] : '',
            calculationDate: {
                year: dateObj.year(),
                month: dateObj.month() + 1,
                day: dateObj.date(),
            },
            vpcDisclaimerEnableFlag: this.props.vaccineCalculatorSettings && this.props.vaccineCalculatorSettings.vpcDisclaimerEnableFlag ?this.props.vaccineCalculatorSettings.vpcDisclaimerEnableFlag : 'N',
            vpcDisclaimerUrlEnableFlag: this.props.vaccineCalculatorSettings && this.props.vaccineCalculatorSettings.vpcDisclaimerUrlEnableFlag ?this.props.vaccineCalculatorSettings.vpcDisclaimerUrlEnableFlag : 'N',
            vpcDisclaimerLearnMore_ENG: this.props.vaccineCalculatorSettings && this.props.vaccineCalculatorSettings.VPC_DISCLAIMER_LEARNMORE_ENG ?this.props.vaccineCalculatorSettings.VPC_DISCLAIMER_LEARNMORE_ENG : '',
            vpcDisclaimerLearnMoreURL_ENG: this.props.vaccineCalculatorSettings && this.props.vaccineCalculatorSettings.VPC_DISCLAIMER_LEARNMORE_URL_ENG ?this.props.vaccineCalculatorSettings.VPC_DISCLAIMER_LEARNMORE_URL_ENG : '',
            vpcResultDisclaimerCW_ENG: this.props.vaccineCalculatorSettings && this.props.vaccineCalculatorSettings.VPC_RESULT_DISCLAIMER_CW_ENG ?this.props.vaccineCalculatorSettings.VPC_RESULT_DISCLAIMER_CW_ENG : '',
            vpcDisclaimerLearnMore_TC: this.props.vaccineCalculatorSettings && this.props.vaccineCalculatorSettings.VPC_DISCLAIMER_LEARNMORE_TC ?this.props.vaccineCalculatorSettings.VPC_DISCLAIMER_LEARNMORE_TC : '',
            vpcDisclaimerLearnMoreURL_TC: this.props.vaccineCalculatorSettings && this.props.vaccineCalculatorSettings.VPC_DISCLAIMER_LEARNMORE_URL_TC ?this.props.vaccineCalculatorSettings.VPC_DISCLAIMER_LEARNMORE_URL_TC : '',
            vpcResultDisclaimerCW_TC: this.props.vaccineCalculatorSettings && this.props.vaccineCalculatorSettings.VPC_RESULT_DISCLAIMER_CW_TC ?this.props.vaccineCalculatorSettings.VPC_RESULT_DISCLAIMER_CW_TC : '',
            vpcDisclaimerLearnMore_SC: this.props.vaccineCalculatorSettings && this.props.vaccineCalculatorSettings.VPC_DISCLAIMER_LEARNMORE_SC ?this.props.vaccineCalculatorSettings.VPC_DISCLAIMER_LEARNMORE_SC : '',
            vpcDisclaimerLearnMoreURL_SC: this.props.vaccineCalculatorSettings && this.props.vaccineCalculatorSettings.VPC_DISCLAIMER_LEARNMORE_URL_SC ?this.props.vaccineCalculatorSettings.VPC_DISCLAIMER_LEARNMORE_URL_SC : '',
            vpcResultDisclaimerCW_SC: this.props.vaccineCalculatorSettings && this.props.vaccineCalculatorSettings.VPC_RESULT_DISCLAIMER_CW_SC ?this.props.vaccineCalculatorSettings.VPC_RESULT_DISCLAIMER_CW_SC : ''
        });

    }

    componentWillUpdate(nextProps, nextState) {
        if (nextState.hasError || (nextProps.vaccineCalculatorSettings && nextProps.vaccineCalculatorSettings.enablePageFlag !== 'Y')) {
            this.props.history.push('maintenance');
        }

        const validationResponse = this.formDataValidation(nextState);
        if (nextState.isFormValid !== validationResponse.isFormValid) {
            this.setState({ isFormValid: validationResponse.isFormValid });
        }

        // if language updated, call calculator api again to get correct lang copywriting
        if (this.props.locale !== nextProps.locale) {
            let newLang = 'en';
            if (nextProps.locale === 'zh-CN') {
                newLang = 'sc';
            } else if (nextProps.locale === 'zh-HK') {
                newLang = 'tc';
            }
            this.setState({
                lang: newLang
            }, () => this.callGetCalResultAPI());
        }

        // Set server dtm when loaded
        if (this.state.serverDate.year === '' && nextProps.serverDtm) {
            this.setState({
                serverDate: getDateObj(nextProps.serverDtm),
            })
        }
    }

    initialErrors() {
        return {
            calculationDate: false,
        };
    }
    
    onStateDateChange({ year = '', month = '', day = '' }, stateKey) {
        const newDate = {
            year: year ? year : this.state[stateKey].year,
            month: month ? month : this.state[stateKey].month,
            day: day ? day : this.state[stateKey].day,
        };
        this.setState({
            [stateKey]: newDate,
        });
    }

    formDataValidation(nextState) {
        if (!nextState) {
            return false;
        }

        let isFormValid = true;
        const {
            calculationDate
        } = nextState;
        const errors = this.initialErrors();

        if (calculationDate && !isDateValid(calculationDate)) {
            isFormValid = false;
            errors.calculationDate = true;
        }
        return { isFormValid, errors };
    }

    async onSubmitDateChangeForm() {
        this.setState({
            isShowDatePopup:
                !this.state.isShowDatePopup,
            calculationDate:
                this.state.popupCalculationDate,
        }, () => {
            this.onConfirmCalculation();
        });
    }

    async onCalculateAgain() {
        await this.callSendStatAPI('AccessPWebVaccineCalculator','Y');
        this.props.history.push('vaccinepasscalculator', { isCalculateAgain: true });
    }

    async callSendStatAPI(operationCd, searchVarchar2){
        let result;
        const param = new URLSearchParams(this.props.location.search);
        
        try{
            const data = {
                "operationCd": operationCd,
                "ehrNoHash": null,
                "searchVarchar1": param.get('srcSystem') ? param.get('srcSystem') : "PWEB",
                "searchVarchar2": searchVarchar2,
                "searchVarchar3": null,
                "searchVarchar4": null,
                "searchVarchar5": null,
                "searchVarchar6": null,
                "searchVarchar7": null,
                "searchVarchar8": null,
                "searchVarchar9": null,
                "searchVarchar10": null,
                "searchInt1": null,
                "searchInt2": null,
                "searchInt3": null,
                "searchInt4": null,
                "searchInt5": null,
                "searchInt6": null,
                "searchInt7": null,
                "searchInt8": null,
                "searchInt9": null,
                "searchInt10": null,
                "birthDate": null,
                "exactDobFlag": null,
                "loginFlag": "N"
            }
            result = await sendStat(getCurrentLang(), data);
        }catch(err){
            console.log('err: ' + err);
            this.setState({ hasError: true });
        }finally{
            if(result && result.status !== 200){
                this.setState({ hasError: true });
            }
        }
    }

    async callGetCalResultAPI(){
        let result;
        let takenVaccineList = '';
        let lastVaccineDate = null;

        if (parseInt(this.state.numberOfDose) === 0){
            takenVaccineList = null;
        } else if (parseInt(this.state.numberOfDose) === this.state.doseLimit) {
            // Call api by dummy vaccine list and last vaccine date to fulfil "no input when numOfDose = doseLimit" requirement
            takenVaccineList= [];
            for (let i=0; i<this.state.doseLimit; i++) {
                takenVaccineList.push({listOfVaccine : this.state.vaccinationOptions[0]['id']});
            }
            lastVaccineDate = getDateString(this.state.calculationDate);
        } else{
            takenVaccineList = [];
            for (let i = 0; i < parseInt(this.state.numberOfDose); i++) {
                takenVaccineList.push({listOfVaccine : this.state.vaccinations[i]});
            }
            lastVaccineDate = getDateString(this.state.lastVaccineDate)
        }

        try{
            this.callSendStatAPI('CalculatePWebVaccineResult', 'Y');
            const data = {
                phaseId: parseInt(this.props.vaccineCalculatorSettings.phaseId),
                checkingDate: getDateString(this.state.calculationDate),
                exemptionInd: this.state.isExemption ? "Y" : "N",
                exemptionExpireDate: this.state.isExemption ? getDateString(this.state.exemptionExpiryDate) : null,
                birthDate: getDateString(this.state.dateOfBirth),
                recoverInd: this.state.isDiagnose ? "Y":"N",
                recoverDate: this.state.isDiagnose ? getDateString(this.state.recoveryDate) : null,
                doseReceive: this.state.numberOfDose,
                listOfVaccineList: takenVaccineList,
                lastVaccineDate: lastVaccineDate,
                lang: getCurrentLang(),
                sourceSystem: "PWEB"
              }
            result = await getCalculatorResult(getCurrentLang(), data);
        }catch(err){
            console.log('err: ' + err);
            this.setState({ hasError: true });
        }finally{
            if(result && result.data){
                const isChild = result.data.vaccineDisplayStatement && getVaccineCalculationStatus(result.data.vaccineDisplayStatement) === 'CHILD';

                if (isChild) {
                    this.props.setVaccineCalcResult(result.data);
                    this.props.history.push('vaccine-calculator-result-child');
                } else {
                    // reload result data
                    this.setState({...result.data});
                }
            }else{
                this.setState({ hasError: true });
            }
        }
    }

    onConfirmCalculation(result) {
        if (this.state.isFormValid) {
            sessionStorage.setItem(
                'calculatorData',
                JSON.stringify(this.state)
            );

            this.callGetCalResultAPI();
        } else {
            this.setState({
                showError: true,
                errors: this.formDataValidation(this.state).errors,
            });
        }
    }

    getVccineDisplayStatement() {
        if (!isNullOrEmpty(this.state.vaccineDisplayStatement)) {
            const vaccineDisplayStatementArr = this.state.vaccineDisplayStatement.split('|');
            let str = replaceText(
                vaccineDisplayStatementArr[1], 
                this.state.latestValidDate, 
                getDateLabel(this.state.latestValidDate, this.props.locale, this.props.t)
            );
            if (str.endsWith('</b>')) {
                str = replaceText(
                    str,
                    '<b>',
                    '<br/><b>'
                )
            }
            return str;
        } else {
            return '';
        }
    }

    getVaccineResultImgSrc() {
        const vaccineStatus = getVaccineCalculationStatus(this.state.vaccineDisplayStatement);
        if (vaccineStatus === 'ALL_PASS') {
            return './resources/images/calculator-result-all-pass.png';
        } else if (vaccineStatus === 'PARTIAL_PASS') {
            return './resources/images/calculator-result-allow.png';
        } else {
            return './resources/images/calculator-result-not-allow.png';
        }
    }

    getVpcResultDisclaimerCW() {
        if (!isNullOrEmpty(this.state.vpcResultDisclaimerCW_ENG) && !isNullOrEmpty(this.state.vpcResultDisclaimerCW_TC) && !isNullOrEmpty(this.state.vpcResultDisclaimerCW_SC)) {
            if(localStorage.getItem('lang')==='zh-CN'){
                return this.state.vpcResultDisclaimerCW_SC;
            }else if(localStorage.getItem('lang')==='zh-HK'){
                return this.state.vpcResultDisclaimerCW_TC;
            }else{
                return this.state.vpcResultDisclaimerCW_ENG;
            }
        } else {
            return '';
        }
    }

    getLearnMoreText() {
        if (!isNullOrEmpty(this.state.vpcDisclaimerLearnMore_ENG) && !isNullOrEmpty(this.state.vpcDisclaimerLearnMore_TC) && !isNullOrEmpty(this.state.vpcDisclaimerLearnMore_SC)) {
            if(localStorage.getItem('lang')==='zh-CN'){
                return this.state.vpcDisclaimerLearnMore_SC;
            }else if(localStorage.getItem('lang')==='zh-HK'){
                return this.state.vpcDisclaimerLearnMore_TC;
            }else{
                return this.state.vpcDisclaimerLearnMore_ENG;
            }
        } else {
            return '';
        }
    }

    getLearnMoreURL() {
        if (!isNullOrEmpty(this.state.vpcDisclaimerLearnMoreURL_ENG) && !isNullOrEmpty(this.state.vpcDisclaimerLearnMoreURL_TC) && !isNullOrEmpty(this.state.vpcDisclaimerLearnMoreURL_SC)) {
            if(localStorage.getItem('lang')==='zh-CN'){
                return this.state.vpcDisclaimerLearnMoreURL_SC;
            }else if(localStorage.getItem('lang')==='zh-HK'){
                return this.state.vpcDisclaimerLearnMoreURL_TC;
            }else{
                return this.state.vpcDisclaimerLearnMoreURL_ENG;
            }
        } else {
            return '';
        }
    }
    
    render() {
        const { locale, t, serverDtm } = this.props;
        const isQrStatusValid = this.state.vaccineQrStatus === 'VALID';
        const extraVaccineDisplayStatementArr = this.state.extraVaccineDisplayStatement && this.state.extraVaccineDisplayStatement.split('|');
        const nextYear = getNextYearObj(this.state.serverDate);

        return (
            <div
                className={`page-container page-container-${locale}`}
                id='MainContent'
            >
                <div
                    className='container-fluid calculator-container'
                    style={{
                        background: `url(${load(
                            './resources/images/calculator-bg.png'
                        )}) 0% 0% no-repeat`,
                    }}
                >
                    <div className='container'>
                        <div className='calculator-decoration1'>
                            <img
                                id=''
                                className='calculator-decoration1-img'
                                src={load(
                                    `./resources/images/calculator-decoration1.png`
                                )}
                            />
                        </div>
                        <div className='calculator-decoration2'>
                            <img
                                id=''
                                className='calculator-decoration2-img'
                                src={load(
                                    `./resources/images/calculator-decoration2.png`
                                )}
                            />
                        </div>
                        <div className='calculator-decoration3'>
                            <img
                                id=''
                                className='calculator-decoration3-img'
                                src={load(
                                    `./resources/images/calculator-decoration3.png`
                                )}
                            />
                        </div>
                        <div className='calculator-title-container'>
                            <h2 
                                id='calculator-title-text'
                                className='calculator-title'>
                                {t('Vaccine Pass Calculator')}
                            </h2>
                            <img
                                id='calculator-title-character-img'
                                className='calculator-title-character'
                                src={load(
                                    `./resources/images/calculator-title-character.png`
                                )}
                            />
                        </div>

                        <div className='calculator-form-container calculator-result-container'>
                            <div
                                className={`calculator-result result-fix-width ${
                                    isQrStatusValid
                                        ? 'allow'
                                        : 'not-allow'
                                }`}
                            >
                                <div className='result-top'>
                                    <div className='result-big-text-container'>
                                        <img
                                            id={`${
                                                isQrStatusValid
                                                    ? 'result-allow-text-img'
                                                    : 'result-not-allow-text-img'
                                            }`}
                                            className='result-big-text'
                                            src={load(
                                                `${
                                                    isQrStatusValid
                                                        ? './resources/images/calculator-allow-text-edited.png'
                                                        : './resources/images/calculator-not-allow-text.png'
                                                }`
                                            )}
                                        />
                                    </div>
                                    <div 
                                        id='result-date-container'
                                        className='result-date-container'>
                                        <div className='result-date-first-row'>
                                            <span>{extraVaccineDisplayStatementArr && extraVaccineDisplayStatementArr[0]}{' '}</span>
                                            <span 
                                                id='result-checking-date'
                                                className='result-date' >
                                                {getDateLabel(this.state.checkingDate, this.props.locale, this.props.t)}
                                            </span>
                                            <button 
                                                id='result-another-date-btn'
                                                onClick={() => {
                                                    window.scrollTo(0, 0);
                                                    this.setState({
                                                        isShowDatePopup:
                                                            !this.state.isShowDatePopup,
                                                    });
                                                }}
                                                alt={t('vaccinePassCalculator.form.checkingDate.open')}
                                                tabIndex='0'
                                            > {t('Another date')}</button>
                                        </div>
                                        <p 
                                            id='result-statement-1'
                                            className='result-title-on-date'>
                                            {extraVaccineDisplayStatementArr && extraVaccineDisplayStatementArr[2]}
                                        </p>
                                    </div>
                                </div>
                                <img
                                    id={`${
                                        isQrStatusValid
                                            ? 'result-allow-img'
                                            : 'result-not-allow-img'
                                    }`}
                                    className='calculator-result-maingraphic'
                                    src={load(this.getVaccineResultImgSrc())}
                                    alt={isQrStatusValid ? t('vaccinePassCalculator.result.validVaccinePass') : t('vaccinePassCalculator.result.invalidVaccinePass')}
                                />

                                <div className='result-main-text'>
                                    <span
                                        id='result-statement-2'
                                        dangerouslySetInnerHTML={{
                                            __html: this.getVccineDisplayStatement()
                                        }}
                                    ></span>
                                </div>
                            </div>
                            {
                                (this.state.exemptionDisplayStatement || this.state.recoverDisplayStatement) && 
                                <div className='result-other-info result-fix-width'>
                                    <div
                                        id='other-info-title'
                                        className='other-info-title'
                                        onClick={() => {
                                            this.setState({
                                                openOtherInfo:
                                                    !this.state.openOtherInfo,
                                            });
                                        }}
                                        alt={
                                            this.state.openOtherInfo 
                                            ? t('vaccinePassCalculator.result.otherInfoBtnCollapse') 
                                            : t('vaccinePassCalculator.result.otherInfoBtnExpand')
                                        }
                                    >

                                        <img
                                            id='other-info-toggle-img'
                                            className='other-info-toggle'
                                            src={load(
                                                `./resources/images/other-info-toggle.png`
                                            )}
                                            alt={
                                                this.state.openOtherInfo 
                                                ? t('vaccinePassCalculator.result.otherInfoBtnCollapse') 
                                                : t('vaccinePassCalculator.result.otherInfoBtnExpand')
                                            }
                                        />{' '}
                                        {t('Other information')}
                                    </div>
                                    {
                                        this.state.openOtherInfo && (
                                        <div 
                                            id='other-info-content'
                                            className='other-info-content'>
                                            {this.state
                                                .exemptionDisplayStatement && (
                                                <span
                                                    id='other-info-medic'
                                                    dangerouslySetInnerHTML={{
                                                        __html: replaceText(
                                                            this.state.exemptionDisplayStatement, 
                                                            this.state.exemptionExpireDate, 
                                                            getDateLabel(this.state.exemptionExpireDate, this.props.locale, this.props.t)
                                                        ),
                                                    }}
                                                ></span>
                                            )}
                                            <br />
                                            {this.state.recoverDisplayStatement && (
                                                <span
                                                    id='other-info-recovery'
                                                    dangerouslySetInnerHTML={{
                                                        __html: replaceText(
                                                            this.state.recoverDisplayStatement, 
                                                            this.state.recoverExpireDate, 
                                                            getDateLabel(this.state.recoverExpireDate, this.props.locale, this.props.t)
                                                        ),
                                                    }}
                                                ></span>
                                            )}
                                        </div>
                                    )}
                                </div>
                            }

                            <div className='result-button-container result-fix-width'>
                                <AccessibilityWrapper
                                    className="result-button"
                                    id='calculate-again-btn'
                                    onClick={() => this.onCalculateAgain()}
                                    alt={t('vaccinePassCalculator.result.calculateAgainBtn')}
                                >
                                    {t('Calculate again')}
                                </AccessibilityWrapper>
                                <AccessibilityWrapper
                                    className="result-button"
                                    id='choose-another-date-btn'
                                    onClick={() => {
                                        window.scrollTo(0, 0);
                                        this.setState({
                                            isShowDatePopup:
                                                !this.state.isShowDatePopup,
                                        });
                                    }}
                                    alt={t('vaccinePassCalculator.form.checkingDate.open')}
                                >
                                    {t('Choose another date')}
                                </AccessibilityWrapper>
                                <div className='clear'></div>
                                <div className='disclaimer-text'>
                                    {this.state.vpcDisclaimerEnableFlag === 'Y' 
                                        ? <p id='disclaimer-text' dangerouslySetInnerHTML={{__html: this.getVpcResultDisclaimerCW()}} />
                                        : ''}
                                </div>
                                <div className='disclaimer-url'>
                                    <a
                                        href={this.getLearnMoreText() ? this.getLearnMoreURL() : ''}
                                        target="_blank"
                                        id='disclaimer-url'
                                    >
                                        {
                                            this.state.vpcDisclaimerEnableFlag === 'Y' && this.state.vpcDisclaimerUrlEnableFlag === 'Y'
                                            ? this.getLearnMoreText()
                                            : ''
                                        }
                                    </a>
                                </div>
                                <div className='clear'></div>
                                <div className='other-action-button'>
                                    <a
                                        href={this.state.bookVaccineUrl ? this.state.bookVaccineUrl[getCurrentLang()] : ''}
                                        target="_blank"
                                        className='result-bottom-btn'
                                        id='result-booking'
                                        alt={t('vaccinePassCalculator.result.bookVaccinationBtn')}
                                    >
                                        {t('Book Vaccination')}
                                    </a>
                                    <a
                                        href={this.state.vaccineInfoUrl ? this.state.vaccineInfoUrl[getCurrentLang()] : ''}
                                        target="_blank"
                                        className='result-bottom-btn'
                                        id='result-website'
                                        alt={t('vaccinePassCalculator.result.vaccinePassWebsiteBtn')}
                                    >
                                        {t('Visit Vaccine Pass Website')}
                                    </a>
                                    <a
                                        href={this.state.hcpSearchUrl ? this.state.hcpSearchUrl[getCurrentLang()] : ''}
                                        target="_blank"
                                        className='result-bottom-btn'
                                        id='result-doctor-search'
                                        alt={t('vaccinePassCalculator.result.drsSearchBtn')}
                                    >
                                        {t('Find your healthcare provider')}{' '}
                                        <img
                                            id=''
                                            className='result-doctor-search-icon'
                                            src={load(
                                                `./resources/images/result-doctor-search-icon.png`
                                            )}
                                        />
                                    </a>
                                </div>

                                <div 
                                    id='disclaimer-text'
                                    className='calculator-disclaimer'>
                                    <span
                                        dangerouslySetInnerHTML={{
                                            __html: t('Disclaimer text')
                                        }}
                                    ></span>
                                </div>
                            </div>
                        </div>
                        <div className='calculator-app-section'>
                            <img
                                id='calculator-men-img'
                                className='calculator-men-character'
                                src={load(
                                    `./resources/images/calculator-men.png`
                                )}
                            />
                            <div 
                                id='calculator-app-text'
                                className='calculator-app-text'>
                                {t(
                                    'Use the Vaccine Pass Calculator on 醫健通 eHealth App to calculate all information for you to'
                                )}
                                <br />
                                <b>
                                    {t(
                                        'check your Vaccine Pass status in one tap.'
                                    )}
                                </b>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isShowDatePopup && (
                    <>
                        <div className='calculator-datepicker-overlay'></div>
                        <div className='calculator-datepicker-popup'>
                            <img
                                onClick={() => {
                                    this.setState({
                                        isShowDatePopup:
                                            !this.state.isShowDatePopup,
                                        popupCalculationDate:
                                            this.state.calculationDate,
                                    });
                                }}
                                id=''
                                className='close-popup-icon'
                                src={load(
                                    `./resources/images/covid19-popup/popup-close-desktop.png`
                                )}
                                alt={t('common.closeBtn')}
                                tabIndex='0'
                            />
                            <h3>{t('Choose another date')}</h3>
                            <CommonDatePicker
                                t={t}
                                locale={locale}
                                type={'anotherDate'}
                                initialDate={this.state.calculationDate}
                                onDateChange={({ year = '', month = '', day = '' }) => this.onStateDateChange({ year, month, day }, 'popupCalculationDate')}
                                minDate={{
                                    year: this.state.serverDate.year,
                                    month: this.state.serverDate.month,
                                    day: this.state.serverDate.day,
                                }}
                                maxDate={{
                                    year: nextYear.year,
                                    month: nextYear.month,
                                    day: nextYear.day,
                                }}
                                alt={{
                                    year: t('vaccinePassCalculator.form.checkingDate.year'),
                                    month: t('vaccinePassCalculator.form.checkingDate.month'),
                                    day: t('vaccinePassCalculator.form.checkingDate.day')
                                }}
                            />
                            {this.state.showError &&
                                this.state.errors.calculationDate && (
                                    <div className='error-message'>
                                        {t('mandatoryErrMsg')}
                                    </div>
                                )}
                            <div className='clear'></div>
                            <AccessibilityWrapper
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.onSubmitDateChangeForm();
                                }}
                                className='calculator-submit'
                                alt={t('Calculate now')}
                            >
                                {t('Calculate now')}
                            </AccessibilityWrapper>
                        </div>
                    </>
                )}

                <Footer {...this.props} />
            </div>
        );
    }
}

export default PageHOC(VaccineCalResult);

import React, { Component } from 'react';
import load from './../../../ImageLoder';
import '../../../resources/styles/bannerSlider.scss';

class BannerSlider extends Component{
    constructor(props){
        super(props);
        this.state = { 
            swipeX: null,
            currentSlide: 0,
            numberOfImage: 3,
            sliderInterval: null
        };
    }

    componentDidMount = () => {
        this.initSlider();
    }

    componentWillUnmount(){
        clearInterval(this.state.sliderInterval);
    }

    initSlider = () => {
        const sliderTimerInSecond = 10;
        const interval = setInterval(this.nextSlide, sliderTimerInSecond * 1000);
        this.setState({
            sliderInterval: interval
        })
    }

    nextSlide = () => {
        let { currentSlide, numberOfImage } = this.state;
        const that = this;
        if(currentSlide === numberOfImage - 1){
            that.setState({currentSlide: 0});
        }else{
            that.setState({currentSlide: currentSlide + 1});
        }
        this.resetInterval()
        
    }

    prevSlide = () => {
        let { currentSlide, numberOfImage } = this.state;
        const that = this;
        if(currentSlide === 0){
            that.setState({currentSlide: numberOfImage - 1});
        }else{
            that.setState({currentSlide: currentSlide - 1});
        }
        this.resetInterval()
    }

    resetInterval = () => {
        clearInterval(this.state.sliderInterval);
        this.initSlider()
    }

    getTouches = (e) =>  {
        return e.touches ||             // browser API
               e.originalEvent.touches; // jQuery
    }        

    handleTouchStart = (e) => {
        const touch = this.getTouches(e)[0];                                                        
        this.setState({swipeX: touch.clientX})                                                    
    };      
    
    handleTouchMove = (e) => {
        if(!this.state.swipeX){
            return;
        }
        let swipeXEnd = e.touches[0].clientX;  
        let xDiff = this.state.swipeX - swipeXEnd;
        if ( xDiff > 0 ) {
            /* left swipe */ 
            this.nextSlide();
        } else {
            /* right swipe */
            this.prevSlide();
        } 
        this.setState({swipeX: null})  
    }


    render() {
        const {t, locale, languageCodeMap} = this.props;
        let ehealthSurveyURL;

        if(locale === "en"){
            ehealthSurveyURL= "https://www.surveymonkey.com/r/ND5S5GB";
        }else if(locale === 'zh-CN'){
            ehealthSurveyURL= "https://www.surveymonkey.com/r/NDFKZN5";
        }else if(locale === 'zh-HK'){
            ehealthSurveyURL= "https://www.surveymonkey.com/r/LS8TKTZ";
        }

        return (
            <div lang={locale} className="ehealth-website-banner" onTouchStart={this.handleTouchStart} onTouchMove={this.handleTouchMove}>
                <div id="banner_0" className={this.state.currentSlide % this.state.numberOfImage === 0 ? `fade-in` : `hide`}>
                    <img className="display-desktop ehealth-website-image" src={load(`./resources/images/truck-slider-1.jpg`)} alt={t("Brand new function of eHealth App is available now! Health Management helps you keep track of your blood pressure or blood sugar record with ease. Click here for more information.")} />
                    <img className="display-mobile ehealth-website-image" src={load(`./resources/images/truck-slider-1.jpg`)} alt={t("Brand new function of eHealth App is available now! Health Management helps you keep track of your blood pressure or blood sugar record with ease. Click here for more information.")} />
                </div>
                <div id="banner_1" className={this.state.currentSlide % this.state.numberOfImage === 1 ? `fade-in` : `hide`}>
                    <img className="display-desktop ehealth-website-image" src={load(`./resources/images/truck-slider-3.jpg`)} alt={t("What do you think of e Health? We would love to hear from you!Please take the survey now!")} />
                    <img className="display-mobile ehealth-website-image" src={load(`./resources/images/truck-slider-3.jpg`)} alt={t("What do you think of e Health? We would love to hear from you!Please take the survey now!")} />
                </div>        
                <div id="banner_2" className={this.state.currentSlide % this.state.numberOfImage === 2 ? `fade-in` : `hide`}>
                    <img className="display-desktop ehealth-website-image" src={load(`./resources/images/truck-slider-2.jpg`)} alt={t("What do you think of e Health? We would love to hear from you!Please take the survey now!")} />
                    <img className="display-mobile ehealth-website-image" src={load(`./resources/images/truck-slider-2.jpg`)} alt={t("What do you think of e Health? We would love to hear from you!Please take the survey now!")} />
                </div>         
                <div id="home_banner_nav">
                    <img onKeyDown={(e) => e.keyCode === 13 && this.prevSlide()} onClick={this.prevSlide} id="home_slider_nav_left" src={load(`./resources/images/slider-left.png`)} alt={t('Previous')} tabIndex="0"></img>
                    <img onKeyDown={(e) => e.keyCode === 13 && this.nextSlide()} onClick={this.nextSlide} id="home_slider_nav_right" src={load(`./resources/images/slider-right.png`)} alt={t('Next')} tabIndex="0"></img>
                </div>
            </div>
        );
    }
}

export default BannerSlider;
import React, { Component } from 'react'
import load from '../../ImageLoder';
import PageHOC from '../../PageHOC';
import CommonPhoneSlider from '../CommonPhoneSlider';
import Footer from '../Footer';

class Covid19MinorsPage extends Component {
    render() {
        const {locale, t, languageCodeMap} = this.props;
        let adolescentApplicationUrl= `https://www.ehealth.gov.hk/${languageCodeMap[locale]['code']}/you-and-your-family/how-to-register/register-oles.html`;
        return (
            <div className={`page-container page-container-${locale}`} id="MainContent">
                <div className="static_page_header static_page_header_adolescent">
                    <div class="static_page_header_bg" style={{background: `url(${load('./resources/images/adolescent_top_bg.png')}) no-repeat 60%`}}></div>
                    <div className="container">
                        <div className="row vertical-align">
                            <div className="col-sm-6">
                            <img id="imgCovidAdolescentTopBannerImage" data-aos="fade-up" data-aos-anchor-placement="center-center" data-aos-offset="0" src={load(`./resources/images/${locale}/covid19-adolescent-top-banner.png`)} alt=" "/>
                            </div>
                            <div className="col-sm-6 static-page-header-left" role="text" aria-label={t("Person aged under 16 can register with eHealth via COVID-19 Vaccination Programme now")}>
                                <p id="textCovidAdolescentTopBannerTitle" data-aos="fade-up" data-aos-anchor-placement="center-center" data-aos-offset="-300" data-aos-delay="200"  className="static_page_top_title" tabIndex="-1">{t("Person aged under 16")}</p>
                                <p id="textCovidAdolescentTopBannerSubTitle" data-aos="fade-up" data-aos-anchor-placement="center-center" data-aos-offset="-400" data-aos-delay="300"  className="static_page_top_subtitle" tabIndex="-1">{t("can register with eHealth via COVID-19 Vaccination Programme now")}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="textCovidAdolescentMainTitle1" data-aos="fadein" data-aos-anchor-placement="center-center" data-aos-offset="-400" className="static_page_section_title" role="text" aria-label={t("How to register eHealth for child via COVID-19 Vaccination Programme ALT")}>
                    {t("How to register eHealth for child via COVID-19 Vaccination Programme")}
                    <span id="CovidAdolescentMainTitle1Line" className="short_line"></span>
                </div>
                <div className="container-fluid second-feature-section">
                        <div className="container">
                            <div className="row vertical-align">
                                <div data-aos="fade-left" className="col-md-6 col-sm-12 col-sm-push-6">
                                    <div id="textCovidAdolescentSection1Title" className="second-feature-title covid-feature-title" role="text" aria-label={t("1.Submit the eHealth application online")}>1. {t("Submit the eHealth application online")}</div>
                                    <div id="btnCovidAdolescentSection1Btn" className="second-feature-text covid_feature_btn"><a href="#" onClick={()=>{window.open(adolescentApplicationUrl, "_blank")}} role="text" aria-label={t("Go now ALT")}>{t("Go now")}</a></div>
                                </div>

                                <div className="col-md-6 col-sm-12 col-sm-pull-6">
                                <img src={load(`./resources/images/empty.png`)} alt="" />
                                <img id="img1CovidAdolescentSection1" data-aos="fade-up" data-aos-anchor-placement="center-center" data-aos-offset="-300" className="absolute-image back" src={load(`./resources/images/${locale}/covid_adolescent_p1_1.png`)} alt=" "/>
                                <img id="img2CovidAdolescentSection1" data-aos="fade-up" data-aos-anchor-placement="center-center" data-aos-offset="-300" className="absolute-image back" src={load(`./resources/images/covid_adolescent_p1_2.png`)} alt=" "/>
                                </div>
                            </div>
                        </div>
                </div>

                
                <div className="container-fluid second-feature-section last-section">
                    <div className="container">
                        <div className="row vertical-align">
                            <div data-aos="fade-right" className="col-md-6 col-sm-12">
                                <div id="textCovidAdolescentSection2Title" className="second-feature-title covid-feature-title">2. {t("Print out the Online Submission Confirmation")}</div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <img src={load(`./resources/images/empty.png`)} alt="" />
                                <img id="img1CovidAdolescentSection2" data-aos="fade-up" data-aos-anchor-placement="center-center" data-aos-offset="-300" data-aos-delay="300" className="absolute-image back" src={load(`./resources/images/${locale}/covid_adolescent_p2_1.png`)} alt=" "/>
                                <img id="img2CovidAdolescentSection2" data-aos="fade-up" data-aos-anchor-placement="center-center" data-aos-offset="-300" className="absolute-image back" src={load(`./resources/images/covid_adolescent_p2_2.png`)} alt=" "/>
                          </div>
                        </div>
                    </div>
                </div>

                <div className="reminder-section" data-aos="fadein" data-aos-anchor-placement="center-center" data-aos-offset="-300" >
                    <div className="container">
                    <div className="reminder-sticker">
                        <p id="textCovidAdolescentReminderLabel" className="reminder-sticker-text">{t("Warm Reminder")}</p>
                    </div>
                        <div className="row">
                            <p id="textCovidAdolescentReminderTitle" className="reminder-title">{t("On the vaccination day, please bring along the following documents:")}</p>
                                <div className="reminder-tick-div">
                                    <img id="imgCovidAdolescentReminderPoint1Tick" className="reminder-tick" src={load(`./resources/images/reminder_tick_box.png`)} alt=" "/>
                                    <span id="textCovidAdolescentReminderPoint1Content">{t("The printout of the Online Submission Confirmation")}</span>
                                </div>
                                <div className="reminder-tick-div">
                                    <img id="imgCovidAdolescentReminderPoint2Tick" className="reminder-tick" src={load(`./resources/images/reminder_tick_box.png`)} alt=" "/>
                                    <span id="textCovidAdolescentReminderPoint2Content">{t("Identity document of your child")}</span>
                                </div>
                                <div className="reminder-tick-div">
                                    <img id="imgCovidAdolescentReminderPoint3Tick" className="reminder-tick" src={load(`./resources/images/reminder_tick_box.png`)} alt=" "/>
                                    <span id="textCovidAdolescentReminderPoint3Content">{t("Consent to administration of COVID-19 vaccination")}</span>
                                </div>
                                <div className="reminder-tick-btn">
                                    <p>
                                    <span id="textCovidAdolescentReminderleaflet" role="text" aria-label={t("You may also refer to the leaflet for information")}>{t("You may refer to the ")}</span>
                                    <a id="linkCovidAdolescentReminderleafletLink" role="text" aria-label={t("Click here to view the leaflet")} href="static/internal/eHealth_COVID-19_adolescents_leaflet-01.pdf?ver=2" target="_blank">{t("leaflet ")}</a>
                                    <span id="textCovidAdolescentReminderleaflet2" role="text" aria-label={t("You may also refer to the leaflet for information")}>{t("for information")}</span></p>
                                </div>
                        </div>
                    </div>
                </div>

                <div id="textCovidAdolescentMainTitle2" data-aos="fadein" data-aos-anchor-placement="center-center" data-aos-offset="-100" className="static_page_section_title" role="text" aria-label={t("How to view my child's COVID-19 vaccination records in the eHealth App ALT")}>
                    {t("How to view my child's COVID-19 vaccination records in the eHealth App")}
                    <span id="CovidAdolescentMainTitle2Line" className="short_line"></span>
                </div>
                <div className="container-fluid second-feature-section">
                        <div className="container">
                            <div className="row vertical-align">
                                <div data-aos="fade-left" className="col-md-6 col-sm-12 col-sm-push-6">
                                    <div id="textCovidAdolescentSection3Title" className="second-feature-title covid-feature-title" role="text" aria-label={t("1 You register with eHealth and download 醫健通eHealth App")}>1. {t("You register with eHealth and download 醫健通eHealth App")}</div>
                                </div>

                                <div className="col-md-6 col-sm-12 col-sm-pull-6">
                                <img src={load(`./resources/images/empty.png`)} alt="" />
                                <img id="img1CovidAdolescentSection3" data-aos="fade-up" data-aos-anchor-placement="center-center" data-aos-offset="-300" className="absolute-image back" src={load(`./resources/images/covid_adolescent_p3_1.png`)} alt=" "/>
                                <img id="img2CovidAdolescentSection3" data-aos="fade-up" data-aos-anchor-placement="center-center" data-aos-offset="-300" className="absolute-image back" src={load(`./resources/images/covid_adolescent_p3_2.png`)} alt=" "/>
                                </div>
                            </div>
                        </div>
                </div>

                
                <div className="container-fluid second-feature-section ">
                    <div className="container">
                        <div className="row vertical-align">
                            <div data-aos="fade-right" className="col-md-6 col-sm-12">
                                <div id="textCovidAdolescentSection4Title" className="second-feature-title covid-feature-title">2. {t("Use 醫健通eHealth App to add your child as My Family Member")}</div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                                <img src={load(`./resources/images/empty.png`)} alt="" />
                                <img id="img1CovidAdolescentSection4" data-aos="fade-up" data-aos-anchor-placement="center-center" data-aos-offset="-300" data-aos-delay="300" className="absolute-image back" src={load(`./resources/images/${locale}/covid_adolescent_p4_1.png`)} alt=" "/>
                                <img id="img2CovidAdolescentSection4" data-aos="fade-up" data-aos-anchor-placement="center-center" data-aos-offset="-300" data-aos-delay="300" className="absolute-image back" src={load(`./resources/images/${locale}/covid_adolescent_p4_2.png`)} alt=" "/>
                          </div>
                        </div>  
                    </div>
                </div>
                

                <div className="container-fluid covid_center_section">
                    <div className="container">
                        <div className="row vertical-align">
                            <div className="col-md-12">
                                <div id="textCovidAdolescentSection5Title" data-aos="fade-up" className="second-usage-title usage-mainTitle covid-main-title">{t("You can then view your child's COVID-19 vaccination records and show the vaccination record QR code anytime anywhere")}</div>
                                <img id="img1CovidAdolescentSection5" data-aos="fadein" data-aos-duration="2500" src={load(`./resources/images/${locale}/covid_adolescent_p5_1.png`)} className="covid-adolescent-bottom-phone"  alt=" "/>
                            </div>
                        </div>
                    </div>
                </div> 

                <Footer {...this.props} />

            </div>
        )
    }
}

export default PageHOC(Covid19MinorsPage);
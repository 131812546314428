export const zeroPad = (num, places = 2) => {
    return num === '' ? '01' : String(num).padStart(places, '0');
}

export const replaceText = (str, oldText, newText) => {
    if (oldText && oldText !== '' && str.includes(oldText)) {
        return str.replace(oldText, newText);
    } else {
        return str;
    }
}
import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class HealthManagementPage extends Component {
  render() {
    const { locale, t } = this.props;
    const sliderImages = [
      "management_slider_phone1.png",
      "management_slider_phone2.png",
      "management_slider_phone3.png",
    ];
    const sliderImageAlts = [
      "Screenshot of Health Management",
      "Screenshot of Health Management",
      "Screenshot of Health Management",
    ];
    const sliderImageIds = [
      "img1HealthManagementSlider",
      "img2HealthManagementSlider",
      "img3HealthManagementSlider",
    ];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"health-management-bg.png"}
        sliderID="healthManagementSlider"
        sliderTitle={t("Health Management​")}
        sliderTitleID={"texHealthManagementSliderTitle"}
        sliderText={t("Your health data diary on-the-go​ slider text")}
        sliderTextID="textHealthManamementSliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );
    return (
      <div
        className={`page-container page-container-${locale}`}
        id="MainContent"
      >
        {phoneSlider}

        <div className="container-fluid second-feature-section last-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textHealthManagementSection1Title"
                  className="second-feature-title"
                >
                  {t(
                    "Detailed record of Blood Pressure, Heart Rate and Blood Glucose​"
                  )}
                </div>
                <div
                  id="textHealthManagementSection1Content"
                  className="second-feature-text"
                >
                  {t(
                    "Easy managing your personal Blood Pressure, Heart Rate and Blood Glucose"
                  )}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="imgHealthManagementSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(
                    `./resources/images/${locale}/health-management_p1_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="imgHealthManagementSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="400"
                  className="absolute-image front"
                  src={load(`./resources/images/health-management_p1_3.png`)}
                  alt=" "
                />
                <img
                  id="imgHealthManagementSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="200"
                  className="absolute-image front"
                  src={load(`./resources/images/health-management_p1_2.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade"
          data-aos-offset="-200"
          className="second-video"
          alt={t("Video")}
        >
          <div ref={this.youtubeContainer} className="second-youtube-container">
            <iframe
              id="videoHealthManagement"
              key={locale}
              className="second-responsive-iframe"
              title="https://www.youtube.com/embed/jGnbNx7r6tA"
              src="https://www.youtube.com/embed/dY6ILQzjkBc"
              alt={t("Video")}
              tabIndex="0"
            ></iframe>
          </div>
        </div>

        <div
          data-aos="fade"
          data-aos-offset="-200"
          className="second-video two-video-bottom"
          alt={t("Video")}
        >
          <div ref={this.youtubeContainer} className="second-youtube-container">
            <iframe
              id="videoHealthManagement"
              key={locale}
              className="second-responsive-iframe"
              title="https://www.youtube.com/embed/lLXtGi_jnHg"
              src="https://www.youtube.com/embed/lLXtGi_jnHg"
              alt={t("Video")}
              tabIndex="0"
            ></iframe>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <div className="container-fluid second-feature-section last-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textHealthManagementSection2Title"
                  className="second-feature-title"
                >
                  {t("Keep track of Your BMI")}
                </div>
                <div
                  id="textHealthManagementSection2Content"
                  className="second-feature-text"
                >
                  {t("Analyse & record your weight for your height")}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="imgHealthManagementSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(
                    `./resources/images/${locale}/health-management_p3_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="imgHealthManagementSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="200"
                  className="absolute-image front"
                  src={load(
                    `./resources/images/${locale}/health-management_p3_2.png`
                  )}
                  alt=" "
                />
                <img
                  id="imgHealthManagementSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="400"
                  className="absolute-image front"
                  src={load(`./resources/images/health-management_p3_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade"
          data-aos-offset="-200"
          className="second-video"
          alt={t("Video")}
        >
          <div ref={this.youtubeContainer} className="second-youtube-container">
            <iframe
              id="videoHealthManagement"
              key={locale}
              className="second-responsive-iframe"
              title="https://www.youtube.com/embed/L_7krzPNLzo"
              src="https://www.youtube.com/embed/L_7krzPNLzo"
              alt={t("Video")}
              tabIndex="0"
            ></iframe>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <div className="container-fluid second-feature-section last-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textHealthManagementSection2Title"
                  className="second-feature-title"
                >
                  {t("Integrating your personal health record")}
                </div>
                <div
                  id="textHealthManagementSection2Content"
                  className="second-feature-text"
                >
                  {t("For reference of medical professionals and your family")}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="imgHealthManagementSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(
                    `./resources/images/${locale}/health-management_p2_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="imgHealthManagementSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="200"
                  className="absolute-image front"
                  src={load(`./resources/images/health-management_p2_2.png`)}
                  alt=" "
                />
                <img
                  id="imgHealthManagementSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="400"
                  className="absolute-image front"
                  src={load(`./resources/images/health-management_p2_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade"
          data-aos-offset="-200"
          className="second-video"
          alt={t("Video")}
        >
          <div ref={this.youtubeContainer} className="second-youtube-container">
            <iframe
              id="videoHealthManagement"
              key={locale}
              className="second-responsive-iframe"
              title="https://www.youtube.com/embed/99RYiSoYExE"
              src="https://www.youtube.com/embed/99RYiSoYExE"
              alt={t("Video")}
              tabIndex="0"
            ></iframe>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <div className="container-fluid second-feature-section light-bg-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textHealthManagementSection2Title"
                  className="second-feature-title"
                >
                  {t("Sync your health data with your device")}
                </div>
                <div
                  id="textHealthManagementSection2Content"
                  className="second-feature-text"
                >
                  {t("Health Management Managing your record made easy")}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="imgHealthManagementSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(
                    `./resources/images/${locale}/health-management_p4_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="imgHealthManagementSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="200"
                  className="absolute-image front"
                  src={load(`./resources/images/health-management_p4_2.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>
        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(HealthManagementPage);

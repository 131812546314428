import React, { Component } from "react";
import load from "../../../ImageLoder";
import PageHOC from "../../../PageHOC";
import Footer from "../../Footer";
import BannerSlider from "./BannerSlider";
import Swiper from "./Swiper";

class PromotionTruck extends Component {
  render() {
    const { locale, t } = this.props;

    return (
      <div
        className={`promulgation-promotion-truck-page page-container page-container-${locale}`}
        id="MainContent"
      >
        <div
          data-aos="fade-up"
          data-aos-offset="-200"
          className="static_page_header"
        >
          <div className="container-full">
            <div className="row vertical-align">
              <div className="col-sm-12">
                <img
                  id="imgCovidQRTopBannerImage"
                  data-aos-anchor-placement="center-center"
                  src={load(
                    `./resources/images/promulgation/interactive-pic-book/pic-book-main-img.jpg`
                  )}
                  alt=" "
                />
                {/* <img id="imgCovidQRTopBannerImage" className="display-mobile" data-aos-anchor-placement="center-center" src={load(`./resources/images/truck-main-img-mobile.jpg`)} alt=" "/> */}
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container content-title-container">
            <div
              className="content-title promulgation-page-title"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <img
                className="content-title-icon"
                src={load(
                  `./resources/images/promulgation/landing/promulgation-landing-picture-book-icon.png`
                )}
                alt=""
              />
              <span className="content-title-text">
                {t("interactive-picture-book-title")}
              </span>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container introduction-text-container">
            <div className="row vertical-align">
              <div
                data-aos="fade-up"
                data-aos-delay="400"
                data-aos-offset="-200"
                className="col-md-12 col-sm-12"
              >
                <div id="" className="promulgation-item-intro">
                  {t("interactive-picture-book-intro")}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid truck-schedule">
          <div className="container">
            <div
              className="row vertical-align"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <div className="col-sm-12">
                <br />
                <img
                  src={load(
                    `./resources/images/promulgation/interactive-pic-book/interactive-pic-book-01.jpg`
                  )}
                  alt=""
                />
              </div>
            </div>
            <div
              className="interactive-pic-book-download"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <a
                target="_blank"
                href={load(
                  `./resources/download/online_interactive_picture_book.pdf`
                )}
              >
                {t("Pic book Download")}{" "}
                <img
                  src={load(
                    `./resources/images/promulgation/interactive-pic-book/download-icon.png`
                  )}
                  alt=""
                />
              </a>
              {t("(Traditional Chinese version only)")}
              <div className="empty-space-40"></div>

              <div
                ref={this.youtubeContainer}
                className="second-youtube-container"
                data-aos="fade-up"
                data-aos-delay="200"
                data-aos-offset="-200"
              >
                <iframe
                  id="videoMyFamily"
                  key={locale}
                  className="second-responsive-iframe"
                  title={t("Video des")}
                  src="https://www.youtube.com/embed/U4QauFbIwq0"
                  tabIndex="0"
                ></iframe>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container content-title-container">
            <div
              className="section-title"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <span className="content-title-text">{t("Preview")}</span>
              <br />
              <br />
            </div>
            <div
              className="row vertical-align"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <div className="col-sm-12">
                <img
                  src={load(
                    `./resources/images/promulgation/interactive-pic-book/interactive-pic-book-preview-01.jpg`
                  )}
                  alt=""
                />
                <br />
                <br />
                <img
                  src={load(
                    `./resources/images/promulgation/interactive-pic-book/interactive-pic-book-preview-02.jpg`
                  )}
                  alt=""
                />
                <br />
                <br />
                <img
                  src={load(
                    `./resources/images/promulgation/interactive-pic-book/interactive-pic-book-preview-03.jpg`
                  )}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container content-title-container ">
            <hr className="blueborder"></hr>
            <div
              className="section-title"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <span className="content-title-text">
                {t(
                  "Children’s Interactive Picture Book Storytelling Workshops"
                )}
              </span>
              <br />
              <br />
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container storytelling-photo-container">
            <div
              className="row vertical-align "
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <div className="col-sm-6">
                <img
                  src={load(
                    `./resources/images/promulgation/interactive-pic-book/storytelling-01.jpg`
                  )}
                  alt=""
                />
              </div>

              <div className="col-sm-6">
                <img
                  src={load(
                    `./resources/images/promulgation/interactive-pic-book/storytelling-02.jpg`
                  )}
                  alt=""
                />
              </div>
            </div>
            <br />
            <br />
            <div
              className="row vertical-align"
              data-aos="fade-up"
              data-aos-delay="200"
              data-aos-offset="-200"
            >
              <div className="col-sm-12">
                <img
                  src={load(
                    `./resources/images/promulgation/interactive-pic-book/storytelling-03.jpg`
                  )}
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>

        <div
          className="promulgation-back"
          data-aos="fade-up"
          data-aos-delay="200"
          data-aos-offset="-200"
        >
          <a href="/promulgation">{t("Back")}</a>
        </div>

        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(PromotionTruck);

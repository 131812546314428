import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class ClinicalMedicationPage extends Component {
  render() {
    const { locale, t } = this.props;
    const sliderImages = [
      "investigation_slider_phone1.png",
      "investigation_slider_phone2.png",
    ];
    const sliderImageAlts = [
      "Screenshot of Investigation",
      "Screenshot of Investigation",
    ];
    const sliderImageIds = [
      "img1InvestigationSlider",
      "img2InvestigationSlider",
    ];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"clinical-investigation-bg.png"}
        sliderID="allergySlider"
        sliderTitle={t("Investigation Header")}
        sliderTitleID={"textMedicationSliderTitle"}
        sliderText={t("View your investigation reports at all times")}
        sliderTextID="textAllergySliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );
    return (
      <div
        className={`page-container page-container-${locale}`}
        id="MainContent"
      >
        {phoneSlider}
        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textAllergySection1Title"
                  className="second-feature-title"
                >
                  {t("Laboratory reports")}
                </div>
                <div
                  id="textAllergySection1Content"
                  className="second-feature-text"
                >
                  {t("Managing your health made easy")}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1AllergySection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/investigation_p1_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img2AllergySection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(`./resources/images/investigation_p1_2.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section ">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textMyFamilySection2Title"
                  className="second-feature-title"
                >
                  {t("Share your investigation reports")}
                </div>
                <div
                  id="textMyFamilySection2Content"
                  className="second-feature-text"
                >
                  {t("For reference of medical professionals")}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1AllergySection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/investigation_p2_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img2AllergySection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(`./resources/images/investigation_p2_2.png`)}
                  alt=" "
                />
                <img
                  id="img3AllergySection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(`./resources/images/investigation_p2_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(ClinicalMedicationPage);

import React from 'react';
import { injectIntl } from "react-intl";
import { withRouter } from 'react-router-dom';

const PageHOC = ChildComponent => {
  class ComposedComponent extends React.Component {
    render() {
        const translate = (intl) => {
            return (message) => {
                return intl.formatMessage({id: message}, {br: <br />})
            }
        };
        return <ChildComponent t={translate(this.props.intl)} {...this.props} />;
    }
  }
  return injectIntl(withRouter(ComposedComponent));
}

export default PageHOC;
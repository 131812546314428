import React, { Component, Fragment } from "react";
import PageHOC from "../PageHOC";
import load from "../ImageLoder";
import { NavLink } from "react-router-dom";

class Footer extends Component {
  static defaultProps = {
    wcag22aURL: "https://www.w3.org/WAI/WCAG2AA-Conformance",
    appStoreDownloadURL: "https://apps.apple.com/app/id1514742468",
    googelPlayDownloadURL:
      "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr",
    huaweiDownloadURL: "https://appgallery.huawei.com/#/app/C104154321",
    apkDownloadURL: "https://app.ehealth.gov.hk/static/app/ehealthapp.apk",
  };

  detectIOS() {
    return (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    );
  }

  detectAndroid() {
    return navigator.userAgent.match(/Android/i);
  }
  detectHuawei() {
    return navigator.userAgent.match(/huawei/i);
  }
  render() {
    const { locale, t } = this.props;
    let WindowWidth = window.innerWidth || document.body.clientWidth;
    let {
      languageCodeMap,
      wcag22aURL,
      appStoreDownloadURL,
      googelPlayDownloadURL,
      huaweiDownloadURL,
      apkDownloadURL,
    } = this.props;
    let isIOS = this.detectIOS();
    let isAndroid = this.detectAndroid();
    let isHuawei = this.detectHuawei();
    let importantNoticesURL = `https://${
      WindowWidth > 768 ? "www" : "m"
    }.ehealth.gov.hk/${
      languageCodeMap[locale]["code"]
    }/important_notices/index.html`;
    let privacyPolicyURL = `https://${
      WindowWidth > 768 ? "www" : "m"
    }.ehealth.gov.hk/${
      languageCodeMap[locale]["code"]
    }/privacy_policy/index.html`;
    let youtubeURL = `https://www.youtube.com/user/ehrssgovhk`;
    let facebookURL = `https://www.facebook.com/%E9%86%AB%E5%81%A5%E9%80%9A-Electronic-Health-Record-Sharing-System-107389701011880`;
    let igURL = `https://www.instagram.com/electronic_health_record/`;

    if (locale === "en") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/id1514742468"
        : "https://apps.apple.com/us/app/id1514742468";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr&hl=en-US";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/#/app/C104154321"
        : "https://appgallery.huawei.com/#/app/C104154321";
    } else if (locale === "zh-CN") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/id1514742468"
        : "https://apps.apple.com/cn/app/id1514742468";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr&hl=zh-CN";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/#/app/C104154321"
        : "https://appgallery.huawei.com/#/app/C104154321";
    } else if (locale === "zh-HK") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/id1514742468"
        : "https://apps.apple.com/hk/app/id1514742468";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr&hl=zh-HK";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/#/app/C104154321"
        : "https://appgallery.huawei.com/#/app/C104154321";
    }

    return (
      <Fragment>
        <div
          id="second-download-section"
          className="container-fluid"
          style={{ background: "#dcf2f2", marginTop: "0px" }}
        >
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-right"
                className="col-md-6 col-sm-12"
                style={{ zIndex: "2" }}
              >
                <div
                  id="textDownloadSection1Title"
                  className="second-download-title"
                >
                  {t("Footer Download Title")}
                </div>
                <div
                  id="textDownloadSectionContent"
                  className="second-download-subtitle"
                >
                  {t("Footer Download Sub Title")}
                </div>
                <div className="second-download-now">
                  <div className="footer-download-btn">
                    <div className="download-btn-row">
                      <a
                        id="btnDownloadAppStore"
                        href={appStoreDownloadURL}
                        rel="noopener noreferrer"
                        className="second-download-now-href"
                        target="_blank"
                        tabIndex="0"
                      >
                        <img
                          id="imgBtnDownloadAppStore"
                          src={load(
                            `./resources/images/${locale}/app_store.png`
                          )}
                          alt={t("Download at App Store")}
                        />
                      </a>
                      <a
                        id="btnDownloadGooglePlay"
                        href={googelPlayDownloadURL}
                        rel="noopener noreferrer"
                        className="second-download-now-href"
                        target="_blank"
                        tabIndex="0"
                      >
                        <img
                          id="imgBtnDownloadGooglePlay"
                          src={load(
                            `./resources/images/${locale}/google_play.png`
                          )}
                          alt={t("Download at Google Play")}
                        />
                      </a>
                    </div>
                    <a
                      id="btnDownloadHuawei"
                      href={huaweiDownloadURL}
                      rel="noopener noreferrer"
                      className="second-download-now-href"
                      target="_blank"
                      tabIndex="0"
                    >
                      <img
                        id="imgBtnDownloadHuawei"
                        src={load(`./resources/images/${locale}/huawei.png`)}
                        alt={t("Download at Huawei App Gallery")}
                      />
                    </a>
                    <a
                      id="btnDownloadApk"
                      href="/download-apk"
                      // href={apkDownloadURL}
                      rel="noopener noreferrer"
                      className="second-download-now-href"
                      // target="_blank"
                      tabIndex="0"
                    >
                      <img
                        id="imgBtnDownloadApk"
                        src={load(
                          `./resources/images/${locale}/apk-dl-btn.png`
                        )}
                        alt={t("Download APK File")}
                      />
                    </a>
                  </div>
                </div>
              </div>
              <div data-aos="fade-left" className="col-md-6 col-sm-12">
                <img
                  id="imgDownloadPhonePhoto"
                  src={load(`./resources/images/${locale}/download_phone.png`)}
                  className="desktop-show"
                  alt={t("Welcome to 醫健通 E health")}
                />
                <img
                  id="imgDownloadPhonePhoto"
                  src={load(
                    `./resources/images/${locale}/mobile_download_phone.png`
                  )}
                  className="mobile-show"
                  alt={t("Welcome to 醫健通 E health")}
                />
              </div>
            </div>
          </div>
        </div>
        <div lang={locale} className="footer">
          <div lang={locale} className="footerLink">
            <span
              id="linkFacebookLogo"
              className="footerLinkLogo"
              tabIndex="0"
              onKeyDown={(e) =>
                e.keyCode === 13 && window.open(facebookURL, "_blank")
              }
              onClick={() => {
                window.open(facebookURL, "_blank");
              }}
            >
              <img
                id="imgFacebookLogo"
                src={load(`./resources/images/footer_facebookLogo.png`)}
                alt={t("Facebook Page")}
              />
            </span>
            <span
              id="linkIGLogo"
              className="footerLinkLogo"
              tabIndex="0"
              onKeyDown={(e) =>
                e.keyCode === 13 && window.open(igURL, "_blank")
              }
              onClick={() => {
                window.open(igURL, "_blank");
              }}
            >
              <img
                id="imgIGLogo"
                src={load(`./resources/images/footer_igLogo.png`)}
                alt={t("Instagram")}
              />
            </span>
            <span
              id="linkYoutubeLogo"
              className="footerLinkLogo"
              tabIndex="0"
              onKeyDown={(e) =>
                e.keyCode === 13 && window.open(youtubeURL, "_blank")
              }
              onClick={() => {
                window.open(youtubeURL, "_blank");
              }}
            >
              <img
                id="imgYoutubeLogo"
                src={load(`./resources/images/footer_youtubeLogo.png`)}
                alt={t("Youtube Channel")}
              />
            </span>
          </div>
          <table>
            <tr>
              <td
                className={`display-desktop ${
                  locale === "en" ? "display-desktop-en" : ""
                }`}
              >
                <div lang={locale} className="badge margin-l-40 margin-t-20">
                  <span
                    id="textFooterBadgeAppStore"
                    alt={t(
                      "App Store and the App Store logo are trademarks of Apple Inc"
                    )}
                  >
                    {t(
                      "App Store and the App Store logo are trademarks of Apple Inc"
                    )}
                  </span>
                </div>
                <div lang={locale} className="badge margin-l-40">
                  <span
                    id="textFooterBadgeGooglePlay"
                    alt={t(
                      "Google Play and the Google Play logo are trademarks of Google LLC"
                    )}
                  >
                    {t(
                      "Google Play and the Google Play logo are trademarks of Google LLC"
                    )}
                  </span>
                </div>
                <div lang={locale} className="badge margin-l-40">
                  <span
                    id="textFooterBadgeHuawei"
                    alt={t(
                      "Huawei AppGallery is provided by Huawei Services (Hong Kong) Co., Limited"
                    )}
                  >
                    {t(
                      "Huawei AppGallery is provided by Huawei Services (Hong Kong) Co., Limited"
                    )}
                  </span>
                </div>
              </td>
              <td>
                <div
                  id="textFooterCopyright"
                  lang={locale}
                  className={`copyright ${
                    locale === "en" ? "copyright-en" : ""
                  }`}
                >
                  <div lang={locale}>
                    <span>
                      {new Date().getFullYear()}©
                      <span className="margin-l-10">|</span>
                      <span className="hyperlink margin-l-10">
                        <a
                          id="btnImportantNotices"
                          href={importantNoticesURL}
                          target="_blank"
                          role="text"
                          tabIndex="0"
                          aria-label={t(
                            "Important Notices Click here to view Important Notices"
                          )}
                        >
                          {t("Important Notices")}
                        </a>
                      </span>
                      <span className="margin-l-10">|</span>
                      <span className="hyperlink margin-l-10">
                        <a
                          id="btnPrivacyPolicy"
                          href="/privacy-policy"
                          role="text"
                          tabIndex="0"
                          aria-label={t(
                            "Privacy Policy Click here to view Privacy Policy"
                          )}
                        >
                          {t("Privacy Policy")}
                        </a>
                      </span>
                      <span className="margin-l-10">|</span>
                      <span className="hyperlink margin-l-10">
                        <a
                          id="btnTermsOfUse"
                          href="/terms-of-use"
                          role="text"
                          tabIndex="0"
                          aria-label={t(
                            "Terms of Use Click here to view Terms of Use"
                          )}
                        >
                          {t("Terms of Use")}
                        </a>
                      </span>
                      <span className="margin-l-10">|</span>
                      <span className="hyperlink margin-l-10">
                        <NavLink exact to="/ehealth-faq" alt={t("FAQs")}>
                          {" "}
                          {t("FAQs")}{" "}
                        </NavLink>
                      </span>
                    </span>
                    <br
                      className={`copyright-warp ${
                        locale === "en" ? "copyright-warp-en" : ""
                      }`}
                    ></br>
                    <span className="margin-l-40">
                      <span className="margin-l-10">
                        {t("Enquiry Hotline")}
                      </span>
                      <br
                        className={`copyright-warp ${
                          locale === "en" ? "copyright-warp-en" : ""
                        }`}
                      ></br>
                      <span className="margin-l-10">
                        <img
                          id="imgPublicHotlineLogo"
                          aria-hidden="true"
                          src={load(`./resources/images/contact_phone.png`)}
                          alt=""
                        />
                        {t("Public Hotline")}
                      </span>
                      <span className="margin-l-10">
                        <img
                          id="imgProfessionalHotlineLogo"
                          aria-hidden="true"
                          src={load(`./resources/images/contact_phone.png`)}
                          alt=""
                        />
                        {t("Professional Hotline")}
                      </span>
                    </span>
                  </div>
                </div>
                <div lang={locale} className="display-mobile margin-t-20">
                  <div lang={locale} className="badge margin-l-40">
                    <span
                      id="textFooterMobileBadgeAppStore"
                      alt={t(
                        "App Store and the App Store logo are trademarks of Apple Inc"
                      )}
                    >
                      {t(
                        "App Store and the App Store logo are trademarks of Apple Inc"
                      )}
                    </span>
                  </div>
                  <div lang={locale} className="badge margin-l-40">
                    <span
                      id="textFooterMobileBadgeGooglePlay"
                      alt={t(
                        "Google Play and the Google Play logo are trademarks of Google LLC"
                      )}
                    >
                      {t(
                        "Google Play and the Google Play logo are trademarks of Google LLC"
                      )}
                    </span>
                  </div>
                  <div lang={locale} className="badge margin-l-40">
                    <span
                      id="textFooterMobileBadgeHuawei"
                      alt={t(
                        "Huawei AppGallery is provided by Huawei Services (Hong Kong) Co., Limited"
                      )}
                    >
                      {t(
                        "Huawei AppGallery is provided by Huawei Services (Hong Kong) Co., Limited"
                      )}
                    </span>
                  </div>
                  <div lang={locale}>
                    <span
                      id="linkFooterMobileW3cLogo"
                      className="w3clogo"
                      tabIndex="0"
                      onClick={() => {
                        window.open(wcag22aURL, "_blank");
                      }}
                    >
                      <img
                        src={load(`./resources/images/w3c_logo.png`)}
                        alt={t(
                          "Explanation of WCAG 20 Level Double-A Conformance"
                        )}
                      />
                    </span>
                  </div>
                </div>
              </td>
              <td className="display-desktop align-right">
                <span
                  id="linkFooterW3cLogo"
                  className="w3clogo"
                  tabIndex="0"
                  onKeyDown={(e) =>
                    e.keyCode === 13 && window.open(wcag22aURL, "_blank")
                  }
                  onClick={() => {
                    window.open(wcag22aURL, "_blank");
                  }}
                >
                  <img
                    src={load(`./resources/images/w3c_logo.png`)}
                    alt={t("Explanation of WCAG 20 Level Double-A Conformance")}
                  />
                </span>
              </td>
            </tr>
          </table>
        </div>
      </Fragment>
    );
  }
}

export default PageHOC(Footer);

import React, { Component } from 'react';
import load from '../../ImageLoder';
import PageHOC from '../../PageHOC';
import Footer from '../Footer';
import { isIEBrowser } from '../../resources/utils/commonUtils';

class MaintenancePage extends Component {
    constructor(props) {
        super(props);
    }
    
    render() {
        const { locale, t } = this.props;
        const isIe = isIEBrowser();

        return (
            <div
                className={`page-container page-container-${locale}`}
                id='MainContent'
            >
                <div
                    className='container-fluid maintenance-container'>
                    <div className='container'>
                    <img
                        id='maintenance-img'
                        className='maintenance-img'
                        src={load(
                            `./resources/images/maintenance-icon.png`
                        )}
                        alt=' '
                    />
                    {
                        !isIe && 
                        <div id='maintenance-title' className="error-page-title">
                            {t('The page is under maintenance')}
                        </div>
                    }
                    <div id='maintenance-content' className="error-page-content">
                        {isIe ? t('unsupported browser') : t('The service is undergoing some maintenance.')}
                    </div>

                    { !isIe && (
                        <>
                            <a 
                            id='maintenance-back-btn' 
                            className="error-page-back" 
                            onClick={() => {
                                /*if(this.props.vaccineCalculatorSettings && this.props.vaccineCalculatorSettings.enablePageFlag === 'Y') {
                                    this.props.history.push('vaccinepasscalculator');
                                } else {
                                    this.props.history.push('index.html');
                                }*/
								this.props.history.push('index.html');
                            }}
                            >
                                {t('OK')}
                            </a>
                        </>
                    )}
                    </div>

                </div>
                {
                    !isIe && 
                    <Footer {...this.props} />
                }
                
            </div>
        );

    }
}

export default PageHOC(MaintenancePage);

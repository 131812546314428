import React, { Component } from "react";
import { Fragment } from "react";
import load from "../ImageLoder";
import { NavLink } from "react-router-dom";

class CommonPhoneSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sliderImages: [],
      sliderImageAlts: [],
      currentSlide: 0,
      sliderInterval: null,
    };
  }

  shouldComponentUpdate(nextProps, nextState, nextContext) {
    if (this.props.locale !== nextProps.locale) {
      clearInterval(this.state.sliderInterval);
      this.initSlider(nextProps.locale);
    }
    return true;
  }

  initSlider(locale) {
    const { sliderImages, sliderImageAlts } = this.props;
    let sliderImg = [];
    let sliderAlts = [];
    sliderImages.forEach((img) => {
      sliderImg.push({ src: load(`./resources/images/${locale}/${img}`) });
    });
    if (sliderImageAlts) {
      sliderImageAlts.forEach((text) => {
        sliderAlts.push(text);
      });
    }
    this.setState(
      {
        sliderImages: sliderImg,
        sliderImageAlts: sliderAlts,
      },
      () => {
        const interval = setInterval(
          this.nextSlide,
          this.props.autoPlay * 1000
        );
        this.setState({
          sliderInterval: interval,
        });
      }
    );
  }

  componentDidMount() {
    this.initSlider(this.props.locale);
  }

  componentWillUnmount() {
    clearInterval(this.state.sliderInterval);
  }

  nextSlide = () => {
    const numberOfImage = this.state.sliderImages.length;
    let { currentSlide } = this.state;
    const that = this;
    this.hideCurrentSlide();
    setTimeout(function () {
      if (currentSlide === numberOfImage - 1) {
        that.setState({ currentSlide: 0 });
      } else {
        that.setState({ currentSlide: currentSlide + 1 });
      }
    }, 500);
  };

  prevSlide = () => {
    const numberOfImage = this.state.sliderImages.length;
    let { currentSlide } = this.state;
    const that = this;
    this.hideCurrentSlide();
    setTimeout(function () {
      if (currentSlide === 0) {
        that.setState({ currentSlide: numberOfImage - 1 });
      } else {
        that.setState({ currentSlide: currentSlide - 1 });
      }
    }, 500);
  };

  hideCurrentSlide = () => {
    let { currentSlide } = this.state;
    if (document.getElementById(`slider-${currentSlide}`)) {
      document
        .getElementById(`slider-${currentSlide}`)
        .classList.add("fadeOutLeft");
      document
        .getElementById(`slider-${currentSlide}`)
        .classList.remove("fadeInLeft");
    }
  };

  gotoSlide = (index) => {
    if (this.state.currentSlide === index) {
      return false;
    }
    const that = this;
    this.hideCurrentSlide();
    setTimeout(function () {
      that.setState({ currentSlide: index });
    }, 500);
  };

  render() {
    let { t } = this.props;
    let sliderImages = this.state.sliderImages.map((slide, index) => {
      return (
        <img
          id={`slider-${index}`}
          id={this.props.sliderTextID}
          key={`slider-${index}`}
          alt={
            this.state.sliderImageAlts[index]
              ? t(this.state.sliderImageAlts[index])
              : ""
          }
          src={slide.src}
          className={`silder-phone animated ${
            index === this.state.currentSlide ? "fadeInLeft" : "hide"
          }`}
        />
      );
    });

    let sliderButtons = this.state.sliderImages.map((slide, index) => {
      return (
        <div
          key={`goto-${index}`}
          onClick={() => this.gotoSlide(index)}
          className={`slider-pagination-btn ${
            index === this.state.currentSlide ? "active" : ""
          }`}
        >
          <a href="javascript:void(0);">{index + 1}</a>
          <span></span>
        </div>
      );
    });
    return (
      <div id="second-slider" className="container-fluid">
        <img
          className="slider-bg normal-color-sliderbg"
          src={load(`./resources/images/background-circle.png`)}
          alt=""
        />
        <img
          className="slider-bg ehealthpro-color-sliderbg"
          src={load(`./resources/images/ehealth-pro/background-circle-pro.jpg`)}
          alt=""
        />
        <div className="container slider-photobg-container">
          <img
            className="childgroup-bg"
            src={load(`./resources/images/${this.props.backgoundImg}`)}
            alt=" "
          />
        </div>
        <div className="container slider-pagination-container">
          <div id="slider_pagination">{sliderButtons}</div>
          <div id="slider_nav"></div>
        </div>

        <div className="container slider-content-container">
          <div className="slider_nav_left">
            <img
              onClick={this.prevSlide}
              src={load(`./resources/images/slider-left.png`)}
              alt=""
            />
          </div>
          <div className="slider_nav_right">
            <img
              onClick={this.nextSlide}
              src={load(`./resources/images/slider-right.png`)}
              alt=""
            />
          </div>
          <div className="row vertical-align">
            <div className="col-md-6 col-sm-12 col-sm-push-6">
              {sliderImages}
            </div>
            <div className="col-md-6 col-sm-12 col-sm-pull-6">
              <div
                id={this.props.sliderTitleID}
                className="second-slider-title"
              >
                {this.props.sliderTitle}
              </div>
              <div id={this.props.sliderTextID} className="second-slider-text">
                {this.props.sliderText}
                {this.props.sliderText2 ? (
                  <Fragment>
                    <br />
                    {this.props.sliderText2}
                  </Fragment>
                ) : (
                  ""
                )}
              </div>
              {this.props.sliderButtonText ? (
                <NavLink
                  className="second-slider-btn"
                  to={this.props.sliderButtonSection}
                >
                  {this.props.sliderButtonText}
                </NavLink>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CommonPhoneSlider;

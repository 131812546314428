import React, { Component } from 'react';
import load from '../../ImageLoder';

class FixedDownloadBar extends Component{
    constructor(props) {
        super(props);
        this.state={ 
            isClosed : false
        };
        this.babyQRredirect=this.babyQRredirect.bind(this);
    }

    closeDownloadBar = () => {
        this.setState({
            isClosed: true
        });
    }

    babyQRredirect() {
        const {locale} = this.props;
        var userAgent =  window . navigator . userAgent . toLocaleLowerCase ();
        if (/huawei/i.test(userAgent)) {

            window.location.href = "https://appgallery.huawei.com/#/app/C104154321";
       } else if(/android/i.test(userAgent)) {
            window.open(
              'https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr',
              '_blank' 
            );
          } else if(/iPhone/i.test(userAgent) || /iPad/i.test(userAgent) || /iPod/i.test(userAgent)) {
            window.open(
              'https://apps.apple.com/app/id1514742468',
              '_blank' 
            );
          } else {
            if(locale === "zh-HK"){
                window.open(
                    'https://apps.apple.com/hk/app/id1514742468',
                    '_blank' 
                  );
            }else if(locale === "zh-CN"){
                window.open(
                    'https://apps.apple.com/cn/app/id1514742468',
                    '_blank' 
                  );
            }else{
                window.open(
                    'https://apps.apple.com/us/app/id1514742468',
                    '_blank' 
                  );
            }
          } 
    }

    render(){
        const {t, locale} = this.props;
        return (
            <div id="fixed_download_bar" lang={locale} style={{display: `${this.state.isClosed ? "none" : "block"}`} }>
                <div id="fixed_download_desktop" className="display-desktop">
                    <div id="close_qr_div" onKeyDown={(e) => e.keyCode === 13 && this.closeDownloadBar()} onClick={() => document.getElementById('fixed_download_bar').style.display = 'none'}>
                        <img className="close_qr" tabIndex="0"  src={load(`./resources/images/close-qr.png`)} alt={t("close")} />    
                    </div>
                    <img id="fixed_download_qr_desktop" className="fixed_download_qr" src={load(`./resources/images/${locale}/qr-baby.png`)} alt={t("Scan to download")}/>    
                </div>
                <div id="fixed_download_mobile" className="display-mobile">
                <div id="close_qr_div" onClick={() => this.closeDownloadBar()}>
                    <img className="close_qr" src={load(`./resources/images/close-qr.png`)} alt={t("close")} />    
                </div>
                    <span className="fixed_download_qr" onClick={()=>{this.babyQRredirect()}} ><img src={load(`./resources/images/${locale}/QR-baby-mobile.png`)} alt={t("Click to download")} /></span><br/>
                </div>
            </div>
        );
    }
}

export default FixedDownloadBar;

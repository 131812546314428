import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class DoctorSearchPage extends Component {
  render() {
    const { locale, t } = this.props;
    const sliderImages = [
      "search_slider_phone1.png",
      "search_slider_phone2.png",
      "search_slider_phone3.png",
    ];
    const sliderImageAlts = [
      "Screenshot of Doctor Search",
      "Screenshot of Doctor Search",
      "Screenshot of Doctor Search",
    ];
    const sliderImageIds = [
      "img1DoctorSearchSlider",
      "img2DoctorSearchSlider",
      "img3DoctorSearchSlider",
    ];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"doctor-search-bg.png"}
        sliderID="doctorSearchSlider"
        sliderTitle={t("Doctor Search Header")}
        sliderTitleID={"textDoctorSearchSliderTitle"}
        sliderText={t(
          "Slider Find your healthcare provider and doctor with ease"
        )}
        sliderTextID="textDoctorSearchSliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );

    return (
      <div
        className={`page-container page-container-${locale}`}
        id="MainContent"
      >
        {phoneSlider}

        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textDoctorSearchSection1Title"
                  className="second-feature-title"
                >
                  {t(
                    "Sharing of your health records ensuring better healthcare"
                  )}
                </div>
                <div
                  id="textDoctorSearchSection1Content"
                  className="second-feature-text"
                >
                  {t(
                    "Create a seamless connection with your healthcare providers"
                  )}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="imgDoctorSearchSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(`./resources/images/doctor-search_p1_2.png`)}
                  alt=" "
                />
                <img
                  id="imgDoctorSearchSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(
                    `./resources/images/${locale}/doctor-search_p1_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="imgDoctorSearchSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(
                    `./resources/images/${locale}/doctor-search_p1_3.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textDoctorSearchSection2Title"
                  className="second-feature-title"
                >
                  {t("Flexible design to meet your needs")}
                </div>
                <div
                  id="textDoctorSearchSection2Content"
                  className="second-feature-text"
                >
                  {t(
                    "Quick response to your specific needs. Programmes, locations and other details all can be set."
                  )}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="imgDoctorSearchSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(`./resources/images/doctor-search_p2_3.png`)}
                  alt=" "
                />
                <img
                  id="imgDoctorSearchSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(
                    `./resources/images/${locale}/doctor-search_p2_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="imgDoctorSearchSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(
                    `./resources/images/${locale}/doctor-search_p2_2.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textDoctorSearchSection3Title"
                  className="second-feature-title"
                >
                  {t("Look for suitable health programmes?")}
                </div>
                <div
                  id="textDoctorSearchSection3Content"
                  className="second-feature-text"
                >
                  {t("More relevant information are here for you")}
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="imgDoctorSearchSection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/doctor-search_p3_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="imgDoctorSearchSection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(`./resources/images/doctor-search_p3_2.png`)}
                  alt=" "
                />
                <img
                  id="imgDoctorSearchSection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(`./resources/images/doctor-search_p3_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section last-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textDoctorSearchSection4Title"
                  className="second-feature-title"
                >
                  {t("All you need are well within reach")}
                </div>
                <div
                  id="textDoctorSearchSection4Content"
                  className="second-feature-text"
                >
                  {t(
                    "Simple to locate and navigate healthcare providers for you"
                  )}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="imgDoctorSearchSection4"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(`./resources/images/doctor-search_p4_1.png`)}
                  alt=" "
                />
                <img
                  id="imgDoctorSearchSection4"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/doctor-search_p4_2.png`
                  )}
                  alt=" "
                />
                <img
                  id="imgDoctorSearchSection4"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(`./resources/images/doctor-search_p4_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-down"
          data-aos-offset="-300"
          className="second-video"
          alt={t("Video")}
        >
          <div ref={this.youtubeContainer} className="second-youtube-container">
            <iframe
              id="videoDoctorSearch"
              key={locale}
              className="second-responsive-iframe"
              title={t("Video des")}
              src="https://www.youtube.com/embed/7mqErQ4r2MI"
              alt={t("Video")}
              tabIndex="-1"
            ></iframe>
          </div>
        </div>

        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(DoctorSearchPage);

import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import Footer from "../Footer";
import BannerSlider from "./BannerSlider";

class PromulgationPage extends Component {
  render() {
    const { locale, t } = this.props;

    return (
      <div
        className={`promulgation-page page-container page-container-${locale}`}
        id="MainContent"
      >
        <div className="static_page_header">
          <BannerSlider {...this.props} />
        </div>

        <div className="container-fluid">
          <div className="container promulgation-landing-intro-container">
            <div className="row vertical-align">
              <div
                data-aos="fade-up"
                data-aos-offset="-500"
                className="col-md-12 col-sm-12"
              >
                <div id="" className="promulgation-landing-intro">
                  {t("promulgation-landing-intro")}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid">
          <div className="container promulgation-landing-video">
            <div className="row vertical-align">
              <div
                data-aos="fade-up"
                data-aos-offset="-200"
                data-aos-delay="200"
                className="center-div"
                alt={t("Video")}
              >
                <div
                  ref={this.youtubeContainer}
                  className="second-youtube-container"
                >
                  <iframe
                    id="videoHealthNews"
                    key={locale}
                    className="second-responsive-iframe"
                    title={t("Video des")}
                    src="https://www.youtube.com/embed/nk8m64dDG94"
                    alt={t("Video")}
                    tabIndex="0"
                  ></iframe>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-offset="-200"
          data-aos-delay="200"
          className="container-fluid promulgation-section"
        >
          <div className="container">
            <div className="row vertical-align">
              <div className="col-md-6 col-sm-12 img-container-left">
                <div>
                  <img
                    className="img-left"
                    src={load(
                      `./resources/images/promulgation/landing/promulgation-landing-promo-video.png`
                    )}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12 promulgation-section-content">
                <div className="promulgation-icon">
                  <img
                    src={load(
                      `./resources/images/promulgation/landing/promulgation-landing-promo-video-icon.png`
                    )}
                    alt=""
                  />
                </div>
                <div className="promulgation-section-title">
                  {t("Promotional Videos")}
                </div>{" "}
                <div className="promulgation-section-button">
                  <NavLink to="/promulgation-promotional-videos">
                    {t("View More")} {">"}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-offset="-200"
          data-aos-delay="200"
          className="container-fluid promulgation-section section2n"
        >
          <div className="container">
            <div className="row vertical-align">
              <div className="col-md-6 col-sm-12 col-sm-push-6 img-container-left">
                <div>
                  <img
                    className="img-left"
                    src={load(
                      `./resources/images/promulgation/landing/promulgation-landing-animation.png`
                    )}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-sm-pull-6 promulgation-section-content">
                <div className="promulgation-icon">
                  <img
                    src={load(
                      `./resources/images/promulgation/landing/promulgation-landing-animation-icon.png`
                    )}
                    alt=""
                  />
                </div>
                <div className="promulgation-section-title">
                  {t("Animation Series and Mini-game")}
                </div>
                <div className="promulgation-section-button">
                  <NavLink to="/promulgation-animation">
                    {t("View More")} {">"}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-offset="-200"
          data-aos-delay="200"
          className="container-fluid promulgation-section"
        >
          <div className="container">
            <div className="row vertical-align">
              <div className="col-md-6 col-sm-12 img-container-left">
                <div>
                  <img
                    className="img-left"
                    src={load(
                      `./resources/images/promulgation/landing/promulgation-landing-download.png`
                    )}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12 promulgation-section-content promulgation-download-section">
                <div className="promulgation-icon">
                  <img
                    src={load(
                      `./resources/images/promulgation/landing/promulgation-landing-download-icon.png`
                    )}
                    alt=""
                  />
                </div>
                <div className="promulgation-section-title">
                  {t("Free Download")}
                </div>
                <div className="promulgation-section-button">
                  <a href="/download-sticker">
                    {t("View More on Whatsapp & Signal Stickers")} {">"}
                  </a>
                </div>{" "}
                <div className="promulgation-section-button">
                  <a href="/download-bg">
                    {t("View More on Wallpapers & Virtual Backgrounds")} {">"}
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-up"
          data-aos-offset="-200"
          data-aos-delay="200"
          className="container-fluid promulgation-section section2n"
        >
          <div className="container">
            <div className="row vertical-align">
              <div className="col-md-6 col-sm-12 col-sm-push-6 ">
                <div className="promulgation-section-title">
                  <img
                    src={load(
                      `./resources/images/promulgation/landing/promulgation-landing-support-station.png`
                    )}
                    alt=""
                  />
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6 promulgation-section-content">
                <div className="promulgation-icon">
                  <img
                    src={load(
                      `./resources/images/promulgation/landing/promulgation-landing-support-station-icon.png`
                    )}
                    alt=""
                  />
                </div>
                <div className="promulgation-section-title">
                  {t("e+ Support Station")}
                </div>
                <div className="promulgation-section-button">
                  <NavLink to="/promulgation-support-station">
                    {t("View More")} {">"}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-offset="-200"
          data-aos-delay="200"
          className="container-fluid promulgation-section "
        >
          <div className="container">
            <div className="row vertical-align">
              <div className="col-md-6 col-sm-12  ">
                <div className="promulgation-section-title">
                  <img
                    src={load(
                      `./resources/images/promulgation/landing/promulgation-landing-social-media.png`
                    )}
                    alt=""
                  />
                </div>
              </div>

              <div className="col-md-6 col-sm-12 promulgation-section-content promulgation-social-media-section">
                <div className="promulgation-icon">
                  <img
                    src={load(
                      `./resources/images/promulgation/landing/promulgation-landing-social-media-icon.png`
                    )}
                    alt=""
                  />
                </div>
                <div className="promulgation-section-title">
                  {t("Social Media")}
                </div>
                <div className="promulgation-section-button">
                  <a
                    href="https://www.facebook.com/profile.php?id=100063945884470"
                    target="_blank"
                  >
                    Facebook
                    <img
                      className="promulgation-social-media-icon"
                      src={load(`./resources/images/facebook-icon.png`)}
                      alt=""
                    />
                  </a>
                </div>
                <div className="promulgation-section-button">
                  <a
                    href="https://www.instagram.com/electronic_health_record/"
                    target="_blank"
                  >
                    Instagram
                    <img
                      className="promulgation-social-media-icon"
                      src={load(`./resources/images/instagram-icon.png`)}
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-offset="-200"
          data-aos-delay="200"
          className="container-fluid promulgation-section section2n"
        >
          <div className="container">
            <div className="row vertical-align">
              <div className="col-md-6 col-sm-12 col-sm-push-6 img-container-left">
                <div>
                  <img
                    className="img-left"
                    src={load(
                      `./resources/images/promulgation/landing/promulgation-landing-in-outdoor-ad.png`
                    )}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12 col-sm-pull-6 promulgation-section-content">
                <div className="promulgation-icon">
                  <img
                    src={load(
                      `./resources/images/promulgation/landing/promulgation-landing-in-outdoor-ad-icon.png`
                    )}
                    alt=""
                  />
                </div>
                <div className="promulgation-section-title">
                  {t("Advertising")}
                </div>
                <div className="promulgation-section-button">
                  <NavLink to="/promulgation-advertisement">
                    {t("View More")} {">"}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-offset="-200"
          data-aos-delay="200"
          className="container-fluid promulgation-section "
        >
          <div className="container">
            <div className="row vertical-align">
              <div className="col-md-6 col-sm-12 img-container-left">
                <div>
                  <img
                    className="img-left"
                    src={load(
                      `./resources/images/promulgation/landing/promulgation-landing-picture-book.png`
                    )}
                    alt=""
                  />
                </div>
              </div>
              <div className="col-md-6 col-sm-12 promulgation-section-content">
                <div className="promulgation-icon">
                  <img
                    src={load(
                      `./resources/images/promulgation/landing/promulgation-landing-picture-book-icon.png`
                    )}
                    alt=""
                  />
                </div>
                <div className="promulgation-section-title">
                  {t("Interactive Picture Book")}
                </div>
                <div className="promulgation-section-button">
                  <NavLink to="/promulgation-picture-book">
                    {t("View More")} {">"}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-up"
          data-aos-offset="-200"
          data-aos-delay="200"
          className="container-fluid promulgation-section section2n"
        >
          <div className="container">
            <div className="row vertical-align">
              <div className="col-md-6 col-sm-12 col-sm-push-6 ">
                <div className="promulgation-section-title">
                  <img
                    src={load(
                      `./resources/images/promulgation/landing/promulgation-landing-promo-truck.png`
                    )}
                    alt=""
                  />
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6 promulgation-section-content">
                <div className="promulgation-icon">
                  <img
                    src={load(
                      `./resources/images/promulgation/landing/promulgation-landing-promo-truck-icon.png`
                    )}
                    alt=""
                  />
                </div>
                <div className="promulgation-section-title">
                  {t("Promotion Truck")}
                </div>
                <div className="promulgation-section-button">
                  <NavLink to="/promulgation-promotion-truck">
                    {t("View More")} {">"}
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <div data-aos="fade-up"  data-aos-offset="-200" data-aos-delay="200" className="container-fluid promulgation-section section2n">
                    <div className="container">
                        <div className="row vertical-align">
                            <div className="col-md-6 col-sm-12 col-sm-push-6 ">
                            <div className="promulgation-section-title">
                                <img src={load(`./resources/images/promulgation/landing/promulgation-landing-souvenir.png`)} alt="" />
                            </div>
                                   
                            </div>

                            <div className="col-md-6 col-sm-12 col-sm-pull-6 promulgation-section-content">
                                <div className="promulgation-icon">
                                    <img  src={load(`./resources/images/promulgation/landing/promulgation-landing-souvenir-icon.png`)} alt="" />
                                </div>
                                <div className="promulgation-section-title">{t("Souvenir")}</div>
                                <div className="promulgation-section-button"><NavLink>{t("View More")} {">"}</NavLink></div> 
                            </div>
                        </div>
                    </div>
                </div> */}

        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(PromulgationPage);

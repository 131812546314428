import React, { Component } from "react";
import "../../resources/styles/promotion.scss";
import PhoneAnimationSlider from "./PhoneAnimationSlider";
import FixedDownloadBar from "./FixedDownloadBar";
import BannerSlider from "./BannerSlider";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import { NavLink } from "react-router-dom";

class PromotionPage extends Component {
  static defaultProps = {
    wcag22aURL: "https://www.w3.org/WAI/WCAG2AA-Conformance",
    appStoreDownloadURL: "https://apps.apple.com/app/id1514742468",
    googelPlayDownloadURL:
      "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr",
    huaweiDownloadURL: "https://appgallery.huawei.com/#/app/C104154321",
    apkDownloadURL: "https://app.ehealth.gov.hk/static/app/ehealthapp.apk",
  };

  constructor(props) {
    super(props);
    this.state = {
      // Initial state
      recordImg: "",
      showPopup: false,
    };
  }

  componentDidMount() {
    // const lastPopup = localStorage.getItem('lastPopup');
    // if(!lastPopup){
    // this.showPopup();
    // }else{
    //     const lastPopupDate = new Date(lastPopup);
    //     const now = new Date();
    //     const secondPassed = ( now.getTime()  - lastPopupDate.getTime() ) / 1000;
    //     if(secondPassed > 60 * 60) { // In seconds
    //         this.showPopup();
    //     }
    // }
    this.initPage();
    window.addEventListener("resize", this.initPage);
  }

  settabIndex(tabIndex) {
    const mainWithtabIndexItems = document
      .getElementById("MainContent")
      .querySelectorAll("[tabIndex]");
    mainWithtabIndexItems.forEach((e) => (e.tabIndex = tabIndex));
    const navWithtabIndexItems = document
      .getElementById("ProNav")
      .querySelectorAll("[tabIndex]");
    navWithtabIndexItems.forEach((e) => (e.tabIndex = tabIndex));
    const youtubeIframe = document
      .getElementById("landing-youtube-iframe")
      .querySelectorAll("[tabIndex]");
    youtubeIframe.forEach((e) => (e.tabIndex = tabIndex));
    const youtubeMobileIframe = document
      .getElementById("landing-youtube-mobile-iframe")
      .querySelectorAll("[tabIndex]");
    youtubeMobileIframe.forEach((e) => (e.tabIndex = tabIndex));
  }

  closePopup() {
    this.setState({ showPopup: false });
    this.settabIndex(0);
    const containerOverflow = document.getElementById("Container");
    containerOverflow.style.overflow = "visible";
  }

  showPopup() {
    // const now = new Date();
    // localStorage.setItem('lastPopup', now.toUTCString());
    this.setState({
      showPopup: true,
    });
    this.settabIndex(-1);
    const containerOverflow = document.getElementById("Container");

    containerOverflow.style.overflow = "hidden";
  }

  detectIOS() {
    return (
      navigator.userAgent.match(/iPhone/i) ||
      navigator.userAgent.match(/iPad/i) ||
      navigator.userAgent.match(/iPod/i)
    );
  }

  detectAndroid() {
    return navigator.userAgent.match(/Android/i);
  }
  detectHuawei() {
    return navigator.userAgent.match(/huawei/i);
  }

  initPage = () => {
    let WindowHeight = window.innerHeight || document.body.clientHeight;
    let WindowWidth = window.innerWidth || document.body.clientWidth;

    this.setState({
      height: "height:" + WindowHeight + ",width:" + WindowWidth,
    });

    let MainContent = document.getElementById("MainContent");
    let Container = document.getElementById("Container");
    // MainContent.style.height = WindowHeight+'px';
    Container.style.height = WindowHeight + "px";

    //scroll show nav
    let ProNav = document.getElementById("ProNav");
    let WWidth = MainContent.clientWidth;
    ProNav.style.width = WWidth + "px";

    window.addEventListener("scroll", this.onWindowScroll);
    // MainContent.addEventListener('scroll',(e)=>{
    //     let scrollTop = e.target.scrollTop;
    //     if(scrollTop>100){
    //         ProNav.className = "promotion-nav scroll-down";
    //     }else{
    //         ProNav.className = "promotion-nav";
    //     }
    //     this.showFeature(scrollTop, WindowHeight);
    // });
  };

  onWindowScroll = () => {
    let ProNav = document.getElementById("ProNav");
    let WindowHeight = window.innerHeight || document.body.clientHeight;
    let scrollTop =
      document.documentElement.scrollTop || document.body.scrollTop; // Get Current Scroll Value
    this.showFeature(scrollTop, WindowHeight);
    if (scrollTop > 100) {
      ProNav.className = "promotion-nav scroll-down";
    } else {
      ProNav.className = "promotion-nav";
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.initPage);
    window.removeEventListener("scroll", this.onWindowScroll);
  }

  showFeature(top, height) {
    let FearturesPart = document.getElementById("FearturesPart");
    for (let i = 0; i < FearturesPart.childNodes.length; i++) {
      let featuresNode = FearturesPart.childNodes[i];
      //当页面滚动到当前区块开始执行
      if (featuresNode.offsetTop - top <= height) {
        let currentNodeHeight;
        let currentScale, currentPosition, currentOpacity;
        //计算当前模块的高度
        currentNodeHeight = featuresNode.clientHeight;
        //滚动值应以0开始（用实际滚动值+屏幕高度-当前模块的offsetTop);
        let scrollValue = top + height - featuresNode.offsetTop;
        currentScale =
          scrollValue * (1 / currentNodeHeight) >= 1
            ? 1
            : scrollValue * (1 / currentNodeHeight);
        currentOpacity =
          scrollValue * (1 / currentNodeHeight) >= 1
            ? 1
            : scrollValue * (1 / currentNodeHeight);
        currentPosition =
          scrollValue * (300 / currentNodeHeight) >= 300
            ? 300
            : scrollValue * (300 / currentNodeHeight);
        if (i === 9) {
          let txtIndexAttr = document.querySelectorAll(
            '[txtIndex="txt' + i + '"]'
          );
          txtIndexAttr.forEach(function (txtElement) {
            txtElement.style.opacity = currentOpacity;
            txtElement.style.transform =
              "translatey(" + (300 - currentPosition) + "px)";
          });
        } else {
          let imgIndexAttr = document.querySelectorAll(
            '[imgIndex="img' + i + '"]'
          );
          imgIndexAttr.forEach(function (imgElement) {
            imgElement.style.opacity = currentOpacity;
            imgElement.style.transform = "scale(" + currentScale + ")";
          });
          let iconIndexAttr = document.querySelectorAll(
            '[iconIndex="icon' + i + '"]'
          );
          iconIndexAttr.forEach(function (iconElement) {
            iconElement.style.opacity = currentOpacity;
            iconElement.style.transform =
              "translateX(" +
              (i % 2 === 0 ? currentPosition - 300 : 300 - currentPosition) +
              "px) scale(" +
              currentScale +
              ")";
          });
        }
      }
    }
  }

  render() {
    const { locale, t } = this.props;
    let WindowWidth = window.innerWidth || document.body.clientWidth;
    let {
      languageCodeMap,
      wcag22aURL,
      appStoreDownloadURL,
      googelPlayDownloadURL,
      huaweiDownloadURL,
      apkDownloadURL,
    } = this.props;
    let isIOS = this.detectIOS();
    let isAndroid = this.detectAndroid();
    let isHuawei = this.detectHuawei();
    let importantNoticesURL = `https://www.ehealth.gov.hk/${languageCodeMap[locale]["code"]}/important-notices/index.html`;
    let privacyPolicyURL = `https://www.ehealth.gov.hk/${languageCodeMap[locale]["code"]}/privacy-policy/index.html`;
    let youtubeURL = `https://www.youtube.com/user/ehrssgovhk`;
    let facebookURL = `https://www.facebook.com/%E9%86%AB%E5%81%A5%E9%80%9A-Electronic-Health-Record-Sharing-System-107389701011880`;
    let igURL = `https://www.instagram.com/electronic_health_record/`;

    if (locale === "en") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/id1514742468"
        : "https://apps.apple.com/us/app/id1514742468";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr&hl=en-US";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/#/app/C104154321"
        : "https://appgallery.huawei.com/#/app/C104154321";
    } else if (locale === "zh-CN") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/id1514742468"
        : "https://apps.apple.com/cn/app/id1514742468";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr&hl=zh-CN";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/#/app/C104154321"
        : "https://appgallery.huawei.com/#/app/C104154321";
    } else if (locale === "zh-HK") {
      appStoreDownloadURL = isIOS
        ? "https://apps.apple.com/app/id1514742468"
        : "https://apps.apple.com/hk/app/id1514742468";
      googelPlayDownloadURL = isAndroid
        ? "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr"
        : "https://play.google.com/store/apps/details?id=hk.gov.ehealth.ehr&hl=zh-HK";
      huaweiDownloadURL = isHuawei
        ? "https://appgallery.huawei.com/#/app/C104154321"
        : "https://appgallery.huawei.com/#/app/C104154321";
    }

    return (
      <div className={`promotion-page page-continer-${locale}`} id="Container">
        {/* {this.state.showPopup ? (<div className="popup-bg"></div>) : null}
                {this.state.showPopup ?(
                    <div lang={locale} className="covid-popup">
                        <img id="imgHomePopupImage" lang={locale} src={load(`./resources/images/covid19-popup/covid19-popup-${locale}-desktop.png`)} className="display-desktop popup-image" alt={t("Easy Registration with  E Health via the COVID-19 Vaccination Scheme Since it takes time to process your registration, you will receive a SMS later informing you that your E Health has been successfully created You may then download 醫健通 E Health and view your COVID-19 vaccination records")} tabIndex="1"/>
                        <a id="btnHomePopupClose" className="close-popup-href" href="#" onClick={() => this.closePopup()} tabIndex="1">
                        <img id="imgHomePopupCloseIcon" lang={locale} role="button" src={load(`./resources/images/covid19-popup/popup-close-desktop.png`)} className="display-desktop close-popup" alt={t("Close")} />
                        </a>

                        <div lang={locale} className={`popup-btn-container ${locale === "en" ? "popup-btn-container-en" : ""}`}>
                            <a id="linkHomePopupKnowMore" lang={locale} className="popup-btn-href" href="static/internal/eHealth_COVID-19_leaflet-01.pdf?ver=2" target="_blank" tabIndex="1">
                                <img id="btnHomePopupKnowMoreBtn" lang={locale} src={load(`./resources/images/covid19-popup/knowmore-btn-${locale}.png`)} className="popup-btn" alt={t("Click for more information")}/>
                                <img lang={locale} src={load(`./resources/images/covid19-popup/knowmore-btn-shadow.png`)} className="popup-btn-shadow" alt="" />
                            </a>
                        </div>
                        <img id="imgHomePopupImageMobile" src={load(`./resources/images/covid19-popup/covid19-popup-${locale}-mobile.png`)} className="display-mobile popup-image" alt={t("Easy Registration with  E Health via the COVID-19 Vaccination Scheme Since it takes time to process your registration, you will receive a SMS later informing you that your E Health has been successfully created You may then download 醫健通 E Health and view your COVID-19 vaccination records")} />
                        <img id="imgHomePopupCloseIconMobile" onClick={() => this.closePopup()} src={load(`./resources/images/covid19-popup/popup-close-mobile.png`)} className="display-mobile close-popup" alt={t("Close")} />
                    </div>
                ):null}                 */}
        <div lang={locale} id="MainContent" className="promotion-page-content">
          <div
            className="promotion-banner"
            style={{
              background: `url(${load(
                "./resources/images/banner_bg.png"
              )}) no-repeat top center`,
            }}
          >
            <BannerSlider {...this.props} />
            <div className="main-view" id="Banner">
              <div lang={locale} className="banner-txt">
                <h1 id="homePhoneSectionTitle">
                  {t("Managing Your Health, anytime, anywhere")}
                </h1>
                <p id="homePhoneSectionContent" lang={locale}>
                  {t(
                    "醫健通 eHealth, a one-stop access of useful health information and your health records"
                  )}
                  {t("Leading you to better health within one click")}
                </p>
                <div className="app-download">
                  <a
                    id="linkHomePhoneSectionDownloadAppStore"
                    href="#"
                    onClick={() => {
                      window.open(appStoreDownloadURL, "_blank");
                    }}
                    tabIndex="0"
                  >
                    <span>
                      <img
                        id="btnHomePhoneSectionDownloadAppStore"
                        src={load(`./resources/images/${locale}/app_store.png`)}
                        alt={t("Download at App Store")}
                      />
                    </span>
                  </a>
                  <a
                    id="linkHomePhoneSectionDownloadGooglePlay"
                    href="#"
                    onClick={() => {
                      window.open(googelPlayDownloadURL, "_blank");
                    }}
                    tabIndex="0"
                  >
                    <span>
                      <img
                        id="btnHomePhoneSectionDownloadGooglePlay"
                        src={load(
                          `./resources/images/${locale}/google_play.png`
                        )}
                        alt={t("Download at Google Play")}
                      />
                    </span>
                  </a>
                  <a
                    id="linkHomePhoneSectionDownloadHuawei"
                    href="#"
                    onClick={() => {
                      window.open(huaweiDownloadURL, "_blank");
                    }}
                    tabIndex="0"
                  >
                    <span>
                      <img
                        id="btnHomePhoneSectionDownloadHuawei"
                        src={load(`./resources/images/${locale}/huawei.png`)}
                        alt={t("Download at Huawei App Gallery")}
                      />
                    </span>
                  </a>
                  <a
                    id="linkHomePhoneSectionDownloadApk"
                    href="/download-apk"
                    // onClick={() => {
                    //   window.open(apkDownloadURL, "_blank");
                    // }}
                    tabIndex="0"
                  >
                    <span>
                      <img
                        id="btnHomePhoneSectionDownloadApk"
                        src={load(
                          `./resources/images/${locale}/apk-dl-btn.png`
                        )}
                        alt={t("Download APK File")}
                      />
                    </span>
                  </a>
                </div>
              </div>
              <PhoneAnimationSlider {...this.props} />
              <img
                id="imgHomePhoneSectionBgPattern"
                aria-hidden="true"
                className="icon-bg to-front"
                src={load(`./resources/images/banner_icon.png`)}
                alt=""
              />
            </div>
            <img
              id="imgHomePhoneSectionBgFamily"
              aria-hidden="true"
              className="family-bg"
              src={load(`./resources/images/img_family.png`)}
              alt=""
            />
          </div>
          <div
            id="promotionDownloadPart"
            style={{
              background: `url(${load(
                "./resources/images/promotion_bg.png"
              )}) no-repeat 60%`,
            }}
          >
            <div className="promotion-features">
              <div lang={locale} className="main-view">
                <div
                  lang={locale}
                  className="display-mobile feature-content"
                  tabIndex="0"
                  alt={t("Video")}
                >
                  <div className="youtube-container">
                    <iframe
                      id="videoHomeIntro"
                      key={locale}
                      className="responsive-iframe"
                      id="landing-youtube-mobile-iframe"
                      src="https://www.youtube.com/embed/9eiX-EaLEmc"
                      frameBorder="0"
                      title={t("Video")}
                      tabIndex="-1"
                    ></iframe>
                  </div>
                  <img
                    id="imgHomeDownloadImage"
                    lang={locale}
                    className="promotion-download-content"
                    src={load(
                      `./resources/images/${locale}/mobile-promotion_content.png`
                    )}
                    alt={t("Scan to download 醫健通 E health App")}
                  />
                </div>
                <div
                  lang={locale}
                  className="display-desktop feature-content"
                  tabIndex="0"
                  alt={t("Video")}
                >
                  <div className="youtube-container">
                    <iframe
                      id="videoHomeIntro"
                      key={locale}
                      className="responsive-iframe"
                      id="landing-youtube-iframe"
                      src="https://www.youtube.com/embed/9eiX-EaLEmc"
                      frameBorder="0"
                      title={t("Video")}
                      tabIndex="-1"
                    ></iframe>
                  </div>
                  <img
                    id="imgHomeDownloadImage"
                    lang={locale}
                    className="promotion-download-content"
                    src={load(
                      `./resources/images/${locale}/promotion_content.png`
                    )}
                    alt={t("Scan to download 醫健通 E health App")}
                  />
                </div>
              </div>
            </div>
          </div>
          <div lang={locale} className="home-yt-btn">
            <a
              id="btnHomeHowToGetStarted"
              className="home-yt-btn-a"
              href="https://youtu.be/53e8Soz8IXc"
              target="_blank"
              tabIndex="0"
            >
              {" "}
              {t("How to get started")}{" "}
              <img
                className="home-ytplay-icon"
                src={load(`./resources/images/home-play-button.png`)}
              />
            </a>
            <div lang={locale} className="home-yt-btn-shadow" href="#">
              {" "}
              {t("How to get started")}{" "}
              <img
                className="home-ytplay-icon"
                src={load(`./resources/images/home-play-button.png`)}
              />
            </div>
          </div>
          <div id="FearturesPart">
            <div className="promotion-features" index="0">
              <p
                id="textHomeFeaturesTitle"
                lang={locale}
                className="feature-title"
              >
                <b>{t("Features")}</b>
                <span></span>
              </p>

              <div lang={locale} className="main-view">
                <div lang={locale} className="display-mobile feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesCBHRMobileTitle"
                        lang={locale}
                        tabIndex="0"
                        to="cross-boundary-health-record"
                        alt={t(
                          "Cross-boundary Health Record, click here for more information"
                        )}
                      >
                        {t("Cross-boundary Health Record")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesCBHRMobileSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t(
                      "Share your health records with medical institutions outside Hong Kong that are specified and certified by the Health Bureau"
                    )}
                  </p>
                </div>
                <div lang={locale} className="image-area">
                  <img
                    id="imgHomeFeaturesCBHRPhone"
                    className="hm-img part-img"
                    imgIndex="img0"
                    src={load(`./resources/images/${locale}/home_cbhr.png`)}
                    alt={t("Cross-boundary Health Record")}
                  />
                </div>
                <div lang={locale} className="display-desktop feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesHealthManagementTitle"
                        lang={locale}
                        tabIndex="0"
                        to="cross-boundary-health-record"
                        alt={t(
                          "Health Management​, click here for more information"
                        )}
                      >
                        {t("Cross-boundary Health Record")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesHealthManagementSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t(
                      "Share your health records with medical institutions outside Hong Kong that are specified and certified by the Health Bureau"
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div className="promotion-features odd-bg" index="0">
              <div lang={locale} className="main-view">
                <div lang={locale} className="feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesPersonalFolderTitle"
                        lang={locale}
                        tabIndex="0"
                        to="personal-folder"
                        alt={t(
                          "Personal Folder, click here for more information"
                        )}
                      >
                        {t("Personal Folder")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesPersonalFolderSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                    alt={t("Repository of cross-boundary medical records")}
                  >
                    {t("Repository of cross-boundary medical records")}
                  </p>
                </div>
                <div lang={locale} className="image-area">
                  <img
                    id="imgHomeFeaturesPersonalFolderPhone"
                    className="adr-img part-img"
                    imgIndex="img0"
                    src={load(
                      `./resources/images/${locale}/home_personal_folder.png`
                    )}
                    alt={t("Personal Folder")}
                  />
                  <img
                    id="imgHomeFeaturesPersonalFolderIcon"
                    className="adr-icon"
                    iconIndex="icon0"
                    src={load(
                      `./resources/images/personal_folder_home_icon.png`
                    )}
                    alt={t("Personal Folder")}
                  />
                </div>
                <img
                  aria-hidden="true"
                  className="right-three-bg"
                  id="imgHomeFeaturesPersonalFolderBg"
                  src={load(`./resources/images/right_five.png`)}
                  alt=""
                />
              </div>
            </div>
            <div
              className="promotion-features appointment-bg"
              index="2"
              style={{
                background: `url(${load(
                  "./resources/images/right_six.png"
                )}) no-repeat`,
              }}
            >
              <div lang={locale} className="main-view">
                <div lang={locale} className="display-mobile feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesAppointmentMobileTitle"
                        lang={locale}
                        tabIndex="0"
                        to="health-management"
                        alt={t(
                          "Health Management​, click here for more information"
                        )}
                      >
                        {t("Health Management​")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesHealthManagement​MobileSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t("Your health data diary on-the-go")}
                  </p>
                </div>
                <div lang={locale} className="image-area">
                  <img
                    id="imgHomeFeaturesHealthManagement​Phone"
                    className="appointment-img part-img"
                    imgIndex="img0"
                    src={load(
                      `./resources/images/${locale}/landing-health-management-phone.png`
                    )}
                    alt={t("Health Management​")}
                  />
                  <img
                    id="imgHomeFeaturesHealthManagement​Icon"
                    className="appointment-icon"
                    iconIndex="icon0"
                    src={load(
                      `./resources/images/landing-health-management-icon.png`
                    )}
                    alt={t("Health Management​")}
                  />
                </div>
                <div lang={locale} className="display-desktop feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesAppointmentTitle"
                        lang={locale}
                        tabIndex="0"
                        to="health-management"
                        alt={t("Appointment, click here for more information")}
                      >
                        {t("Health Management​")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesAppointmentSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t("Your health data diary on-the-go")}
                  </p>
                </div>
              </div>
            </div>
            <div
              className="promotion-features vaccine-bg odd-bg"
              index="3"
              style={{ background: "#f8fdfd" }}
            >
              <div lang={locale} className="main-view">
                <div lang={locale} className=" feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesVaccineTitle"
                        lang={locale}
                        tabIndex="3"
                        to="clinical-vaccine"
                        alt={t("Vaccines, click here for more information")}
                      >
                        {t("Vaccine")} <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesVaccineSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t("Your electronic vaccine records,anywhere, anytime")}
                  </p>
                </div>
                <div lang={locale} className="image-area">
                  <img
                    id="imgHomeFeaturesVaccinePhone"
                    className="adr-img part-img"
                    imgIndex="img0"
                    src={load(`./resources/images/${locale}/home_vaccine.png`)}
                    alt={t("Vaccines")}
                  />
                  <img
                    id="imgHomeFeaturesVaccineIcon"
                    className="adr-icon"
                    iconIndex="icon0"
                    src={load(`./resources/images/landing-vaccine-icon.png`)}
                    alt={t("Vaccines")}
                  />
                </div>
                <img
                  aria-hidden="true"
                  className="left-two-bg"
                  id="imgHomeFeaturesVaccineBg"
                  src={load(`./resources/images/left_three.png`)}
                  alt=""
                />
              </div>
            </div>

            <div
              className="promotion-features appointment-bg"
              index="2"
              style={{
                background: `url(${load(
                  "./resources/images/right_six.png"
                )}) no-repeat`,
              }}
            >
              <div lang={locale} className="main-view">
                <div lang={locale} className="display-mobile feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesAppointmentMobileTitle"
                        lang={locale}
                        tabIndex="0"
                        to="clinical-appointment"
                        alt={t("Appointment, click here for more information")}
                      >
                        {t("Appointments")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesAppointmentMobileSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t("Check out your appointment records effortlessly")}
                  </p>
                </div>
                <div lang={locale} className="image-area">
                  <img
                    id="imgHomeFeaturesAppointmentPhone"
                    className="appointment-img part-img"
                    imgIndex="img0"
                    src={load(
                      `./resources/images/${locale}/home_appointments.png`
                    )}
                    alt={t("Appointments")}
                  />
                  <img
                    id="imgHomeFeaturesAppointmentIcon"
                    className="appointment-icon"
                    iconIndex="icon0"
                    src={load(`./resources/images/appointment_icon.png`)}
                    alt={t("Appointments")}
                  />
                </div>
                <div lang={locale} className="display-desktop feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesAppointmentTitle"
                        lang={locale}
                        tabIndex="0"
                        to="clinical-appointment"
                        alt={t("Appointment, click here for more information")}
                      >
                        {t("Appointments")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesAppointmentSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t("Check out your appointment records effortlessly")}
                  </p>
                </div>
              </div>
            </div>
            <div className="promotion-features odd-bg" index="3">
              <div lang={locale} className="main-view">
                <div lang={locale} className="feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesAllergyTitle"
                        lang={locale}
                        tabIndex="0"
                        to="clinical-allergy"
                        alt={t("Allergy, click here for more information")}
                      >
                        {t("Allergy")} <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesAllergySubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                    alt={t(
                      "Provide you a close up on allergy and adverse drug reaction records"
                    )}
                  >
                    {t(
                      "Provide you a close up on allergy and adverse drug reaction records"
                    )}
                  </p>
                </div>
                <div lang={locale} className="image-area">
                  <img
                    id="imgHomeFeaturesAllergyPhone"
                    className="adr-img part-img"
                    imgIndex="img0"
                    src={load(`./resources/images/${locale}/home_allergy.png`)}
                    alt={t("Allergy")}
                  />
                  <img
                    id="imgHomeFeaturesAllergyIcon"
                    className="adr-icon"
                    iconIndex="icon0"
                    src={load(`./resources/images/allergy_home_icon.png`)}
                    alt={t("Allergy")}
                  />
                </div>
                <img
                  aria-hidden="true"
                  className="right-three-bg"
                  id="imgHomeFeaturesAllergyBg"
                  src={load(`./resources/images/right_five.png`)}
                  alt=""
                />
              </div>
            </div>

            <div
              className="promotion-features medication-bg"
              index="4"
              style={{
                background: `url(${load(
                  "./resources/images/left_two.png"
                )}) no-repeat`,
              }}
            >
              <div lang={locale} className="main-view">
                <div lang={locale} className="display-mobile feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesHealthNewsMobileTitle"
                        lang={locale}
                        tabIndex="0"
                        to="clinical-medication"
                        alt={t("Medications, click here for more information")}
                      >
                        {t("Medications")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesHealthNewsMobileSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t(
                      "Keep the records with you for better use of medications"
                    )}
                  </p>
                </div>
                <div lang={locale} className="image-area">
                  <img
                    id="imgHomeFeaturesHealthNewsPhone"
                    className="news-img part-img"
                    imgIndex="img0"
                    src={load(
                      `./resources/images/${locale}/home_medication.png`
                    )}
                    alt={t("Medications")}
                  />
                  <img
                    id="imgHomeFeaturesHealthNewsIcon"
                    className="news-icon"
                    iconIndex="icon0"
                    src={load(`./resources/images/adr_icon.png`)}
                    alt={t("Medications")}
                  />
                </div>
                <div lang={locale} className="display-desktop feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesHealthNewsTitle"
                        lang={locale}
                        tabIndex="0"
                        to="clinical-medication"
                        alt={t("Medications, click here for more information")}
                      >
                        {t("Medications")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesHealthNewsSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t(
                      "Keep the records with you for better use of medications"
                    )}
                  </p>
                </div>
              </div>
            </div>

            <div className="promotion-features odd-bg" index="5">
              <div lang={locale} className="main-view">
                <div lang={locale} className=" feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesInvestigationsTitle"
                        lang={locale}
                        tabIndex="0"
                        to="clinical-investigations"
                        alt={t(
                          "Investigations, click here for more information"
                        )}
                      >
                        {t("Investigations")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesInvestigationsSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t("View your investigation reports at all times")}
                  </p>
                </div>
                <div lang={locale} className="image-area">
                  <img
                    id="imgHomeFeaturesInvestigationsBg"
                    className="ppp-img part-img"
                    imgIndex="img0"
                    src={load(
                      `./resources/images/${locale}/home_investigation.png`
                    )}
                    alt={t("Investigations")}
                  />
                  <img
                    id="imgHomeFeaturesInvestigationsIcon"
                    className="ppp-icon"
                    iconIndex="icon0"
                    src={load(`./resources/images/home_investigation_icon.png`)}
                    alt={t("Investigations")}
                  />
                </div>
              </div>
            </div>
            <div className="promotion-features " index="6">
              <div lang={locale} className="main-view">
                <div lang={locale} className="display-mobile feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesHealthNewsTitle"
                        lang={locale}
                        tabIndex="0"
                        to="health-news"
                        alt={t("Health news, click here for more information")}
                      >
                        {t("Health News")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id=""
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t("Find your healthcare provider and doctor with ease")}
                  </p>
                </div>
                <div lang={locale} className="image-area">
                  <img
                    id="textHomeFeaturesHealthNewsSubtitle"
                    className="news-img part-img"
                    imgIndex="img0"
                    src={load(
                      `./resources/images/${locale}/home_health_news.png`
                    )}
                    alt={t("Health News")}
                  />
                  <img
                    id="imgHomeFeaturesHealthNewsIcon"
                    className="news-icon"
                    iconIndex="icon0"
                    src={load(`./resources/images/news_icon.png`)}
                    alt={t("Health News")}
                  />
                </div>
                <div lang={locale} className="display-desktop feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="textHomeFeaturesHealthNewsSubtitle"
                        lang={locale}
                        tabIndex="0"
                        to="health-news"
                        alt={t("Health news, click here for more information")}
                      >
                        {t("Health News")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesHealthNewsSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t("Keep your finger on the pulse of recent health topics")}
                  </p>
                </div>
              </div>
            </div>
            <div className="promotion-features odd-bg" index="7">
              <div lang={locale} className="main-view">
                <div lang={locale} className="feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesDoctorSearchTitle"
                        lang={locale}
                        tabIndex="0"
                        to="doctor-search"
                        alt={t(
                          "Doctor search, click here for more information"
                        )}
                      >
                        {t("Doctor Search")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesDoctorSearchMobileSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t("Find your healthcare provider and doctor with ease")}
                  </p>
                </div>
                <div lang={locale} className="image-area">
                  <img
                    id="imgHomeFeaturesChildGrowthPhone"
                    className="growth-img part-img"
                    imgIndex="img0"
                    src={load(`./resources/images/${locale}/02_doctor.png`)}
                    alt={t("Doctor Search")}
                  />
                  <img
                    id="imgHomeFeaturesDoctorSearchIcon"
                    className="ppp-icon"
                    iconIndex="icon0"
                    src={load(`./resources/images/doctor_icon.png`)}
                    alt={t("Doctor Search")}
                  />
                </div>
              </div>
            </div>
            <div
              className="promotion-features ppp-bg"
              index="8"
              style={{
                background: `url(${load(
                  "./resources/images/right_one.png"
                )}) no-repeat`,
              }}
            >
              <div lang={locale} className="main-view">
                <div lang={locale} className="display-mobile feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="lnikHomeFeaturesChildGrowthMobileTitle"
                        lang={locale}
                        tabIndex="0"
                        to="child-growth"
                        alt={t(
                          "Child growth record, click here for more information"
                        )}
                      >
                        {t("Child Growth Record")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesChildGrowthMobileSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t(
                      "Provide you with a consolidated view of the balance and quota of health programmes"
                    )}
                  </p>
                </div>
                <div lang={locale} className="image-area">
                  <img
                    id="imgHomeFeaturesChildGrowthPhone"
                    className="growth-img part-img"
                    imgIndex="img0"
                    src={load(`./resources/images/${locale}/03_growth.png`)}
                    alt={t("Health Programme")}
                  />
                  <img
                    id="imgHomeFeaturesChildGrowthIcon"
                    className="growth-icon"
                    iconIndex="icon0"
                    src={load(`./resources/images/${locale}/img_growth.png`)}
                    alt={t("Child Growth Record")}
                  />
                </div>
                <div lang={locale} className="display-desktop feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesChildGrowthTitle"
                        lang={locale}
                        tabIndex="0"
                        to="child-growth"
                        alt={t(
                          "Child growth record, click here for more information"
                        )}
                      >
                        {t("Child Growth Record")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesChildGrowthSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t(
                      "Understand the growth and capture every treasurable moments of your child"
                    )}
                  </p>
                </div>
                <img
                  aria-hidden="true"
                  className="left-two-bg"
                  src={load(`./resources/images/left_three.png`)}
                  alt=""
                />
              </div>
            </div>
            <div className="promotion-features odd-bg" index="10">
              <div lang={locale} className="main-view">
                <div lang={locale} className=" feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesHealthProgrammeMobileTitle"
                        lang={locale}
                        tabIndex="0"
                        to="health-program"
                        alt={t(
                          "Health programme, click here for more information"
                        )}
                      >
                        {t("Health Programme")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesHealthProgrammeSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t(
                      "Provide you with a consolidated view of the balance and quota of health programmes"
                    )}
                  </p>
                </div>
                <div lang={locale} className="image-area">
                  <img
                    id="imgHomeFeaturesHealthProgrammePhone"
                    className="ppp-img part-img"
                    imgIndex="img0"
                    src={load(
                      `./resources/images/${locale}/home_health_programme.png`
                    )}
                    alt={t("Health Programme")}
                  />
                  <img
                    id="imgHomeFeaturesHealthProgrammeIcon"
                    className="ppp-icon"
                    iconIndex="icon0"
                    src={load(`./resources/images/ppp_icon.png`)}
                    alt={t("Health Programme")}
                  />
                </div>
              </div>
            </div>
            <div
              className="promotion-features ppp-bg"
              index="11"
              style={{
                background: `url(${load(
                  "./resources/images/right_one.png"
                )}) no-repeat`,
              }}
            >
              <div lang={locale} className="main-view">
                <div lang={locale} className="display-mobile feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesMyFamilyMobileTitle"
                        lang={locale}
                        tabIndex="0"
                        to="carergiver"
                        alt={t("My family, click here for more information")}
                      >
                        {t("My Family")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesMyFamilyMobileSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t("Keep the health of your child within easy reach")}
                  </p>
                </div>
                <div lang={locale} className="image-area">
                  <img
                    id="imgHomeFeaturesMyFamilyPhone"
                    className="growth-img part-img"
                    imgIndex="img0"
                    src={load(
                      `./resources/images/${locale}/home_caregiver.png`
                    )}
                    alt={t("My Family")}
                  />
                  <img
                    id="imgHomeFeatureMyFamilyIcon"
                    className="growth-icon"
                    iconIndex="icon0"
                    src={load(`./resources/images/feature_6_icon.png`)}
                    alt={t("My Family")}
                  />
                </div>
                <div lang={locale} className="display-desktop feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesMyFamilyTitle"
                        lang={locale}
                        tabIndex="0"
                        to="caregiver"
                        alt={t(
                          "Keep the health of your child within easy reach"
                        )}
                      >
                        {t("My Family")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesMyFamilySubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t("Keep the health of your child within easy reach")}
                  </p>
                </div>
                <img
                  aria-hidden="true"
                  className="left-two-bg"
                  src={load(`./resources/images/left_three.png`)}
                  alt=""
                />
              </div>
            </div>
            <div className="promotion-features odd-bg" index="12">
              <div lang={locale} className="main-view">
                <div lang={locale} className=" feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesMyFamilyDoctorMobileTitle"
                        lang={locale}
                        tabIndex="0"
                        to="my-family-doctor"
                        alt={t(
                          "My Family Doctor, click here for more information"
                        )}
                      >
                        {t("My Family Doctor")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesMyFamilyDoctorSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t("Manage your health with your family doctor")}
                  </p>
                </div>
                <div lang={locale} className="image-area">
                  <img
                    id="imgHomeFeaturesMyFamilyDoctorPhone"
                    className="ppp-img part-img"
                    imgIndex="img0"
                    src={load(
                      `./resources/images/${locale}/home_family_doctor.png`
                    )}
                    alt={t("My Family Doctor")}
                  />
                  <img
                    id="imgHomeFeaturesMyFamilyDoctorIcon"
                    className="ppp-icon"
                    iconIndex="icon0"
                    src={load(`./resources/images/home_family_doctor_icon.png`)}
                    alt={t("My Family Doctor")}
                  />
                </div>
              </div>
            </div>

            <div
              className="promotion-features ppp-bg"
              index="13"
              style={{
                background: `url(${load(
                  "./resources/images/right_one.png"
                )}) no-repeat`,
              }}
            >
              <div lang={locale} className="main-view">
                <div lang={locale} className="display-mobile feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="lnikHomeFeaturesHealthcareDocumentMobileTitle"
                        lang={locale}
                        tabIndex="0"
                        to="healthcare-document"
                        alt={t(
                          "Healthcare document, click here for more information"
                        )}
                      >
                        {t("Healthcare Document")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="imgHomeFeaturesHealthcareDocumentPhone"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t(
                      "Keep the records with you for better health management"
                    )}
                  </p>
                </div>
                <div lang={locale} className="image-area">
                  <img
                    id="imgHomeFeatureHealthcareDocumentPhone"
                    className="growth-img part-img"
                    imgIndex="img0"
                    src={load(
                      `./resources/images/${locale}/home_healthcare_document.png`
                    )}
                    alt={t("Healthcare Document")}
                  />
                  <img
                    id="imgHomeFeaturesHealthcareDocumentIcon"
                    className="growth-icon"
                    iconIndex="icon0"
                    src={load(
                      `./resources/images/home_healthcare_document_icon.png`
                    )}
                    alt={t("Healthcare Document")}
                  />
                </div>
                <div lang={locale} className="display-desktop feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesMedicalCertificateMobileTitle"
                        lang={locale}
                        tabIndex="0"
                        to="healthcare-document"
                        alt={t(
                          "Healthcare document, click here for more information"
                        )}
                      >
                        {t("Healthcare Document")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesMedicalCertificateSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t(
                      "Keep the records with you for better health management"
                    )}
                  </p>
                </div>
              </div>
            </div>
            <div className="promotion-features odd-bg" index="12">
              <div lang={locale} className="main-view">
                <div lang={locale} className=" feature-content">
                  <p className="part-title">
                    <b>
                      <NavLink
                        id="linkHomeFeaturesMedicalCertificateMobileTitle"
                        lang={locale}
                        tabIndex="0"
                        to="medical-certificate"
                        alt={t(
                          "Medical Certificate, click here for more information"
                        )}
                      >
                        {t("Medical Certificate")}{" "}
                        <span className="arrow-right-1st"></span>
                      </NavLink>
                    </b>
                  </p>
                  <p
                    id="textHomeFeaturesMedicalCertificateSubtitle"
                    className={`part-dec ${locale === "en" ? "" : "tc"}`}
                    lang={locale}
                  >
                    {t("Manage all your medical certificates in one place")}
                  </p>
                </div>
                <div lang={locale} className="image-area">
                  <img
                    id="imgHomeFeaturesMedicalCertificatePhone"
                    className="ppp-img part-img"
                    imgIndex="img0"
                    src={load(
                      `./resources/images/${locale}/home_medical_certificate.png`
                    )}
                    alt={t("Medical Certificate")}
                  />
                  <img
                    id="imgHomeFeaturesMedicalCertificateIcon"
                    className="ppp-icon"
                    iconIndex="icon0"
                    src={load(
                      `./resources/images/${locale}/home_medical_certificate_icon.png`
                    )}
                    alt={t("Medical Certificate")}
                  />
                </div>
              </div>
            </div>

            <div
              id="imgHomeFeaturesBottomBg"
              className="promotion-features mission-bg"
              index="14"
              style={{
                background: `url(${load(
                  "./resources/images/bottom_bg.png"
                )}) no-repeat`,
              }}
            >
              <div lang={locale} className="mission-dec" txtIndex="txt9">
                <p id="linkHomeFeaturesBottomContent" lang={locale}>
                  {t(
                    "醫健通 eHealth App is developed by the Electronic Health Record Office of the Health Bureau"
                  )}
                  <br />
                  {t(
                    "With 醫健通 eHealth, you can take charge of your and your loved ones' health, right at your fingertips"
                  )}
                </p>
              </div>
            </div>
          </div>

          <div lang={locale} className="footer">
            <div lang={locale} className="footerLink">
              <span
                id="linkFacebookLogo"
                className="footerLinkLogo"
                tabIndex="0"
                onKeyDown={(e) =>
                  e.keyCode === 13 && window.open(facebookURL, "_blank")
                }
                onClick={() => {
                  window.open(facebookURL, "_blank");
                }}
              >
                <img
                  id="imgFacebookLogo"
                  src={load(`./resources/images/footer_facebookLogo.png`)}
                  alt={t("Facebook Page")}
                />
              </span>
              <span
                id="linkIGLogo"
                className="footerLinkLogo"
                tabIndex="0"
                onKeyDown={(e) =>
                  e.keyCode === 13 && window.open(igURL, "_blank")
                }
                onClick={() => {
                  window.open(igURL, "_blank");
                }}
              >
                <img
                  id="imgIGLogo"
                  src={load(`./resources/images/footer_igLogo.png`)}
                  alt={t("Instagram")}
                />
              </span>
              <span
                id="linkYoutubeLogo"
                className="footerLinkLogo"
                tabIndex="0"
                onKeyDown={(e) =>
                  e.keyCode === 13 && window.open(youtubeURL, "_blank")
                }
                onClick={() => {
                  window.open(youtubeURL, "_blank");
                }}
              >
                <img
                  id="imgYoutubeLogo"
                  src={load(`./resources/images/footer_youtubeLogo.png`)}
                  alt={t("Youtube Channel")}
                />
              </span>
            </div>
            <table>
              <tr>
                <td
                  className={`display-desktop ${
                    locale === "en" ? "display-desktop-en" : ""
                  }`}
                >
                  <div lang={locale} className="badge margin-l-40 margin-t-20">
                    <span
                      id="textFooterBadgeAppStore"
                      alt={t(
                        "App Store and the App Store logo are trademarks of Apple Inc"
                      )}
                    >
                      {t(
                        "App Store and the App Store logo are trademarks of Apple Inc"
                      )}
                    </span>
                  </div>
                  <div lang={locale} className="badge margin-l-40">
                    <span
                      id="textFooterBadgeGooglePlay"
                      alt={t(
                        "Google Play and the Google Play logo are trademarks of Google LLC"
                      )}
                    >
                      {t(
                        "Google Play and the Google Play logo are trademarks of Google LLC"
                      )}
                    </span>
                  </div>
                  <div lang={locale} className="badge margin-l-40">
                    <span
                      id="textFooterBadgeHuawei"
                      alt={t(
                        "Huawei AppGallery is provided by Huawei Services (Hong Kong) Co., Limited"
                      )}
                    >
                      {t(
                        "Huawei AppGallery is provided by Huawei Services (Hong Kong) Co., Limited"
                      )}
                    </span>
                  </div>
                </td>
                <td>
                  <div
                    id="textFooterCopyright"
                    lang={locale}
                    className={`copyright ${
                      locale === "en" ? "copyright-en" : ""
                    }`}
                  >
                    <div lang={locale}>
                      <span>
                        {new Date().getFullYear()}©
                        <span className="margin-l-10">|</span>
                        <span className="hyperlink margin-l-10">
                          <a
                            id="btnImportantNotices"
                            href={importantNoticesURL}
                            target="_blank"
                            role="text"
                            tabIndex="0"
                            aria-label={t(
                              "Important Notices Click here to view Important Notices"
                            )}
                          >
                            {t("Important Notices")}
                          </a>
                        </span>
                        <span className="margin-l-10">|</span>
                        <span className="hyperlink margin-l-10">
                          <a
                            id="btnPrivacyPolicy"
                            href="/privacy-policy"
                            role="text"
                            tabIndex="0"
                            aria-label={t(
                              "Privacy Policy Click here to view Privacy Policy"
                            )}
                          >
                            {t("Privacy Policy")}
                          </a>
                        </span>
                        <span className="margin-l-10">|</span>
                        <span className="hyperlink margin-l-10">
                          <a
                            id="btnTermsOfUse"
                            href="/terms-of-use"
                            role="text"
                            tabIndex="0"
                            aria-label={t(
                              "Terms of Use Click here to view Terms of Use"
                            )}
                          >
                            {t("Terms of Use")}
                          </a>
                        </span>
                        <span className="margin-l-10">|</span>
                        <span className="hyperlink margin-l-10">
                          <NavLink exact to="/ehealth-faq" alt={t("FAQs")}>
                            {t("FAQs")}
                          </NavLink>
                        </span>
                      </span>
                      <br
                        className={`copyright-warp ${
                          locale === "en" ? "copyright-warp-en" : ""
                        }`}
                      ></br>
                      <span className="margin-l-40">
                        <span className="margin-l-10">
                          {t("Enquiry Hotline")}
                        </span>
                        <br
                          className={`copyright-warp ${
                            locale === "en" ? "copyright-warp-en" : ""
                          }`}
                        ></br>
                        <span className="margin-l-10">
                          <img
                            id="imgPublicHotlineLogo"
                            aria-hidden="true"
                            src={load(`./resources/images/contact_phone.png`)}
                            alt=""
                          />
                          {t("Public Hotline")}
                        </span>
                        <span className="margin-l-10">
                          <img
                            id="imgProfessionalHotlineLogo"
                            aria-hidden="true"
                            src={load(`./resources/images/contact_phone.png`)}
                            alt=""
                          />
                          {t("Professional Hotline")}
                        </span>
                      </span>
                    </div>
                  </div>
                  <div lang={locale} className="display-mobile margin-t-20">
                    <div lang={locale} className="badge margin-l-40">
                      <span
                        id="textFooterMobileBadgeAppStore"
                        alt={t(
                          "App Store and the App Store logo are trademarks of Apple Inc"
                        )}
                      >
                        {t(
                          "App Store and the App Store logo are trademarks of Apple Inc"
                        )}
                      </span>
                    </div>
                    <div lang={locale} className="badge margin-l-40">
                      <span
                        id="textFooterMobileBadgeGooglePlay"
                        alt={t(
                          "Google Play and the Google Play logo are trademarks of Google LLC"
                        )}
                      >
                        {t(
                          "Google Play and the Google Play logo are trademarks of Google LLC"
                        )}
                      </span>
                    </div>
                    <div lang={locale} className="badge margin-l-40">
                      <span
                        id="textFooterMobileBadgeHuawei"
                        alt={t(
                          "Huawei AppGallery is provided by Huawei Services (Hong Kong) Co., Limited"
                        )}
                      >
                        {t(
                          "Huawei AppGallery is provided by Huawei Services (Hong Kong) Co., Limited"
                        )}
                      </span>
                    </div>
                    <div lang={locale}>
                      <span
                        id="linkFooterMobileW3cLogo"
                        className="w3clogo"
                        tabIndex="0"
                        onClick={() => {
                          window.open(wcag22aURL, "_blank");
                        }}
                      >
                        <img
                          src={load(`./resources/images/w3c_logo.png`)}
                          alt={t(
                            "Explanation of WCAG 20 Level Double-A Conformance"
                          )}
                        />
                      </span>
                    </div>
                  </div>
                </td>
                <td className="display-desktop align-right">
                  <span
                    id="linkFooterW3cLogo"
                    className="w3clogo"
                    tabIndex="0"
                    onKeyDown={(e) =>
                      e.keyCode === 13 && window.open(wcag22aURL, "_blank")
                    }
                    onClick={() => {
                      window.open(wcag22aURL, "_blank");
                    }}
                  >
                    <img
                      src={load(`./resources/images/w3c_logo.png`)}
                      alt={t(
                        "Explanation of WCAG 20 Level Double-A Conformance"
                      )}
                    />
                  </span>
                </td>
              </tr>
            </table>
          </div>
        </div>
        <FixedDownloadBar {...this.props} />
      </div>
    );
  }
}

export default PageHOC(PromotionPage);

import axios from "axios";
import moment from "moment";
import apiInfo from "./resources/apis/api.json";

export async function getSchoolRankings() {
  let url = window.location.origin + "/SchoolRankings.json";
  try {
    const response = await axios.get(url);
    return response;
  } catch (error) {
    throw error;
  }
}
export async function SubmitRequest(url, method, language, data) {
  // try {
  // 	if (method === 'POST') {
  // 		const postResponse = await axios.post(url, data, {
  // 			headers: {
  // 				'Content-Type': 'application/json',
  // 				'X-IBM-Client-Id': apiInfo.CLIENT_ID,
  // 				'locale': language
  // 			  }
  // 		  })
  // 		return postResponse;
  // 	} else {
  // 		const getResponse = await axios.get(url, data, {
  // 			headers: {
  // 				'Content-Type': 'application/json',
  // 				'X-IBM-Client-Id': apiInfo.CLIENT_ID,
  // 				'locale': language
  // 			  }
  // 		  })
  // 		return getResponse;
  // 	}
  // } catch (error) {
  // 	throw error;
  // }
}

export async function getVaccineList(language) {
  // const timeStamp = moment().format('YYYYMMDDHHmmss');
  // let url = window.location.protocol + '//' + getAPIHostname() + '/vaccineCalculator/vaccineList.json?t=' + timeStamp;
  // const data = {};
  // return await SubmitRequest(url, "GET", language, data);
}

export async function getCalculatorSettings(language) {
  // const timeStamp = moment().format('YYYYMMDDHHmmss');
  // let url = window.location.protocol + '//' + getAPIHostname() + '/vaccineCalculator/vaccinePassCalculator.json?t=' + timeStamp;
  // const data = {};
  // return await SubmitRequest(url, "GET", language, data);
}

export async function getCalculatorResult(language, data) {
  // let url = window.location.protocol + '//' + getAPIHostname() + '/rest/vaccinePassCalculator/calculator';
  // return await SubmitRequest(url, "POST", language, data);
}

export async function sendStat(language, data) {
  // let url = window.location.protocol + '//' + getAPIHostname() + '/rest/stats';
  // return await SubmitRequest(url, "POST", language, data);
}

// function getInitTokenRequestData() {
// 	const secretKey = 'h76sgf29dvc6291hs761';
// 	//const secretKey = '84f7e2ht3u3q32ad3fa2';
// 	const timeStamp = moment().format('YYYYMMDD');
// 	const clientID = apiInfo.CLIENT_ID;
// 	const key = clientID + timeStamp + secretKey;
// 	console.log('key: ', key);
// 	let hash = sha512(key);
// 	console.log('hash: ', hash);
// 	const data = {
// 		hash: hash,
// 		timestamp: timeStamp,
// 	  };

// 	  return data;
// }

function getAPIHostname() {
  // let apiHost = '';
  // if(window.location.host.includes('dev') || window.location.host.includes('tst') || window.location.host.includes('uat')){
  // 	apiHost = window.location.host + '/epp-static-res/common';
  // }else{
  // 	//to handle PRD primary and secondary site
  // 	apiHost = 'www.portal.ehealth.gov.hk/epp-static-res/common';
  // }
  //   return apiHost;
}

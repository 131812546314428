import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class Covid19QRPage extends Component {
  render() {
    const { locale, t } = this.props;
    return (
      <div
        className={`page-container page-container-${locale}`}
        id="MainContent"
      >
        <div className="static_page_header static_page_header_qr">
          <div
            className="static_page_header_bg"
            style={{
              background: `url(${load(
                "./resources/images/qr_top_bg.png"
              )}) no-repeat 60%`,
            }}
          ></div>
          <div className="container">
            <div className="row vertical-align">
              <div className="col-sm-6">
                <img
                  id="imgCovidQRTopBannerImage"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="0"
                  src={load(`./resources/images/covid19-qr-top-banner.png`)}
                  alt=" "
                />
              </div>
              <div className="col-sm-6 static-page-header-left">
                <p
                  id="textCovidQRTopBannerTitle"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="400"
                  className="static_page_top_title"
                >
                  {t("View Covid-19 vaccine record with one tap")}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div
          id="textCovidQRMainTitle"
          data-aos="fadein"
          data-aos-anchor-placement="center-center"
          data-aos-delay="500"
          data-aos-offset="-400"
          className="static_page_section_title"
          role="text"
          aria-label={t(
            "To show COVID-19 vaccination record QR code via the 醫健通 eHealth App ALT"
          )}
        >
          {/* <p className="subtitle">{t("To show COVID-19 vaccination record")}</p> */}
          {/* <p className="maintitle">{t("and the COVID-19 vaccination medical exemption certificate QR code")}<span>{t('and')}</span></p> */}
          {locale === "en" ? (
            <>
              <p className="subtitle">
                {t("To show ")}
                <span>{t("COVID-19 vaccination record")}</span>
              </p>
              <p className="maintitle">
                <span>{t("and")}</span>
                {t(
                  "and the COVID-19 vaccination medical exemption certificate QR code"
                )}
              </p>
            </>
          ) : (
            <>
              <p className="subtitle">
                {t("To show COVID-19 vaccination record CN")}
              </p>
              <p className="maintitle">
                {t(
                  "and the COVID-19 vaccination medical exemption certificate QR code"
                )}
                <span>{t("and")}</span>
              </p>
            </>
          )}
          <p className="thirdtitle">{t("via the 醫健通 eHealth App")}</p>

          <span className="short_line"></span>
        </div>

        <div className="container-fluid covid_center_section covid-qr-p1-row">
          <div className="container">
            <div className="row ">
              <div className="col-md-12">
                <div
                  id="textCovidQRSection1Title"
                  data-aos="fade-up"
                  className="second-usage-title usage-mainTitle covid-main-title covid-main-title-long"
                  role="text"
                  aria-label={t(
                    "Click the COVID-19 vaccine icon in Vaccines and show the COVID-19 vaccination record QR code easily"
                  )}
                >
                  <div className="fullwidth-screen-container">
                    <img
                      id="img0CovidQRSection1"
                      data-aos="fade-up"
                      data-aos-anchor-placement="center-center"
                      data-aos-offset="-300"
                      className="absolute-image fullwidth-screen"
                      src={load(`./resources/images/${locale}/covid_qr_p0.png`)}
                      alt=" "
                    />
                  </div>
                  {t("click covid icon")}
                  <img
                    src={load(`./resources/images/covid19-inapp-icon.png`)}
                    alt=" "
                  />
                  {t(
                    "icon in Vaccines and show the COVID-19 vaccination record QR code easily"
                  )}
                </div>
              </div>
              <div
                className="col-md-12 covid19-bottom-phone-container"
                style={{ padding: "0px" }}
              >
                <div
                  className="covid19-bottom-phone"
                  style={{ marginTop: "0px" }}
                >
                  <img
                    src={load(`./resources/images/covid_qr_p1_empty.png`)}
                    alt=""
                  />
                  <img
                    id="img1CovidQRSection1"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-300"
                    className="absolute-image"
                    src={load(`./resources/images/${locale}/covid_qr_p1_1.png`)}
                    alt=" "
                  />
                  <img
                    id="img2CovidQRSection1"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-300"
                    data-aos-delay="150"
                    className="absolute-image back"
                    src={load(`./resources/images/${locale}/covid_qr_p1_2.png`)}
                    alt=" "
                  />
                  <img
                    id="img2CovidQRSection1"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-300"
                    data-aos-delay="300"
                    className="absolute-image back"
                    src={load(`./resources/images/${locale}/covid_qr_p1_3.png`)}
                    alt=" "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid second-feature-section covid-qr-export-row">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-5 col-sm-12 col-sm-push-7"
              >
                <div
                  id="textCovidAdolescentSection4Title"
                  className="second-usage-title usage-mainTitle covid-main-title covid-main-title-long"
                  role="text"
                  lang={locale}
                  aria-label={t(
                    "Print the COVID-19 vaccination record with ease"
                  )}
                >
                  {t("Print the COVID-19 vaccination record with ease")}
                </div>
              </div>

              <div className="col-md-7 col-sm-12 col-sm-pull-5">
                <img
                  src={load(`./resources/images/covid_qr_p3_empty.png`)}
                  alt=""
                  className=""
                />
                <img
                  id="img1CovidAdolescentSection4"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/covid_qr_export_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img2CovidAdolescentSection4"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/covid_qr_export_2.png`
                  )}
                  alt=" "
                />
                <img
                  id="img3CovidAdolescentSection4"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="600"
                  className="absolute-image back"
                  src={load(`./resources/images/covid_qr_export_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        {/*<div className="container-fluid second-feature-section ">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textCovidAdolescentSection5Title"
                  className="second-usage-title usage-mainTitle covid-main-title covid-main-title-long"
                  role="text"
                  lang={locale}
                  aria-label={t(
                    "If you wish to manage your family members’ records, you could also save their QR codes to your App too"
                  )}
                >
                  {t(
                    "If you wish to manage your family members’ records, you could also save their QR codes to your App too"
                  )}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <div className="covid19-bottom-phone">
                  <img
                    src={load(`./resources/images/covid_qr_p3_empty.png`)}
                    alt=""
                    className="desktop-show"
                  />
                  <img
                    id="img1CovidAdolescentSection5"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-300"
                    className="absolute-image back desktop-show"
                    src={load(`./resources/images/${locale}/covid_qr_p3_1.png`)}
                    alt=" "
                  />
                  <img
                    id="img2CovidAdolescentSection5"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-300"
                    data-aos-delay="300"
                    className="absolute-image back desktop-show"
                    src={load(`./resources/images/covid_qr_p3_2.png`)}
                    alt=" "
                  />
                  <img
                    id="img3CovidAdolescentSection5"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-300"
                    data-aos-delay="600"
                    className="absolute-image back desktop-show"
                    src={load(`./resources/images/covid_qr_p3_3.png`)}
                    alt=" "
                  />

                  <img
                    src={load(
                      `./resources/images/covid_qr_p2_1_empty_mobile.png`
                    )}
                    className="mobile-show"
                  />
                  <img
                    id="img1MobileCovidAdolescentSection3"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-300"
                    className="absolute-image back mobile-show"
                    src={load(
                      `./resources/images/${locale}/covid_qr_p3_1_mobile.png`
                    )}
                    alt=" "
                  />
                  <img
                    id="img2MobileCovidAdolescentSection3"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-300"
                    data-aos-delay="300"
                    className="absolute-image back mobile-show"
                    src={load(`./resources/images/covid_qr_p3_2_mobile.png`)}
                    alt=" "
                  />
                  <img
                    id="img3MobileCovidAdolescentSection3"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-300"
                    data-aos-delay="600"
                    className="absolute-image back mobile-show"
                    src={load(`./resources/images/covid_qr_p3_3_mobile.png`)}
                    alt=" "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>*/}

        {/*<div className="container-fluid second-feature-section covid-qr-p4-row">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-sm-6 col-sm-12">
                <div
                  id="textCovidQRSection2Title"
                  className="second-feature-title covid-feature-title"
                  role="text"
                  aria-label={t(
                    "Click Health Programme to view your COVID-19 electronic testing record and electronic vaccination record"
                  )}
                >
                  {t(
                    "Click Health Programme to view your COVID-19 electronic testing record and electronic vaccination record"
                  )}
                </div>
              </div>
              <div className="col-sm-6 col-sm-12 covid19-bottom-phone-container">
                <div className="covid19-bottom-phone">
                  <img
                    src={load(`./resources/images/covid_qr_p4_empty.png`)}
                    alt=""
                  />
                  <img
                    id="img1CovidQRSection6"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-300"
                    className="absolute-image back"
                    src={load(`./resources/images/${locale}/covid_qr_p4_1.png`)}
                    alt=" "
                  />
                  <img
                    id="img2CovidQRSection6"
                    data-aos="fade-up"
                    data-aos-anchor-placement="center-center"
                    data-aos-offset="-300"
                    data-aos-delay="300"
                    className="absolute-image back"
                    src={load(`./resources/images/${locale}/covid_qr_p4_2.png`)}
                    alt=" "
                  />
                </div>
              </div>
            </div>
          </div>
        </div>*/}
        <div className="container-fluid second-feature-section reminder-full-row">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-8 col-sm-12 col-sm-push-4"
              >
                <div
                  id="textCovidQRReminderLabel"
                  className="second-feature-title covid-feature-title"
                >
                  {t("Reminder")}
                </div>
                <div
                  id="textCovidQRReminderContent"
                  className="second-feature-text covid-feature-text"
                >
                  {t(
                    "You can register with eHealth via COVID-19 Vaccination Programme in one stop. For more information, please refer to the eHealth leaflet."
                  )}
                </div>
                <div
                  id="btnCovidQRReminderbutton"
                  className="second-feature-text covid_feature_btn"
                >
                  <a
                    href="static/internal/eHealth_COVID-19_leaflet-01.pdf?ver=2"
                    target="_blank"
                    role="text"
                    aria-label={t("Click to Know More")}
                  >
                    {t("Know More")}
                  </a>
                </div>
              </div>

              <div className="col-md-4 col-sm-12 col-sm-pull-8 reminder-doctor-col">
                <img
                  src={load(`./resources/images/reminder-doctor-empty-bg.png`)}
                  alt=""
                />
                <img
                  id="btnCovidQRReminderDoctor"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back doctor-col-img"
                  src={load(`./resources/images/reminder-doctor.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(Covid19QRPage);

import moment from 'moment';
import { zeroPad } from './stringUtils';

export const isDateValid = (date) => {
    if (isNullOrEmpty(date.year) || isNullOrEmpty(date.month) || isNullOrEmpty(date.day)) {
        return false;
    }
    return moment(`${date.year}-${zeroPad(date.month)}-${zeroPad(date.day)}`).isValid();
}

export const isNullOrEmpty = (str) => {
    return str === null || str === '' || str === undefined;
}
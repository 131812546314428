import React, { Component } from 'react';
import load from '../../ImageLoder';
import AccessibilityWrapper from '../AccessibilityWrapper';

class VaccinePicker extends Component {
    constructor(props) {
        super(props);
        this.vaccineTooltipButtonRef = React.createRef();
        this.vaccineTooltipContentRef = React.createRef();
        this.dropdownRef = React.createRef();
        this.state = {
            showVaccine: false,
            showDropdown: false,
        };
        this.doseTexts = [
            'Not Yet Vaccinated',
            '1st dose',
            '2nd dose',
            '3rd dose',
            '4th dose',
            '5th dose',
            '6th dose',
            '7th dose',
            '8th dose',
            '9th dose',
        ]
        this.handleClickOutsideVaccine =
            this.handleClickOutsideVaccine.bind(this);
        this.handleClickOutsideDropdown =
            this.handleClickOutsideDropdown.bind(this);
    }

    handleClickOutsideVaccine(event) {
        if (
            this.vaccineTooltipButtonRef?.current &&
            this.vaccineTooltipContentRef?.current
        ) {
            if (
                this.vaccineTooltipButtonRef.current.contains(event.target) ||
                this.vaccineTooltipContentRef.current.contains(event.target)
            ) {
                // Do nothing
            } else {
                this.setState({ showVaccine: false });
            }
        }
    }

    handleClickOutsideDropdown(event) {
        if (
            this.dropdownRef?.current &&
            this.dropdownRef.current.contains(event.target)
        ) {
            // Do nothing
        } else {
            this.setState({ showDropdown: false });
        }
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutsideVaccine);
        document.addEventListener('mousedown', this.handleClickOutsideDropdown);
    }

    componentWillUnmount() {
        document.removeEventListener(
            'mousedown',
            this.handleClickOutsideVaccine
        );
        document.removeEventListener(
            'mousedown',
            this.handleClickOutsideDropdown
        );
    }


    isNotCommonVaccine() {
        const { vaccine } = this.props;
        return (
            vaccine === '' || vaccine === 'BioNTech' || vaccine === 'CoronaVac'
        );
    }

    render() {
        const { doseIndex, locale, t, vaccine, onVaccineChange, vaccinationOptions, recognisedVaccineUrl, isError, showError } =
            this.props;
        const coronaVacOpt = vaccinationOptions.find(opt => opt.id === 'CoronaVac');
        const biontechOpt = vaccinationOptions.find(opt => opt.id === 'BioNTech');
        
        let lang = 'en'; 
        if(locale ==='zh-CN'){
            lang ='sc'
        }else if(locale ==='zh-HK'){
            lang ='tc'
        }else{
            lang ='en'
        }

        return (
            <React.Fragment key={`dose-selection-${doseIndex}`}>
                <div className='vaccine-type-single'>
                    <p className='vaccine-type-title'>{t(this.doseTexts[doseIndex+1])}</p>
                    <div className='vaccine-type-single-selection'>
                        <AccessibilityWrapper 
                            onClick={() => {
                                onVaccineChange(coronaVacOpt.id, doseIndex);
                            }}
                            className={`calculator-radio-button vaccine-type radio-first ${
                                vaccine === coronaVacOpt.id ? 'active-button' : ''
                            }`}
                            id={coronaVacOpt.id+(doseIndex+1)}
                            alt={t('vaccinePassCalculator.form.vaccines.coronaVac')}
                        >
                                {lang === 'en' ? coronaVacOpt['tc'] : coronaVacOpt[lang]} 
                                <br />
                                {coronaVacOpt['en']}
                        </AccessibilityWrapper>
                        <AccessibilityWrapper 
                            onClick={() => {
                                onVaccineChange(biontechOpt.id, doseIndex);
                            }}
                            className={`calculator-radio-button vaccine-type radio-last ${
                                vaccine === biontechOpt.id
                                    ? 'active-button'
                                    : ''
                            }`}
                            id={biontechOpt.id+(doseIndex+1)}
                            alt={t('vaccinePassCalculator.form.vaccines.bionTech')}
                        >
                            {lang === 'en' ? biontechOpt['tc'] : biontechOpt[lang]} 
                            <br />
                            {biontechOpt['en']}
                        </AccessibilityWrapper>
                        <img
                            ref={this.vaccineTooltipButtonRef}
                            id='vaccine-type-tooltip-button'
                            className='tooltips-icon'
                            src={load(`./resources/images/tooltip-icon.png`)}
                            alt={t('vaccinePassCalculator.form.vaccines.tooltipsBtn')}
                            onClick={() => {
                                this.setState({
                                    showVaccine: !this.state.showVaccine,
                                });
                            }}
                            tabIndex="0"
                        />
                        <div className='clear'></div>
                        <div
                            className='custom-dropdown-container other-vaccine-button'
                            ref={this.dropdownRef}
                        >
                            <AccessibilityWrapper
                                className={`dropdown-selector ${
                                    this.isNotCommonVaccine()
                                        ? ''
                                        : 'vaccine-selected'
                                }`}
                                onClick={() => {
                                    this.setState({
                                        showDropdown: !this.state.showDropdown,
                                    });
                                }}
                                id={'otherVaccine'+(doseIndex+1)}
                                alt={t('vaccinePassCalculator.form.vaccines.otherVaccines')}
                            >
                                {this.isNotCommonVaccine() ? (
                                    <>
                                        {t('Other vaccines')} 
                                        <br />
                                        {t('Other vaccines en')}
                                    </>
                                ) : (
                                    vaccine
                                )}
                            </AccessibilityWrapper>
                            {
                                this.state.showDropdown && 
                                <ul>
                                    {
                                        vaccinationOptions
                                        .filter(vaccinationOpt => vaccinationOpt.id !== 'CoronaVac' && vaccinationOpt.id !== 'BioNTech')
                                        .map(vaccinationOpt => (
                                            <li
                                                key={vaccinationOpt.id}
                                                onClick={() => {
                                                    onVaccineChange(
                                                        vaccinationOpt.id,
                                                        doseIndex
                                                    );
                                                    this.setState({
                                                        showDropdown:
                                                            !this.state.showDropdown,
                                                    });
                                                }}
                                                id={vaccinationOpt.id+(doseIndex+1)}
                                            >
                                                {vaccinationOpt[lang]}
                                            </li>
                                        ))
                                    }
                                </ul>
                            }
                        </div>
                        {isError && showError && (
                            <div className='error-message'>
                            {t('vaccineErrMsg')}
                            </div>
                        )}
                    </div>
                </div>
                {this.state.showVaccine && (
                            <div
                                id='recovery-tooltip-content'
                                ref={this.vaccineTooltipContentRef}
                            >
                                <img
                                    id=''
                                    className='close-tooltips-icon'
                                    src={load(
                                        `./resources/images/covid19-popup/popup-close-desktop.png`
                                    )}
                                    alt={t('common.closeBtn')}
                                    onClick={() => {
                                        this.setState({
                                            showVaccine:
                                                !this.state.showVaccine,
                                        });
                                    }}
                                    tabIndex="0"
                                />
                                {`${t('vaccine tooltip text')} (`}
                                <a target="_blank" href={recognisedVaccineUrl && recognisedVaccineUrl[0] ? recognisedVaccineUrl[0][lang] : ''}>
                                    {recognisedVaccineUrl && recognisedVaccineUrl[0] ? recognisedVaccineUrl[0][lang] : ''}
                                </a>
                                {`)`}
                            </div>
                        )}
                <div className='clear'></div>
            </React.Fragment>
        );
    }
}

export default VaccinePicker;

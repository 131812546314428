import React, { Component } from 'react';
import load from '../../ImageLoder';
import { isIEBrowser } from '../../resources/utils/commonUtils';

class PhoneAnimationSlider extends Component{

    constructor(props) {
        super(props);
        this.state={ // Initial state
            isPhoneAnimationRunning: false,
            phoneAnimationInterval: null,
            recordImg: null, 
            oneImg: null, 
            twoImg: null, 
            threeImg: null, 
            fourImg: null,
            phoneframe: null,
            timeoutItems: []
        };
    }

    componentDidMount(){
        this.initPhoneAnimation(); // landing phone animation
    }

    componentWillUnmount(){
        clearInterval(this.state.phoneAnimationInterval);
        const timeoutItems = this.state.timeoutItems;
        timeoutItems.forEach(timeout => {
            clearTimeout(timeout);
        });
    }

    initPhoneAnimation = () => {
        let Phone = document.getElementById('Phone');
        for(let i=0;i<Phone.childNodes.length;i++){
            let node = Phone.childNodes[i]
            if(node.className === "phone-screen one"){
                this.setState({ oneImg: node });
            }else if(node.className === "phone-screen two"){
                this.setState({ twoImg: node });
            }else if(node.className === "phone-screen three"){
                this.setState({ threeImg: node });
            }else if(node.className === "phone-screen four"){
                this.setState({ fourImg: node });
            }else if(node.className ==="record"){
                this.setState({ recordImg: node });
            }else if(node.className==="phone-framework"){
                this.setState({ phoneframe: node });
            }
        }
        this.setState({
            phoneAnimationInterval: setInterval(this.phoneAnimation,1000)
        });
    }

    phoneAnimation = () => {
        //IE is not supported anymore, will not load phone animation
        const isIe = isIEBrowser();
        if(!this.state.isPhoneAnimationRunning && !isIe){
            let timeoutItems = []
            let {recordImg, oneImg, twoImg, threeImg, fourImg, phoneframe} = this.state;
            this.setState({ isPhoneAnimationRunning: true });
            oneImg.className="phone-screen one-img";
            phoneframe.alt=oneImg.getAttribute('alt');
            timeoutItems.push(setTimeout(()=>{
                twoImg.className="phone-screen two-img";
                phoneframe.alt=twoImg.getAttribute('alt');
            },2000));
            timeoutItems.push(setTimeout(()=>{
                threeImg.className="phone-screen three-img";
                phoneframe.alt=threeImg.getAttribute('alt');
            },4000));
            timeoutItems.push(setTimeout(()=>{
                fourImg.className="phone-screen four-img";
                phoneframe.alt=fourImg.getAttribute('alt');
            },6000));
            timeoutItems.push(setTimeout(()=>{
                recordImg.className="record show-record";
                phoneframe.alt="";
            },8000));
            timeoutItems.push(setTimeout(()=>{
                oneImg.className="phone-screen one";
                twoImg.className="phone-screen two";
                threeImg.className="phone-screen three";
                fourImg.className="phone-screen four";
                recordImg.className="record";
                this.setState({
                    isPhoneAnimationRunning: false
                });
            },10000));
            this.setState({timeoutItems});
        }
    }

    render() {
        const {t, locale} = this.props;
        return (
            <div lang={locale} className="phone" id="Phone">
                <img className="phone-framework" src={load(`./resources/images/phone.png`)} alt="" /> 
                <img className="phone-bg" src={load(`./resources/images/phone_bg.png`)} alt="" />                     
                <img aria-hidden="true" className="record" src={load(`./resources/images/${locale}/banner_record.png`)} alt="" />
                <img className="phone-screen four" src={load(`./resources/images/${locale}/landing.png`)} alt={ t("Welcome to 醫健通 E health") } />
                <img className="phone-screen one" src={load(`./resources/images/${locale}/on_one.png`)} alt={ t("Keep Track Of Your Health") } />
                <img className="phone-screen two" src={load(`./resources/images/${locale}/on_two.png`)} alt={ t("Be A Healthier You") } />
                <img className="phone-screen three" src={load(`./resources/images/${locale}/on_three.png`)} alt={ t("Get started with 醫健通 E Health") } />
                <img aria-hidden="true" className="right-bg" src={ load(`./resources/images/${locale}/banner_record_bg.png`) } alt="" />                              
            </div>
        );
    }
}

export default PhoneAnimationSlider;
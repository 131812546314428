export const getCurrentLang = () => {
    if(localStorage.getItem('lang')==='zh-CN'){
        return 'sc';
    }else if(localStorage.getItem('lang')==='zh-HK'){
        return 'tc';
    }else{
        return 'en';
    }
}

export const getVaccineCalculationStatus = (vaccineDisplayStatement) => {
    if (vaccineDisplayStatement.startsWith('0|')) {
        return 'ALL_PASS';
    } else if (vaccineDisplayStatement.startsWith('1|')) {
        return 'PARTIAL_PASS';
    } else if (vaccineDisplayStatement.startsWith('2|')) {
        return 'FAIL';
    } else {
        return 'CHILD';
    }
}

export const isIEBrowser = () => {
    const ua = window.navigator.userAgent;
    const msie = ua.indexOf('MSIE '); // IE 10 or older
    const trident = ua.indexOf('Trident/'); //IE 11
    
    return (msie > 0 || trident > 0);
}
import React, { Component } from 'react';
import { zeroPad } from '../resources/utils/stringUtils';
import { getEnglishMonthNames }from '../resources/utils/dateUtils';
import { isNullOrEmpty } from '../resources/utils/validator';
import moment from 'moment';

class CommonDatePicker extends Component {
    constructor(props) {
        super(props);
        this.state = {
            year: props.initialDate?.year ?? '',
            month: props.initialDate?.month ?? '',
            day: props.initialDate?.day ?? '',
            isYearOpen: false,
            isMonthOpen: false,
            isDayOpen: false,
            lastDay: 31,
        };
        this.closeAll = this.closeAll.bind(this);
        this.renderYear = this.renderYear.bind(this);
        this.renderMonth = this.renderMonth.bind(this);
        this.renderDay = this.renderDay.bind(this);
        this.onPickerChange = this.onPickerChange.bind(this);
    }
    closeAll() {
        this.setState({
            isYearOpen: false,
            isMonthOpen: false,
            isDayOpen: false,
        });
    }

    onPickerChange({ year = null, month = null, day = null }) {
        const { maxDate, minDate } = this.props;
        let lastDay = this.state.lastDay;
        let updatedYear = year ? year : this.state.year;
        let updatedMonth = month ? month : this.state.month;
        let updatedDay = day ? day : this.state.day;
        
        if (!isNullOrEmpty(updatedYear) && !isNullOrEmpty(updatedMonth)) {
            const monthStartDate = moment([updatedYear, updatedMonth - 1]);
            const monthEndDate = moment(monthStartDate).endOf('month');
            lastDay = monthEndDate.date();

            // if selected day not exist in selected month, reset day
            updatedDay =
                parseInt(updatedDay) > lastDay
                    ? lastDay
                    : updatedDay;
        }

        if (maxDate) {
            if (parseInt(updatedYear) === maxDate?.year) {
                // if future date, reset month
                updatedMonth =
                    parseInt(updatedMonth) >= maxDate.month
                        ? maxDate.month
                        : updatedMonth;
                if (parseInt(updatedMonth) === maxDate.month) {
                    // if future date, reset day
                    updatedDay =
                        parseInt(updatedDay) > maxDate.day
                            ? maxDate.day
                            : updatedDay;
                }
            }
        }
        if (minDate) {
            if (updatedYear === minDate?.year) {
                // if past date, reset month
                updatedMonth =
                    parseInt(updatedMonth) <= minDate.month
                        ? minDate.month
                        : updatedMonth;
                // if past date, reset day
                if (updatedMonth === minDate.month) {
                    updatedDay =
                        parseInt(updatedDay) < minDate.day
                            ? minDate.day
                            : updatedDay;
                }
            }
        }

        if (!isNullOrEmpty(updatedYear) && !isNullOrEmpty(updatedMonth)) {
            const monthStartDate = moment([updatedYear, updatedMonth - 1]);
            const monthEndDate = moment(monthStartDate).endOf('month');
            lastDay = monthEndDate.date();

            // if selected day not exist in selected month, reset day
            updatedDay =
                parseInt(updatedDay) > lastDay
                    ? lastDay
                    : updatedDay;
        }

        this.setState(
            {
                year: isNullOrEmpty(updatedYear) ? '' : parseInt(updatedYear),
                month: isNullOrEmpty(updatedMonth) ? '' : parseInt(updatedMonth),
                day: isNullOrEmpty(updatedDay) ? '' : parseInt(updatedDay),
            },
            () => {
                this.props.onDateChange({
                    year: isNullOrEmpty(updatedYear) ? '' : parseInt(updatedYear),
                    month: isNullOrEmpty(updatedMonth) ? '' : parseInt(updatedMonth),
                    day: isNullOrEmpty(updatedDay) ? '' : parseInt(updatedDay),
                });
            }
        );
    }

    renderYear() {
        const { maxDate, minDate } = this.props;
        const defaultYear = 2000;
        let maxYear = new Date().getFullYear();
        let minYear = 1900;
        let yearsBetweenDefaultAndNow = 0;

        if (this.props?.maxYear) {
            maxYear = this.props.maxYear;
        }
        if (this.props?.minYear) {
            minYear = this.props.minYear;
        }
        if (maxDate?.year) {
            maxYear = maxDate?.year;
        }
        if (minDate?.year) {
            minYear = minDate?.year;
        }
        const { locale, t , type } = this.props;
        let allowedYears = [];
        for (let i = maxYear; i >= minYear; i--) {
            allowedYears.push(i);
            if (defaultYear < i) {
                yearsBetweenDefaultAndNow++;
            }
        }
        return (
            <div className='input-date-picker-container'>
                <div>
                    <input
                        autoComplete='off'
                        value={
                            this.state.year
                                ? locale === 'en'
                                    ? this.state.year
                                    : `${this.state.year}${t(
                                          'selection year unit'
                                      )}`
                                : ''
                        }
                        readonly="true"
                        className='font-20 input-date-picker'
                        id={type + '-inputYear'}
                        maxLength='4'
                        minLength='4'
                        pattern='\d{4}'
                        tabIndex='0'
                        placeholder={t('year picker placeholder')}
                        onChange={(e) => {
                            e.preventDefault();
                        }}
                        onFocus={(e) => {
                            this.closeAll();
                            this.setState({ isYearOpen: true });
                        }}
                        onClick={(e) => {
                            this.closeAll();
                            this.setState({ isYearOpen: true });
                            const yearDropDownList= document.getElementById('year-dropdown-list');
                            if (yearDropDownList) {
                                yearDropDownList.scrollTo({ top: 45 * yearsBetweenDefaultAndNow });
                            }
                        }}
                        onBlur={(e) => {
                            setTimeout(() => {
                                this.setState({ isYearOpen: false });
                            }, 500);
                        }}
                        title={this.props.alt && this.props.alt.year ? this.props.alt.year : ''}
                    ></input>
                </div>
                {this.state.isYearOpen && (
                    <div className='input-date-picker-dropdown'>
                        <div className='input-date-picker-dropdown-area'>
                            <div className='input-date-picker-list'>
                                <ul id="year-dropdown-list">
                                    {allowedYears.map((year) => (
                                        <div key={`year-${year}`}>
                                            <li
                                                id={`year-${year}`}
                                                index={`${year}`}
                                                onClick={() => {
                                                    this.setState(
                                                        {
                                                            isYearOpen: false,
                                                        },
                                                        () => {
                                                            if (this.props.onClick) {
                                                                this.props.onClick();
                                                            }
                                                            this.onPickerChange(
                                                                { year: year }
                                                            );
                                                        }
                                                    );
                                                }}
                                                alt={year}
                                            >
                                                {year}
                                            </li>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    renderMonth() {
        const { locale, t, maxDate, minDate, type } = this.props;
        let maxMonth = 12;
        let minMonth = 1;
        if (maxDate?.month) {
            if (this.state.year === maxDate.year) {
                maxMonth = maxDate.month;
            }
        }
        if (minDate?.month) {
            if (this.state.year === minDate.year) {
                minMonth = minDate.month;
            }
        }
        const allowedMonths = [];
        for (let i = minMonth; i <= maxMonth; i++) {
            const month = i < 10 ? `0${i}` : i;
            allowedMonths.push(month);
        }
        return (
            <div className='input-date-picker-container'>
                <div>
                    <input
                        readonly="true"
                        autoComplete='off'
                        value={
                            this.state.month
                                ? locale === 'en'
                                    ? getEnglishMonthNames(
                                        this.state.month
                                    )
                                    : `${zeroPad(this.state.month)}${t(
                                        'selection month unit'
                                    )}`
                                : ''
                        }
                        className='font-20 input-date-picker'
                        id={type + '-inputMonth'}
                        tabIndex='0'
                        placeholder={t('month picker placeholder')}
                        onChange={(e) => {
                            e.preventDefault();
                        }}
                        onFocus={(e) => {
                            this.closeAll();
                            this.setState({ isMonthOpen: true });
                        }}
                        onBlur={(e) => {
                            setTimeout(() => {
                                this.setState({ isMonthOpen: false });
                            }, 500);
                        }}
                        title={this.props.alt && this.props.alt.month ? this.props.alt.month : ''}
                    ></input>
                </div>
                {this.state.isMonthOpen && (
                    <div className='input-date-picker-dropdown'>
                        <div className='input-date-picker-dropdown-area'>
                            <div className='input-date-picker-list'>
                                <ul>
                                    {allowedMonths.map((month) => (
                                        <div key={`month-${month}`}>
                                            <li
                                                id={`month-${month}`}
                                                index={`${month}`}
                                                onClick={() => {
                                                    this.setState(
                                                        {
                                                            isMonthOpen: false,
                                                        },
                                                        () => {
                                                            this.onPickerChange(
                                                                { month: month }
                                                            );
                                                        }
                                                    );
                                                }}
                                                alt={month}
                                            >
                                                {locale === 'en'
                                                    ? getEnglishMonthNames(
                                                          parseInt(month)
                                                      )
                                                    : zeroPad(month)}
                                            </li>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    renderDay() {
        const { locale, t, maxDate, minDate, type } = this.props;
        let minDay = 1;
        let lastDay = this.state.lastDay;

        if (!isNullOrEmpty(this.state.year) && !isNullOrEmpty(this.state.month)) {
            const monthStartDate = moment([this.state.year, this.state.month - 1]);
            const monthEndDate = moment(monthStartDate).endOf('month');
            lastDay = monthEndDate.date();
        }

        if (maxDate?.day) {
            if (
                this.state.year === maxDate.year &&
                this.state.month === maxDate.month
            ) {
                lastDay = maxDate.day;
            }
        }
        if (minDate?.day) {
            if (
                this.state.year === minDate.year &&
                this.state.month === minDate.month
            ) {
                minDay = minDate.day;
            }
        }
        const allowedDays = [];
        for (let i = minDay; i <= lastDay; i++) {
            const day = i < 10 ? `0${i}` : i;
            allowedDays.push(day);
        }
        return (
            <div className='input-date-picker-container'>
                <div>
                    <input
                        readonly="true"
                        autoComplete='off'
                        value={
                            this.state.day
                                ? locale === 'en'
                                    ? zeroPad(this.state.day)
                                    : `${zeroPad(this.state.day)}${t(
                                          'selection day unit'
                                      )}`
                                : ''
                        }
                        className='font-20 input-date-picker'
                        id={type + '-inputDay'}
                        tabIndex='0'
                        placeholder={t('day picker placeholder')}
                        onChange={(e) => {
                            e.preventDefault();
                        }}
                        onFocus={(e) => {
                            this.closeAll();
                            this.setState({ isDayOpen: true });
                        }}
                        onBlur={(e) => {
                            setTimeout(() => {
                                this.setState({ isDayOpen: false });
                            }, 500);
                        }}
                        title={this.props.alt && this.props.alt.day ? this.props.alt.day : ''}
                    ></input>
                </div>
                {this.state.isDayOpen && (
                    <div className='input-date-picker-dropdown'>
                        <div className='input-date-picker-dropdown-area'>
                            <div className='input-date-picker-list'>
                                <ul>
                                    {allowedDays.map((day) => (
                                        <div key={`day-${day}`}>
                                            <li
                                                id={`day-${day}`}
                                                index={`${day}`}
                                                onClick={() => {
                                                    this.onPickerChange({
                                                        day: parseInt(day),
                                                    });
                                                }}
                                                alt={day}
                                            >
                                                {day}
                                            </li>
                                        </div>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        );
    }

    render() {
        const { locale, t } = this.props;
        return (
            <div className='common-date-picker-container'>
                {locale === 'en' ? (
                    <>
                        {this.renderDay()}
                        {this.renderMonth()}
                        {this.renderYear()}
                    </>
                ) : (
                    <>
                        {this.renderYear()}
                        {this.renderMonth()}
                        {this.renderDay()}
                    </>
                )}
            </div>
        );
    }
}

export default CommonDatePicker;

import moment from 'moment';
import { zeroPad } from '../utils/stringUtils';

export const getEnglishMonthNames = (monthNumber) => {
    const names = {
        1: 'Jan',
        2: 'Feb',
        3: 'Mar',
        4: 'Apr',
        5: 'May',
        6: 'Jun',
        7: 'Jul',
        8: 'Aug',
        9: 'Sep',
        10: 'Oct',
        11: 'Nov',
        12: 'Dec',
    };
    return names[monthNumber];
}

export const getDateLabel = (dateString,locale, t) => {
    const dateObj = moment(dateString, 'YYYY-MM-DD');
    if (locale === 'en') {
        //DD-MMM-YY
        return `${zeroPad(dateObj.date())}${t('selection day unit')}${getEnglishMonthNames(dateObj.month()+1)}${t('selection month unit')}${dateObj.year()}`;
    } else {
        //YYYY年MM月DD日
        return `${dateObj.year()}${t('selection year unit')}${zeroPad(dateObj.month()+1)}${t('selection month unit')}${zeroPad(dateObj.date())}${t('selection day unit')}`;
    }
}

export const getTimestamp = (dateObj) => {
    return moment(`${dateObj.year}-${zeroPad(dateObj.month)}-${zeroPad(dateObj.day)} 00:00:00`);
}

export const getNextYearObj = (serverDate) => {
    const nextYear = moment(serverDate).add(1, 'y').subtract(1, 'd');
    return {
        year: nextYear.year(),
        month: nextYear.month(),
        day: nextYear.date(),
    }
}

export const getDateString = (dateObj) => moment(`${dateObj.year}-${zeroPad(dateObj.month)}-${zeroPad(dateObj.day)}`).format('YYYY-MM-DD');

export const getDateObj = (serverDtm) => {
    return  Object.assign({},
        {
            year: new Date(serverDtm).getFullYear(),
            month: new Date(serverDtm).getMonth() + 1,
            day: new Date(serverDtm).getDate(),
        }
    );
}

export const compareDate = (dateObjA, dateObjB) => {
    const dateA = moment(`${dateObjA.year}-${zeroPad(dateObjA.month)}-${zeroPad(dateObjA.day)}`);
    const dateB = moment(`${dateObjB.year}-${zeroPad(dateObjB.month)}-${zeroPad(dateObjB.day)}`);

    if (dateA.isAfter(dateB)) {
        return 1;
    } else {
        return 0;
    }
}
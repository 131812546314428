import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class ClinicalVaccinePage extends Component {
  render() {
    const { locale, t } = this.props;
    const sliderImages = [
      "vaccine_slider_phone1.png",
      "vaccine_slider_phone2.png",
      "vaccine_slider_phone3.png",
    ];
    const sliderImageAlts = [
      "Screenshot of Vaccines",
      "Screenshot of Vaccines",
      "Screenshot of Vaccines",
    ];
    const sliderImageIds = [
      "img1VaccineSlider",
      "img2VaccineSlider",
      "img3VaccineSlider",
    ];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"clinical-vaccine-bg.png"}
        sliderID="vaccineSlider"
        sliderTitle={t("Vaccine Header")}
        sliderTitleID={"textVaccineSliderTitle"}
        sliderText={t("Your electronic vaccine records, anywhere, anytime")}
        sliderTextID="textVaccineSliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );

    return (
      <div
        className={`page-container page-container-${locale}`}
        id="MainContent"
      >
        {phoneSlider}

        <div className="container-fluid second-feature-section last-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textVaccineSection1Title"
                  className="second-feature-title"
                >
                  {t("Detailed vaccine record")}
                </div>
                <div
                  id="textVaccineSection1Content"
                  className="second-feature-text"
                >
                  {t("Easy reference for all vaccines records")}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1VaccineSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(`./resources/images/vaccine_p1_1.png`)}
                  alt=" "
                />
                <img
                  id="img2VaccineSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(`./resources/images/${locale}/vaccine_p1_2.png`)}
                  alt=" "
                />
                <img
                  id="img3VaccineSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(`./resources/images/vaccine_p1_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-down"
          data-aos-offset="-300"
          className="second-video video-middle"
          alt={t("Video")}
        >
          <div ref={this.youtubeContainer} className="second-youtube-container">
            <iframe
              id="video1Vaccine"
              key={locale}
              className="second-responsive-iframe"
              title={t("Video des")}
              src="https://www.youtube.com/embed/cC5ChtNV9lE"
              alt={t("Video")}
              tabIndex="0"
            ></iframe>
          </div>
        </div>

        <div className="container-fluid second-feature-section last-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textVaccineSection2Title"
                  className="second-feature-title"
                >
                  {t("Easy supplementing")}
                </div>
                <div
                  id="textVaccineSection2Content"
                  className="second-feature-text"
                >
                  {t(
                    "Supplement record by self entry or uploading vaccine card"
                  )}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1VaccineSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(`./resources/images/${locale}/vaccine_p2_1.png`)}
                  alt=" "
                />
                <img
                  id="img2VaccineSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front"
                  src={load(`./resources/images/vaccine_p2_2.png`)}
                  alt=" "
                />
                <img
                  id="img3VaccineSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(`./resources/images/vaccine_p2_3.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div
          data-aos="fade-down"
          data-aos-offset="-300"
          className="second-video video-middle"
          alt={t("Video")}
        >
          <div ref={this.youtubeContainer} className="second-youtube-container">
            <iframe
              id="video2Vaccine"
              key={locale}
              className="second-responsive-iframe"
              title={t("Video des")}
              src="https://www.youtube.com/embed/TDiDUpW38Ao"
              alt={t("Video")}
              tabIndex="0"
            ></iframe>
          </div>
        </div>
        
        {/* 20241105 Update, remove COVID19 section of vaccine page*/}
        {/*<div className="container-fluid second-feature-section last-section-novideo">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textVaccineSection3Title"
                  className="second-feature-title"
                >
                  {t("Quick access to Covid-19")}
                </div>
                <div
                  id="textVaccineSection3Content"
                  className="second-feature-text"
                >
                  {t("View Covid-19 vaccine record with one tap")}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1VaccineSection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(`./resources/images/${locale}/vaccine_p3_2.png`)}
                  alt=" "
                />
                <img
                  id="img2VaccineSection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image "
                  src={load(`./resources/images/vaccine_p3_3.png`)}
                  alt=" "
                />
                <img
                  id="img3VaccineSection3"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image front "
                  src={load(`./resources/images/vaccine_p3_1.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>*/}

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(ClinicalVaccinePage);

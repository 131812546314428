import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class HealthcareDocumentPage extends Component {
  render() {
    const { locale, t } = this.props;
    const sliderImages = [
      "healthcare_doc_slider_phone1.png",
      "healthcare_doc_slider_phone2.png",
      "healthcare_doc_slider_phone3.png",
    ];
    const sliderImageAlts = ["Screenshot of Healthcare Document"];
    const sliderImageIds = ["img1HealthcareDocumentSlider"];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"healthcare_doc-bg.png"}
        sliderID="HealthcareDocumentSlider"
        sliderTitle={t("Healthcare Document")}
        sliderTitleID={"textHealthcareDocumentSliderTitle"}
        sliderText={t("Keep the records with you for better health management")}
        sliderTextID="textHealthcareDocumentSliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );
    return (
      <div
        className={`page-container page-container-${locale} `}
        id="MainContent"
      >
        {phoneSlider}
        <div className="container-fluid second-feature-section light-bg-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="textMyFamilyDoctorSection1Title"
                  className="second-feature-title"
                >
                  {t("Referral letter")}
                </div>
                <div
                  id="textMyFamilyDoctorSection1Content"
                  className="second-feature-text"
                >
                  {t("View anytime, save with ease")}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1MyFamilyDoctorSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-400"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/${locale}/healthcare_doc_p1_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img2MyFamilyDoctorSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-400"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(`./resources/images/healthcare_doc_p1_2.png`)}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(HealthcareDocumentPage);

import React, { Component } from 'react';

class AccessibilityWrapper extends Component {
    render() {
        return (
            <div 
                className={`accessibility-wrapper ${this.props.onClick ? 'clickable' : ''} ${this.props.className ? this.props.className : ''}`}
                id={this.props.id ? this.props.id : ''}
                onClick={this.props.onClick ? this.props.onClick : () => {}}
                tabIndex="0"
            >
               <img 
                    className="alt-image"
                    alt={this.props.alt}
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAoAAAAKCAYAAACNMs+9AAAABmJLR0QA/wD/AP+gvaeTAAAACXBIWXMAAC4jAAAuIwF4pT92AAAAB3RJTUUH5AcPChMg/FTb/gAAABl0RVh0Q29tbWVudABDcmVhdGVkIHdpdGggR0lNUFeBDhcAAAAOSURBVBjTY2AYBYMTAAABmgABC6KdHwAAAABJRU5ErkJggg==" />
               {this.props.children}
            </div>
        );
    }
}

export default AccessibilityWrapper;

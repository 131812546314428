import React, { Component } from 'react';
import load from '../../ImageLoder';
import PageHOC from '../../PageHOC';
import Footer from '../Footer';
import CommonDatePicker from '../CommonDatePicker';
import VaccinePicker from './VaccinePicker';
import moment from 'moment';
import {getVaccineList, sendStat, getCalculatorResult, getCalculatorSettings} from '../../apis';
import { isDateValid, isNullOrEmpty } from '../../resources/utils/validator';
import { getDateLabel, compareDate, getNextYearObj, getDateString, getDateObj, getTimestamp }from '../../resources/utils/dateUtils';
import { getCurrentLang } from '../../resources/utils/commonUtils';
import AccessibilityWrapper from '../AccessibilityWrapper';
import { getVaccineCalculationStatus } from '../../resources/utils/commonUtils';
import { isIEBrowser } from '../../resources/utils/commonUtils';

class VaccineCalculator extends Component {
    constructor(props) {
        super(props);
        
        this.recoveryTooltipButtonRef = React.createRef();
        this.recoveryTooltipContentRef = React.createRef();
        this.doseNumberDropDownRef = React.createRef();

        this.dateOfBirthErrMsgRef = React.createRef();
        this.isExemptionErrMsgRef = React.createRef();
        this.exemptionExpiryDateErrMsgRef = React.createRef();
        this.isDiagnoseErrMsgRef = React.createRef();
        this.recoveryDateErrMsgRef = React.createRef();
        this.numberOfDoseErrMsgRef = React.createRef();
        this.lastVaccineDateErrMsgRef = React.createRef();
        this.vaccinesErrMsgRef = React.createRef();

        this.state = {
            locale: this.props.locale,
            setVaccineCalcResult: null,
            vaccineCalcResult: null,
            showRecovery: false,
            isExemption: '',
            exemptionExpiryDate: {
                year: '',
                month: '',
                day: '',
            },
            isDiagnose: '',
            recoveryDate: {
                year: '',
                month: '',
                day: '',
            },
            numberOfDose: '',
            vaccinations: {
                0: '',
                1: '',
                2: '',
                3: '',
            },
            isShowDatePopup: false,
            lastVaccineDate: {
                year: '',
                month: '',
                day: '',
            },
            dateOfBirth: {
                year: '',
                month: '',
                day: '',
            },
            doseDropdownTexts: [
                'Not Yet Vaccinated',
                '1 dose',
                '2 dose',
                '3 dose',
                '4 dose',
                '5 dose',
                '6 dose',
                '7 dose',
                '8 dose',
                '9 dose',
            ],
            calculationDate: {
                year: '',
                month: '',
                day: '',
            },
            popupCalculationDate: {
                year: '',
                month: '',
                day: '',
            },
            serverDate: {
                year: '',
                month: '',
                day: '',
            },
            isFormValid: false,
            showDoseDropdown: false,
            vaccinationOptions: [],
            hasError: false,    //api error
            doseLimit: props.vaccineCalculatorSettings ? parseInt(props.vaccineCalculatorSettings.DoseNum) : 4,
            errors: {
                dateOfBirth: false,
                isExemption: false,
                exemptionExpiryDate: false,
                isDiagnose: false,
                recoveryDate: false,
                numberOfDose: false,
                vaccinations: {
                    0: false,
                    1: false,
                    2: false,
                    3: false,
                },
                lastVaccineDate: false,
                calculationDate: false,
                lastVaccineDateIsAfterCaclculationDate: false
            },
            showError: false,   //frontend form error
            submitBtnIsClickedOnce: false,
            isShowDisclaimerPopup: false,
            serverDtm: null,
            activationDate: null,
            isBeforeActive: false,
        };

        this.toggleRecovery = this.toggleRecovery.bind(this);
        this.vaccineTypeInput = this.vaccineTypeInput.bind(this);
        this.onStateDateChange = this.onStateDateChange.bind(this);
        this.onDateOfBirthChange = this.onDateOfBirthChange.bind(this);
        this.onConfirmCalculation = this.onConfirmCalculation.bind(this);
        this.formDataValidation = this.formDataValidation.bind(this);
        this.triggerVaccinationsUpdate =
            this.triggerVaccinationsUpdate.bind(this);
        this.renderVaccineTypeElements =
            this.renderVaccineTypeElements.bind(this);
        this.handleClickOutsideDoseNumberDropdown =
        this.handleClickOutsideDoseNumberDropdown.bind(this);
        this.handleClickOutsideRecovery =
        this.handleClickOutsideRecovery.bind(this);
    }

    componentDidMount() {
        sessionStorage.clear();
        this.callGetVaccineListAPI();
        
        document.addEventListener('mousedown', this.handleClickOutsideRecovery);
        document.addEventListener(
            'mousedown',
            this.handleClickOutsideDoseNumberDropdown
        );

        // Detect whether the page is redireted from result page or not
        if (this.props.history.location.state && this.props.history.location.state.isCalculateAgain) {
            this.props.history.replace();
        } else {
            // Access promotion web vaccine pass calculator page
            this.callSendStatAPI('AccessPWebVaccineCalculator', null);
            this.setState({isShowDisclaimerPopup: true});
        }

        //get disclaimer status when load
        this.callGetCalSettingsAPI();
    }

    componentDidUpdate(prevProps) {
        if (
            prevProps.locale !== this.props.locale && this.state.vpcDisclaimerPopUpEnableFlag === 'Y'
        ) {
            this.setState({isShowDisclaimerPopup: true});
        }
      }

    componentWillUpdate(nextProps, nextState) {
        const isIe = isIEBrowser();
        if (nextState.hasError || (nextProps.vaccineCalculatorSettings && nextProps.vaccineCalculatorSettings.enablePageFlag !== 'Y') || isIe) {
            this.props.history.push('maintenance');
        }

        // Set server dtm when loaded
        if (this.state.calculationDate.year === '' && this.state.popupCalculationDate.year === '' && nextProps.serverDtm) {
            this.setState({
                calculationDate: getDateObj(nextProps.serverDtm),
                popupCalculationDate: getDateObj(nextProps.serverDtm),
                serverDate: getDateObj(nextProps.serverDtm)
            });
        }
    }

    handleActivePeriod(){
        // To handle revised child case before actual activation
        if(!isNullOrEmpty(this.state.activationDate)){
            const currServerDtm = moment(this.state.serverDtm).format('YYYY-MM-DD');
            if(moment(currServerDtm).isBefore(this.state.activationDate)){
                this.setState({isBeforeActive: true}); 
            }
        }
    }

    handleClickOutsideRecovery(event) {
        if (
            this.recoveryTooltipButtonRef?.current &&
            this.recoveryTooltipContentRef?.current
        ) {
            if (!(this.recoveryTooltipButtonRef.current.contains(event.target) || this.recoveryTooltipContentRef.current.contains(event.target))) {
                this.setState({ showRecovery: false });
            }
        }
    }

    handleClickOutsideDoseNumberDropdown(event) {
        if (!(this.doseNumberDropDownRef?.current && this.doseNumberDropDownRef.current.contains(event.target))) {
            this.setState({ showDoseDropdown: false });
        }
    }

    triggerVaccinationsUpdate(numberOfDose, callback) {
        const vaccinations = {
            ...this.state.vaccinations,
        };

        for (let i = 0; i < this.state.doseLimit; i++) {
            if (i >= parseInt(numberOfDose)) {
                vaccinations[i] = '';
            } else {
                // preselect first vaccine type 
                if (!vaccinations[i] && vaccinations[0]) {
                    vaccinations[i] = vaccinations[0];
                }
            }
        }

        this.setState({
            vaccinations: vaccinations,
            numberOfDose: numberOfDose,
            showDoseDropdown: false,
            lastVaccineDate: numberOfDose === 0 || numberOfDose === this.state.doseLimit ? { year: '', month: '', day: '' } : this.state.lastVaccineDate,
            errors: {
                ...this.state.errors,
                numberOfDose: false,
            },
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    formDataValidation(triggerBy, callback) {
        let isFormValid = true;
        const errMsgRef = [];
        const {
            dateOfBirth,
            isExemption,
            exemptionExpiryDate,
            isDiagnose,
            recoveryDate,
            numberOfDose,
            vaccinations,
            lastVaccineDate,
            calculationDate,
            errors,
            submitBtnIsClickedOnce
        } = this.state;

        if (!submitBtnIsClickedOnce) {
            return;
        }

        // Date input validation only triggered by submit button
        if (triggerBy === 'submit'){
            if (isNullOrEmpty(dateOfBirth.year) || (!isNullOrEmpty(dateOfBirth.year) && isNullOrEmpty(dateOfBirth.month) && !isNullOrEmpty(dateOfBirth.day))) {
                isFormValid = false;
                errors.dateOfBirth = true;
                errMsgRef.push(this.dateOfBirthErrMsgRef);
            } else {
                errors.dateOfBirth = false;
            }

            if (isExemption && !isDateValid(exemptionExpiryDate)) {
                isFormValid = false;
                errors.exemptionExpiryDate = true;
                errMsgRef.push(this.exemptionExpiryDateErrMsgRef);
            } else {
                errors.exemptionExpiryDate = false;
            }

            if (isDiagnose && !isDateValid(recoveryDate)) {
                isFormValid = false;
                errors.recoveryDate = true;
                errMsgRef.push(this.recoveryDateErrMsgRef);
            } else {
                errors.recoveryDate = false;
            }

            if (numberOfDose > 0 && numberOfDose < this.state.doseLimit) {
                if (!isDateValid(lastVaccineDate)) {
                    isFormValid = false;
                    errors.lastVaccineDate = true;
                    errMsgRef.push(this.lastVaccineDateErrMsgRef);
                } else {
                    errors.lastVaccineDate = false;
                }
                
                if (isDateValid(lastVaccineDate) && compareDate(lastVaccineDate, calculationDate) === 1) {
                    isFormValid = false;
                    errors.lastVaccineDateIsAfterCaclculationDate = true;
                    errMsgRef.push(this.lastVaccineDateErrMsgRef);
                } else {
                    errors.lastVaccineDateIsAfterCaclculationDate = false;
                }
            }
        }

        // Date input error message will be removed on date changed
        if (triggerBy === 'dateOfBirth') {
            if (!isNullOrEmpty(dateOfBirth.year) && !(!isNullOrEmpty(dateOfBirth.year) && isNullOrEmpty(dateOfBirth.month) && !isNullOrEmpty(dateOfBirth.day))) {
                errors.dateOfBirth = false;
            }
        } 

        if (triggerBy === 'exemptionExpiryDate') {
            if (!(isExemption && !isDateValid(exemptionExpiryDate))) {
                errors.exemptionExpiryDate = false;
            }
        }

        if (triggerBy === 'recoveryDate') {
            if (!(isDiagnose && !isDateValid(recoveryDate))) {
                errors.recoveryDate = false;
            }
        }

        if (triggerBy === 'lastVaccineDate') {
            if (isDateValid(lastVaccineDate)) {
                errors.lastVaccineDate = false;
            }
            if (!(isDateValid(lastVaccineDate) && compareDate(lastVaccineDate, calculationDate) === 1)) {
                errors.lastVaccineDateIsAfterCaclculationDate = false;
            }
        }

        // other valdiations will be triggered by both submit button and on value change 
        if ((triggerBy === 'submit' || triggerBy === 'isExemption')) {
            if (isNullOrEmpty(isExemption)) {
                isFormValid = false;
                errors.isExemption = true;
                errMsgRef.push(this.isExemptionErrMsgRef);
            } else {
                errors.isExemption = false;
            }
        }
        
        if (triggerBy === 'submit' || triggerBy === 'isDiagnose') {
            if (isNullOrEmpty(isDiagnose)) {
                isFormValid = false;
                errors.isDiagnose = true;
                errMsgRef.push(this.isDiagnoseErrMsgRef);
            } else {
                errors.isDiagnose = false;
            }
        }
        
        if (triggerBy === 'submit' || triggerBy === 'numberOfDose') {
            if (isNullOrEmpty(numberOfDose)) {
                isFormValid = false;
                errors.numberOfDose = true;
                errMsgRef.push(this.numberOfDoseErrMsgRef);
            } else {
                errors.numberOfDose = false;
            }
        }
        
        if (numberOfDose > 0 && numberOfDose < this.state.doseLimit) {
            if (triggerBy === 'submit' || triggerBy === 'vaccinations') {
                let hasVaccinationsErr = false;
                for (let i = 0; i < numberOfDose; i++) {
                    if (!vaccinations[i]) {
                        isFormValid = false;
                        errors.vaccinations[i] = true;

                        if (!hasVaccinationsErr) {
                            errMsgRef.push(this.vaccinesErrMsgRef);
                            hasVaccinationsErr = true;
                        }
                    } else {
                        errors.vaccinations[i] = false;
                    }
                }
            }
        }

        if (triggerBy === 'calculationDate') {
            if (!isDateValid(calculationDate)) {
                isFormValid = false;
                errors.calculationDate = true;
            } else {
                errors.calculationDate = false;
            }
        }

        if (errMsgRef.length > 0) {
            errMsgRef[0].focus();
        }

        this.setState({ isFormValid: isFormValid, errors: errors, showError: true }, 
            () => {
                if (callback) {
                    callback();
                }
            }
        );
    }

    onStateDateChange({ year = '', month = '', day = '' }, stateKey, callback) {
        const newDate = {
            year: year ? year : this.state[stateKey].year,
            month: month ? month : this.state[stateKey].month,
            day: day ? day : this.state[stateKey].day,
        };
        this.setState({
            [stateKey]: newDate,
            errors: {
                ...this.state.errors,
                [stateKey]: !isDateValid(newDate),
            },
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    onDateOfBirthChange({ year = '', month = '', day = '' }, callback) {
        this.onStateDateChange({ year, month, day }, 'dateOfBirth');
        const newDate = {
            year: year ? year : this.state.dateOfBirth.year,
            month: month ? month : this.state.dateOfBirth.month,
            day: day ? day : this.state.dateOfBirth.day,
        };
        this.setState({
            dateOfBirth: newDate,
        }, () => {
            if (callback) {
                callback();
            }
        });
    }

    toggleRecovery() {
        const { showRecovery } = this.state;
        this.setState({ showRecovery: !showRecovery });
    }

    vaccineTypeInput(doseIndex) {
        const { t, locale } = this.props;
        
        return (
            <VaccinePicker
                locale={locale}
                t={t}
                doseIndex={doseIndex}
                firstVaccine={this.state.vaccinations[0]}
                key={`dose-picker-${doseIndex}`}
                showError={this.state.showError}
                isError={this.state.errors.vaccinations[doseIndex]}
                onVaccineChange={(value, index) => {
                    const vaccinationErrors = this.state.errors.vaccinations;
                    const vaccinations = { ...this.state.vaccinations };
                    vaccinations[index] = value;
                    vaccinationErrors[index] = false;
                    if (index === 0) {
                        for (let i = 0; i < this.state.numberOfDose; i++) {
                            if (!vaccinations[i]) {
                                vaccinations[i] = value;
                                vaccinationErrors[i] = false;
                            }
                        }
                    }
                    this.setState({
                        vaccinations: vaccinations,
                        errors: {
                            ...this.state.errors,
                            vaccinations: vaccinationErrors,
                        },
                    }, () => this.formDataValidation('vaccinations'));
                }}
                vaccine={
                    this.state.vaccinations[doseIndex]
                        ? this.state.vaccinations[doseIndex]
                        : this.state.vaccinations[0]
                }
                recognisedVaccineUrl={this.props.vaccineCalculatorSettings.recognisedVaccineUrl}
                vaccinationOptions={this.state.vaccinationOptions}
            />
        );
    }

    renderVaccineTypeElements() {
        const outputElements = [];
        const { numberOfDose } = this.state;
        if (!numberOfDose || numberOfDose >= this.state.doseLimit) {
            return;
        }
        for (let i = 0; i < numberOfDose; i++) {
            outputElements.push(this.vaccineTypeInput(i));
        }
        return outputElements;
    }

    onConfirmCalculation() {
        this.formDataValidation('submit',
        () => {
            if (this.state.isFormValid) {
                // save form input in session for recalculate from result page
                sessionStorage.setItem(
                    'calculatorData',
                    JSON.stringify(this.state)
                );
    
                this.callGetCalResultAPI();
            }
        });
    }

    getNumOfDoseOptionLabel(numberOfDose) {
        const {t} = this.props;
        if (numberOfDose === this.state.doseLimit) {
            return `${t(this.state.doseDropdownTexts[numberOfDose])}${t('or above')}`;
        } else {
            return t(this.state.doseDropdownTexts[numberOfDose]);
        }
    }

    getCurrentLang(){
        if(localStorage.getItem('lang')==='zh-CN'){
            this.state.lang='sc'
        }else if(localStorage.getItem('lang')==='zh-HK'){
            this.state.lang='tc'
        }else{
            this.state.lang='en'
        }
        console.log('this.state.lang: ' + this.state.lang);
    }

    async callGetCalSettingsAPI(){
        this.getCurrentLang();
        let result;
        try{
            result = await getCalculatorSettings(this.state.lang);
        }catch(err){
            console.log('err: ' + err);
        }finally{
            if(result && result.data){
                
                const responseDtm = result.headers.date;
                const isIe = isIEBrowser();
                let responseLocalDtm = '';
                if (isIe) {
                    // as IE doesn't support HK timezone, server date will be calculate by UTC server date
                    var date = new Date(responseDtm);
                    var now_utc = `${date.getUTCFullYear()}-${date.getUTCMonth() + 1}-${date.getUTCDate()} ${date.getUTCHours() + 8}:${date.getUTCMinutes()}:${date.getUTCSeconds()}`;
                    responseLocalDtm = new Date(now_utc);
                } else {
                    responseLocalDtm = new Date(responseDtm).toLocaleString("en-US", {timeZone: "Asia/Hong_Kong"});
                }
                this.setState({
                    vaccineCalculatorSettings: result.data,
                    vpcDisclaimerEnableFlag: result.data.vpcDisclaimerEnableFlag,
                    vpcDisclaimerUrlEnableFlag: result.data.vpcDisclaimerUrlEnableFlag,
                    vpcDisclaimerPopUpEnableFlag: result.data.vpcDisclaimerPopUpEnableFlag,
                    vpcDisclaimerLearnMore_ENG: result.data.VPC_DISCLAIMER_LEARNMORE_ENG,
                    vpcDisclaimerLearnMoreURL_ENG: result.data.VPC_DISCLAIMER_LEARNMORE_URL_ENG,
                    vpcResultDisclaimerCW_ENG: result.data.VPC_RESULT_DISCLAIMER_CW_ENG,
                    vpcDisclaimerPopup_ENG: result.data.VPC_DISCLAIMER_POPUP_ENG,
                    vpcDisclaimerLearnMore_TC: result.data.VPC_DISCLAIMER_LEARNMORE_TC,
                    vpcDisclaimerLearnMoreURL_TC: result.data.VPC_DISCLAIMER_LEARNMORE_URL_TC,
                    vpcResultDisclaimerCW_TC: result.data.VPC_RESULT_DISCLAIMER_CW_TC,
                    vpcDisclaimerPopup_TC: result.data.VPC_DISCLAIMER_POPUP_TC,
                    vpcDisclaimerLearnMore_SC: result.data.VPC_DISCLAIMER_LEARNMORE_SC,
                    vpcDisclaimerLearnMoreURL_SC: result.data.VPC_DISCLAIMER_LEARNMORE_URL_SC,
                    vpcResultDisclaimerCW_SC: result.data.VPC_RESULT_DISCLAIMER_CW_SC,
                    vpcDisclaimerPopup_SC: result.data.VPC_DISCLAIMER_POPUP_SC,
                    serverDtm: new Date(responseLocalDtm),
                    activationDate: result.data.activationDate,
                }, () => this.handleActivePeriod());
            }else{
                //err
            }
        }
    }

    async callGetVaccineListAPI(){
        let result;
        try{
            result = await getVaccineList(getCurrentLang());
        }catch(err){
            this.setState({ hasError: true });
        }finally{
            if(result && result.data && result.data.vaccineList){
                this.setState({
                    vaccinationOptions: result.data.vaccineList
                })
            }else{
                this.setState({ hasError: true });
            }
        }
    }

    async callGetCalResultAPI(){
        let result;
        let takenVaccineList = '';
        let lastVaccineDate = null;

        if (parseInt(this.state.numberOfDose) === 0){
            takenVaccineList = null;
        } else if (parseInt(this.state.numberOfDose) === this.state.doseLimit) {
            // Call api by dummy vaccine list and last vaccine date to fulfil "no input when numOfDose = doseLimit" requirement
            takenVaccineList= [];
            for (let i=0; i<this.state.doseLimit; i++) {
                takenVaccineList.push({listOfVaccine : this.state.vaccinationOptions[0]['id']});
            }
            lastVaccineDate = getDateString(this.state.calculationDate);
        } else {
            takenVaccineList = [];
            for (let i = 0; i < parseInt(this.state.numberOfDose); i++) {
                takenVaccineList.push({listOfVaccine : this.state.vaccinations[i]});
            }
            lastVaccineDate = getDateString(this.state.lastVaccineDate)
        }

        try{
            await this.callSendStatAPI('CalculatePWebVaccineResult', null);
            const data = {
                phaseId: parseInt(this.props.vaccineCalculatorSettings.phaseId),
                checkingDate: getDateString(this.state.calculationDate),
                exemptionInd: this.state.isExemption ? "Y" : "N",
                exemptionExpireDate: this.state.isExemption ? getDateString(this.state.exemptionExpiryDate) : null,
                birthDate: getDateString(this.state.dateOfBirth),
                recoverInd: this.state.isDiagnose ? "Y":"N",
                recoverDate: this.state.isDiagnose ? getDateString(this.state.recoveryDate) : null,
                doseReceive: this.state.numberOfDose,
                listOfVaccineList: takenVaccineList,
                lastVaccineDate: lastVaccineDate,
                lang: getCurrentLang(),
                sourceSystem: "PWEB"
              }
            result = await getCalculatorResult(this.props.language, data);
        }catch(err){
            console.log('err: ' + err);
            this.setState({ hasError: true });
        }finally{
            if(result && result.data){
                // pass calculator result to result page
                this.props.setVaccineCalcResult(result.data);

                const isChild = result.data.vaccineDisplayStatement && getVaccineCalculationStatus(result.data.vaccineDisplayStatement) === 'CHILD';
                
                if (isChild) {
                    this.props.history.push('vaccine-calculator-result-child', {isBeforeActive: this.state.isBeforeActive});
                } else {
                    this.props.history.push('vaccine-calculator-result');
                }
            }else{
                this.setState({ hasError: true });
            }
        }
    }

    async callSendStatAPI(operationCd, searchVarchar2){
        let result;
        const param = new URLSearchParams(this.props.location.search);
        
        try{
            const data = {
                "operationCd": operationCd,
                "ehrNoHash": null,
                "searchVarchar1": param.get('srcSystem') ? param.get('srcSystem') : "PWEB",
                "searchVarchar2": searchVarchar2,
                "searchVarchar3": null,
                "searchVarchar4": null,
                "searchVarchar5": null,
                "searchVarchar6": null,
                "searchVarchar7": null,
                "searchVarchar8": null,
                "searchVarchar9": null,
                "searchVarchar10": null,
                "searchInt1": null,
                "searchInt2": null,
                "searchInt3": null,
                "searchInt4": null,
                "searchInt5": null,
                "searchInt6": null,
                "searchInt7": null,
                "searchInt8": null,
                "searchInt9": null,
                "searchInt10": null,
                "birthDate": null,
                "exactDobFlag": null,
                "loginFlag": "N"
            }
            result = await sendStat(getCurrentLang(), data);
        }catch(err){
            console.log('err: ' + err);
            this.setState({ hasError: true });
        }finally{
            if(result && result.status !== 200){
                this.setState({ hasError: true });
            }
        }
    }

    getVpcResultDisclaimerCW() {
        if (!isNullOrEmpty(this.state.vpcResultDisclaimerCW_ENG) && !isNullOrEmpty(this.state.vpcResultDisclaimerCW_TC) && !isNullOrEmpty(this.state.vpcResultDisclaimerCW_SC)) {
            if(localStorage.getItem('lang')==='zh-CN'){
                return this.state.vpcResultDisclaimerCW_SC;
            }else if(localStorage.getItem('lang')==='zh-HK'){
                return this.state.vpcResultDisclaimerCW_TC;
            }else{
                return this.state.vpcResultDisclaimerCW_ENG;
            }
        } else {
            return '';
        }
    }

    getLearnMoreText() {
        if (!isNullOrEmpty(this.state.vpcDisclaimerLearnMore_ENG) && !isNullOrEmpty(this.state.vpcDisclaimerLearnMore_TC) && !isNullOrEmpty(this.state.vpcDisclaimerLearnMore_SC)) {
            if(localStorage.getItem('lang')==='zh-CN'){
                return this.state.vpcDisclaimerLearnMore_SC;
            }else if(localStorage.getItem('lang')==='zh-HK'){
                return this.state.vpcDisclaimerLearnMore_TC;
            }else{
                return this.state.vpcDisclaimerLearnMore_ENG;
            }
        } else {
            return '';
        }
    }

    getLearnMoreURL() {
        if (!isNullOrEmpty(this.state.vpcDisclaimerLearnMoreURL_ENG) && !isNullOrEmpty(this.state.vpcDisclaimerLearnMoreURL_TC) && !isNullOrEmpty(this.state.vpcDisclaimerLearnMoreURL_SC)) {
            if(localStorage.getItem('lang')==='zh-CN'){
                return this.state.vpcDisclaimerLearnMoreURL_SC;
            }else if(localStorage.getItem('lang')==='zh-HK'){
                return this.state.vpcDisclaimerLearnMoreURL_TC;
            }else{
                return this.state.vpcDisclaimerLearnMoreURL_ENG;
            }
        } else {
            return '';
        }
    }

    getDisclaimerPopupMsg() {
        if (!isNullOrEmpty(this.state.vpcDisclaimerPopup_ENG) && !isNullOrEmpty(this.state.vpcDisclaimerPopup_TC) && !isNullOrEmpty(this.state.vpcDisclaimerPopup_SC)) {
            if(localStorage.getItem('lang')==='zh-CN'){
                return this.state.vpcDisclaimerPopup_SC;
            }else if(localStorage.getItem('lang')==='zh-HK'){
                return this.state.vpcDisclaimerPopup_TC;
            }else{
                return this.state.vpcDisclaimerPopup_ENG;
            }
        } else {
            return '';
        }
    }

    render() {
        const { locale, t, vaccineCalculatorSettings } = this.props;
        const isIe = isIEBrowser();
        const nextYear = getNextYearObj(this.state.serverDate);
        const recoveryDateMaxDate = getDateObj(getTimestamp(this.state.serverDate).add(14, 'days'));
        
        // Prevent rendering before page is confirmed to be enabled
        if (!vaccineCalculatorSettings || (vaccineCalculatorSettings && vaccineCalculatorSettings.enablePageFlag === 'N') || isIe) {
            return null;
        }

        return (
            <div
                className={`page-container page-container-${locale}`}
                id='MainContent'
            >
                <div
                    className='container-fluid calculator-container'
                    style={{
                        background: `url(${load(
                            './resources/images/calculator-bg.png'
                        )}) 0% 0% no-repeat`,
                    }}
                >
                    <div className='container'>
                        <div className='calculator-decoration1'>
                            <img
                                id=''
                                className='calculator-decoration1-img'
                                src={load(
                                    `./resources/images/calculator-decoration1.png`
                                )}
                            />
                        </div>
                        <div className='calculator-decoration2'>
                            <img
                                id=''
                                className='calculator-decoration2-img'
                                src={load(
                                    `./resources/images/calculator-decoration2.png`
                                )}
                            />
                        </div>
                        <div className='calculator-decoration3'>
                            <img
                                id=''
                                className='calculator-decoration3-img'
                                src={load(
                                    `./resources/images/calculator-decoration3.png`
                                )}
                            />
                        </div>
                        <div className='calculator-title-container'>
                            <h2 
                                id='calculator-title-text'
                                className='calculator-title'>
                                {t('Vaccine Pass Calculator')}
                            </h2>
                            <img
                                id='calculator-title-character-img'
                                className='calculator-title-character'
                                src={load(
                                    `./resources/images/calculator-title-character.png`
                                )}
                            />
                        </div>

                        <div className='calculator-form-container'>
                            <div
                                className='calculator-select-date'
                                alt={t('vaccinePassCalculator.form.checkingDate.open')}
                                onClick={() => {
                                    window.scrollTo(0, 0);
                                    this.setState({
                                        isShowDatePopup:
                                            !this.state.isShowDatePopup,
                                    });
                                }}
                                tabIndex="0"
                            >
                                <span id='quick-date-picker-title'>{t('As of ')}</span>
                                <span id='quick-date-picker-btn'>
                                    {getDateLabel(
                                        `${this.state.calculationDate.year}-${this.state.calculationDate.month}-${this.state.calculationDate.day}`,
                                        this.props.locale,
                                        this.props.t
                                    )}
                                </span>
                                <img
                                    id='quick-date-picker-img'
                                    className='calculator-date-picker-icon'
                                    src={load(
                                        `./resources/images/calculator-date-picker-icon.png`
                                    )}
                                    alt={t('vaccinePassCalculator.form.checkingDate.open')}
                                />
                            </div>
                            <form
                                className='calculator-form'
                                action=''
                                autoComplete='off'
                            >
                                <div className='calculator-question-container calculator-date-of-birth'>
                                    <p 
                                        id='calculator-question-1'
                                        className='calculator-question'>
                                        <b>1.</b>{' '}
                                        {t('What is your Date of Birth?')}
                                    </p>
                                    <CommonDatePicker
                                        t={t}
                                        locale={locale}
                                        type={'dob'}
                                        onDateChange={({ year = '', month = '', day = '' }) => {
                                            this.onDateOfBirthChange({year, month, day}, 
                                                () => this.formDataValidation('dateOfBirth'));
                                        }}
                                        maxDate={{
                                            year: this.state.serverDate.year,
                                            month: this.state.serverDate.month,
                                            day: this.state.serverDate.day,
                                        }}
                                        alt={{
                                            year: t('vaccinePassCalculator.form.dob.year'),
                                            month: t('vaccinePassCalculator.form.dob.month'),
                                            day: t('vaccinePassCalculator.form.dob.day'),
                                        }}
                                    />
                                    <div className='error-message' tabindex="0" ref={input => this.dateOfBirthErrMsgRef = input}>
                                        {this.state.showError &&
                                            this.state.errors.dateOfBirth && (
                                                <>
                                                    {t('dobErrMsg')}
                                                    </>
                                            )}
                                    </div>
                                </div>
                                <div className='calculator-question-container calculator-exemption'>
                                    <p 
                                        id='calculator-question-2'
                                        className='calculator-question'>
                                        <b>2.</b>{' '}
                                        {t(
                                            'Do you have a COVID-19 Vaccination Medical Exemption Certificate?'
                                        )}
                                    </p>
                                    <AccessibilityWrapper 
                                        className={`calculator-radio-button radio-first ${
                                            this.state.isExemption
                                                ? 'active-button'
                                                : ''
                                        }`}
                                        alt={t('vaccinePassCalculator.form.exemptionCert.yes')}
                                        onClick={() => {
                                            this.setState({
                                                isExemption: true,
                                            }, 
                                            () => this.formDataValidation('isExemption'));
                                        }}
                                        id='exemption-yes-btn'
                                    >
                                        {t('Exemption Yes')}
                                    </AccessibilityWrapper>
                                    
                                    <AccessibilityWrapper 
                                        className={`calculator-radio-button radio-last ${
                                            this.state.isExemption === false
                                                ? 'active-button'
                                                : ''
                                        }`}
                                        alt={t('vaccinePassCalculator.form.exemptionCert.no')}
                                        onClick={() => {
                                            this.setState({
                                                isExemption: false,
                                                exemptionExpiryDate: {
                                                    year: '',
                                                    month: '',
                                                    day: '',
                                                },
                                            },
                                            () => this.formDataValidation('isExemption'));
                                        }}
                                        id='exemption-no-btn'
                                    >
                                            {t('Exemption No')}
                                    </AccessibilityWrapper>
                                    <div className='error-message' tabindex="0" ref={input => this.isExemptionErrMsgRef = input}>
                                    {this.state.showError &&
                                        this.state.errors.isExemption && (
                                            <>
                                                {t('exemptionCertNullErrMsg')}
                                            </>
                                        )}
                                    </div>
                                    
                                    <div className='clear'></div>
                                    {this.state.isExemption && (
                                        <>
                                            <p className='calculator-sub-question'>
                                                {t(
                                                    'My Medical Exemption Certificate is valid until'
                                                )}
                                            </p>
                                            <CommonDatePicker
                                                t={t}
                                                locale={locale}
                                                type={'exemption'}
                                                onDateChange={
                                                    ({ year = '', month = '', day = '' }) => {
                                                        this.onStateDateChange({ year, month, day }, 'exemptionExpiryDate', 
                                                        () => this.formDataValidation('exemptionExpiryDate'));
                                                    }
                                                }
                                                maxDate={{
                                                    year: nextYear.year,
                                                    month: nextYear.month,
                                                    day: nextYear.day,
                                                }}
                                                minYear={2019}
                                                reverseYear={true}
                                                alt={{
                                                    year: t('vaccinePassCalculator.form.exemptionCertExpiryDate.year'),
                                                    month: t('vaccinePassCalculator.form.exemptionCertExpiryDate.month'),
                                                    day: t('vaccinePassCalculator.form.exemptionCertExpiryDate.day'),
                                                }}
                                            />
                                            <div className='error-message' tabindex="0" ref={input => this.exemptionExpiryDateErrMsgRef = input}>
                                                {this.state.showError &&
                                                    this.state.errors
                                                        .exemptionExpiryDate && (
                                                        <>
                                                            {t('exemptionCertErrMsg')}
                                                        </>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className='calculator-question-container calculator-diagnose'>
                                    <p 
                                        id='calculator-question-3'
                                        className='calculator-question'>
                                        <b>3.</b>{' '}
                                        {t(
                                            'Have you been infected with COVID-19 before?'
                                        )}
                                    </p>
                                    <AccessibilityWrapper 
                                        className={`calculator-radio-button radio-first ${
                                            this.state.isDiagnose
                                                ? 'active-button'
                                                : ''
                                        }`}
                                        id='diagnose-yes-btn'
                                        alt={t('vaccinePassCalculator.form.recovery.yes')}
                                        onClick={() => {
                                            this.setState({
                                                isDiagnose: true,
                                            },
                                            () => this.formDataValidation('isDiagnose'));
                                        }}
                                    >
                                        {t('Infected Yes')}
                                    </AccessibilityWrapper>
                                    <AccessibilityWrapper 
                                        className={`calculator-radio-button radio-last ${
                                            this.state.isDiagnose === false
                                                ? 'active-button'
                                                : ''
                                        }`}
                                        onClick={() => {
                                            this.setState({
                                                isDiagnose: false,
                                                recoveryDate: {
                                                    year: '',
                                                    month: '',
                                                    day: '',
                                                },
                                            },
                                            () => this.formDataValidation('isDiagnose'));
                                        }}
                                        id='diagnose-no-btn'
                                        alt={t('vaccinePassCalculator.form.recovery.no')}
                                    >
                                        {t('Infected No')}
                                    </AccessibilityWrapper>
                                    <div className='error-message' tabindex="0" ref={input => this.isDiagnoseErrMsgRef = input}>
                                        {this.state.showError &&
                                            this.state.errors.isDiagnose && (
                                                <>{t('diagnoseErrMsg')}</>
                                        )}
                                    </div>
                                    <div className='clear'></div>
                                    {this.state.isDiagnose && (
                                        <>
                                            <p className='calculator-sub-question'>
                                                <img
                                                    ref={
                                                        this
                                                            .recoveryTooltipButtonRef
                                                    }
                                                    id='recovery-tooltip-button'
                                                    className='tooltips-icon'
                                                    src={load(
                                                        `./resources/images/tooltip-icon.png`
                                                    )}
                                                    alt={t('vaccinePassCalculator.form.recoveryDate.tooltipsBtn')}
                                                    onClick={
                                                        this.toggleRecovery
                                                    }
                                                    tabIndex="0"
                                                />
                                                {t('My date of recovery is')}
                                            </p>
                                            {this.state.showRecovery && (
                                                <div
                                                    id='recovery-tooltip-content'
                                                    ref={
                                                        this
                                                            .recoveryTooltipContentRef
                                                    }
                                                >
                                                    <img
                                                        id=''
                                                        className='close-tooltips-icon'
                                                        src={load(
                                                            `./resources/images/covid19-popup/popup-close-desktop.png`
                                                        )}
                                                        alt={t('common.closeBtn')}
                                                        onClick={
                                                            this.toggleRecovery
                                                        }
                                                        tabIndex="0"
                                                    />
                                                    {t('recovery tooltip text')}
                                                </div>
                                            )}
                                            <CommonDatePicker
                                                t={t}
                                                locale={locale}
                                                type={'recovery'}
                                                onDateChange={
                                                    ({ year = '', month = '', day = '' }) => {
                                                        this.onStateDateChange({ year, month, day }, 'recoveryDate', 
                                                        () => this.formDataValidation('recoveryDate'));
                                                    }
                                                }
                                                maxDate={{
                                                    year: recoveryDateMaxDate.year,
                                                    month: recoveryDateMaxDate.month,
                                                    day: recoveryDateMaxDate.day,
                                                }}
                                                minYear={2019}
                                                alt={{
                                                    year: t('vaccinePassCalculator.form.recoveryDate.year'),
                                                    month: t('vaccinePassCalculator.form.recoveryDate.month'),
                                                    day: t('vaccinePassCalculator.form.recoveryDate.day'),
                                                }}
                                            />
                                            <div className='error-message' tabindex="0" ref={input => this.recoveryDateErrMsgRef = input}>
                                            {this.state.showError &&
                                                this.state.errors
                                                    .recoveryDate && (
                                                    <>
                                                        {t('recoveryDateErrMsg')}
                                                    </>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </div>
                                <div className='calculator-question-container calculator-num-of-dose'>
                                    <p 
                                        id='calculator-question-4'
                                        className='calculator-question'>
                                        <b>4.</b>{' '}
                                        {t(
                                            'How many dose of COVID-19 vaccine have you received?'
                                        )}
                                    </p>
                                    <div ref={this.doseNumberDropDownRef} className='no-of-dose-dropdown-container custom-dropdown-container'>
                                        <AccessibilityWrapper
                                            className='dropdown-selector'
                                            onClick={() => {
                                                this.setState({
                                                    showDoseDropdown:
                                                        !this.state
                                                            .showDoseDropdown,
                                                });
                                            }}
                                            id='no-of-dose-dropdown'
                                            alt={t('vaccinePassCalculator.form.numOfVaccines.dropdown')}
                                        >
                                            {
                                                this.state.numberOfDose === '' 
                                                ? t('Please choose the number of dose you have received.')
                                                : this.getNumOfDoseOptionLabel(this.state.numberOfDose)
                                            }
                                        </AccessibilityWrapper>
                                        {this.state.showDoseDropdown && (
                                            <ul className='no-of-dose-dropdown'>
                                                {
                                                    [...Array(this.state.doseLimit+1)].map((e,i) => (
                                                        <a>
                                                            <li 
                                                                key={i} 
                                                                onClick={() => {
                                                                    this.triggerVaccinationsUpdate(
                                                                        i, 
                                                                        () => this.formDataValidation('numberOfDose')
                                                                    );
                                                                }} 
                                                                id={'no-of-dose-'+i}
                                                            >
                                                            {this.getNumOfDoseOptionLabel(i)}
                                                        </li> 
                                                            </a>
                                                         
                                                    ))
                                                }
                                            </ul>
                                        )}
                                    </div>
                                    <div className='error-message' tabindex="0" ref={input => this.numberOfDoseErrMsgRef = input}>
                                        {this.state.showError &&
                                            this.state.errors.numberOfDose && (
                                                <>
                                                    {t('numberOfDoseErrMsg')}
                                                </>
                                        )}
                                    </div>
                                </div>
                                {
                                    parseInt(this.state.numberOfDose) > 0 && parseInt(this.state.numberOfDose) < this.state.doseLimit && 
                                    (
                                        <>
                                            <div className='calculator-question-container calculator-type-of-dose' tabindex="0" ref={input => this.vaccinesErrMsgRef = input}>
                                                <p 
                                                    id='calculator-question-5'
                                                    className='calculator-question'>
                                                    <b>5.</b>{' '}
                                                    {t(
                                                        'Which COVID-19 vaccine(s) have you received?'
                                                    )}
                                                </p>
                                                {this.renderVaccineTypeElements().map(
                                                    (output) => {
                                                        return output;
                                                    }
                                                )}
                                            </div>
                                            {
                                                parseInt(this.state.numberOfDose) <= this.state.doseLimit && (
                                                <div className='calculator-question-container calculator-last-vaccine-date'>
                                                    <p 
                                                        id='calculator-question-6'
                                                        className='calculator-question'>
                                                        <b>6.</b>{' '}
                                                        {t(
                                                            'When was your last COVID-19 vaccination date?'
                                                        )}
                                                    </p>
                                                    <CommonDatePicker
                                                        t={t}
                                                        locale={locale}
                                                        type={'lastVaccine'}
                                                        onDateChange={({ year = '', month = '', day = '' }) => {
                                                            this.onStateDateChange({ year, month, day }, 'lastVaccineDate', 
                                                            () => this.formDataValidation('lastVaccineDate'));
                                                        }}
                                                        minYear={2019}
                                                        maxDate={{
                                                            year: nextYear.year,
                                                            month: nextYear.month,
                                                            day: nextYear.day,
                                                        }}
                                                        alt={{
                                                            year: t('vaccinePassCalculator.form.lastVaccineDate.year'),
                                                            month: t('vaccinePassCalculator.form.lastVaccineDate.month'),
                                                            day: t('vaccinePassCalculator.form.lastVaccineDate.day'),
                                                        }}
                                                    />
                                                    <div className='error-message' tabindex="0" ref={input => this.lastVaccineDateErrMsgRef = input}>
                                                        {this.state.showError &&
                                                            this.state.errors
                                                                .lastVaccineDate && (
                                                                <>
                                                                    {t('lastVaccineDateErrMsg')}
                                                                </>
                                                            )}
                                                        {this.state.showError &&
                                                            this.state.errors
                                                                .lastVaccineDateIsAfterCaclculationDate && (
                                                                <>
                                                                    {t('lastVaccineDateIsAfterCaclculationDateErrMsg')}
                                                                </>
                                                            )}
                                                    </div>
                                                </div>
                                            )}
                                        </>
                                    )
                                }
                                <AccessibilityWrapper 
                                    className={`calculator-submit`}
                                    alt={t('vaccinePassCalculator.form.calculateBtn')}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        this.setState({submitBtnIsClickedOnce: true}, this.onConfirmCalculation)
                                    }}
                                    id='sumbit-form-btn'
                                >
                                    {t('Calculate now')}
                                </AccessibilityWrapper>
                                <div className='disclaimer-text'>
                                    {this.state.vpcDisclaimerEnableFlag === 'Y' 
                                        ? <p id='disclaimer-text' dangerouslySetInnerHTML={{__html: this.getVpcResultDisclaimerCW()}} />
                                        : ''}
                                </div>
                                <div className='disclaimer-url'>
                                    <a
                                        href={this.getLearnMoreText() ? this.getLearnMoreURL() : ''}
                                        target="_blank"
                                        id='disclaimer-url'
                                    >
                                        {
                                            this.state.vpcDisclaimerEnableFlag === 'Y' && this.state.vpcDisclaimerUrlEnableFlag === 'Y'
                                            ? this.getLearnMoreText()
                                            : ''
                                        }
                                    </a>
                                </div>
                            </form>
                        </div>
                        <div className='calculator-app-section'>
                            <img
                                id='calculator-men-img'
                                className='calculator-men-character'
                                src={load(
                                    `./resources/images/calculator-men.png`
                                )}
                            />
                            <div 
                                id='calculator-app-text'
                                className='calculator-app-text'>
                                {t(
                                    'Use the Vaccine Pass Calculator on 醫健通 eHealth App to calculate all information for you to'
                                )}
                                <br />
                                <b>
                                    {t(
                                        'check your Vaccine Pass status in one tap.'
                                    )}
                                </b>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isShowDatePopup && (
                    <>
                        <div className='calculator-datepicker-overlay'></div>
                        <div className='calculator-datepicker-popup'>
                            <img
                                onClick={() => {
                                    this.setState({
                                        isShowDatePopup:
                                            !this.state.isShowDatePopup,
                                        popupCalculationDate:
                                            this.state.calculationDate,
                                    });
                                }}
                                id='close-popup-icon'
                                className='close-popup-icon'
                                src={load(
                                    `./resources/images/covid19-popup/popup-close-desktop.png`
                                )}
                                alt={t('common.closeBtn')}
                                tabIndex="0"
                            />
                            <h3>{t('Choose another date')}</h3>
                            <CommonDatePicker
                                t={t}
                                locale={locale}
                                type={'anotherDate'}
                                initialDate={this.state.calculationDate}
                                onDateChange={
                                    ({ year = '', month = '', day = '' }) => {
                                        this.onStateDateChange({ year, month, day },'popupCalculationDate', 
                                        () => this.formDataValidation('calculationDate'))
                                }}
                                minDate={{
                                    year: this.state.serverDate.year,
                                    month: this.state.serverDate.month,
                                    day: this.state.serverDate.day,
                                }}
                                maxDate={{
                                    year: nextYear.year,
                                    month: nextYear.month,
                                    day: nextYear.day,
                                }}
                                alt={{
                                    year: t('vaccinePassCalculator.form.checkingDate.year'),
                                    month: t('vaccinePassCalculator.form.checkingDate.month'),
                                    day: t('vaccinePassCalculator.form.checkingDate.day')
                                }}
                            />
                            {this.state.showError &&
                                this.state.errors.calculationDate && (
                                    <div className='error-message'>
                                        {t('mandatoryErrMsg')}
                                    </div>
                                )}
                            <div className='clear'></div>
                            <AccessibilityWrapper
                                onClick={(e) => {
                                    e.preventDefault();
                                    this.setState({
                                        isShowDatePopup:
                                            !this.state.isShowDatePopup,
                                        calculationDate:
                                            this.state.popupCalculationDate,
                                    });
                                }}
                                className='calculator-submit'
                                id='sumbit-another-date-btn'
                                alt={t('vaccinePassCalculator.form.checkingDate.confirm')}
                            >
                                {t('dateConfirm')}
                            </AccessibilityWrapper>
                        </div>
                    </>
                )}
                {this.state.vpcDisclaimerEnableFlag === 'Y' && this.state.vpcDisclaimerPopUpEnableFlag === 'Y' && this.state.isShowDisclaimerPopup && !isNullOrEmpty(this.getDisclaimerPopupMsg()) && (
                    <>
                        <div className='disclaimer-popup-overlay'></div>
                            <div class="disclaimer-popup-container">
                                <img
                                className='disclaimer-popup-img-desktop'
                                src={load(`./resources/images/disclaimer-popup/ehealth-red-popup-desktop.png`)}
                                alt={this.getDisclaimerPopupMsg()}
                                />
                                <div
                                className="disclaimer-popup-text-desktop"
                                dangerouslySetInnerHTML={{__html: this.getDisclaimerPopupMsg()}}
                                />
                                <img
                                onClick={() => {
                                    this.setState({
                                        isShowDisclaimerPopup:
                                            !this.state.isShowDisclaimerPopup,
                                    });
                                }}
                                id='close-disclaimer-popup-icon'
                                className='close-disclaimer-popup-icon-desktop'
                                src={load(
                                    `./resources/images/disclaimer-popup/ok-button-${locale}.png`
                                )}
                                alt={t('disclaimer popup ok button')}
                                />

                                
                                <img
                                className='disclaimer-popup-img-mobile'
                                src={load(`./resources/images/disclaimer-popup/ehealth-red-popup-mobile.png`)}
                                alt={this.getDisclaimerPopupMsg()}
                                />
                                <div
                                className="disclaimer-popup-text-mobile"
                                dangerouslySetInnerHTML={{__html: this.getDisclaimerPopupMsg()}}
                                />
                                <img
                                onClick={() => {
                                    this.setState({
                                        isShowDisclaimerPopup:
                                            !this.state.isShowDisclaimerPopup,
                                    });
                                }}
                                id='close-disclaimer-popup-icon'
                                className='close-disclaimer-popup-icon-mobile'
                                src={load(
                                    `./resources/images/disclaimer-popup/ok-button-${locale}.png`
                                )}
                                alt={t('disclaimer popup ok button')}
                                />
                            </div>
                    </>
                )}
                
                <Footer {...this.props} />
            </div>
        );
    }
}

export default PageHOC(VaccineCalculator);

import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import Footer from "../Footer";

class EhealthProFAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sectionOne: ["close", "close"],
      sectionOneOpenAll: false,
      sectionTwo: ["close", "close", "close", "close"],
      sectionTwoOpenAll: false,
      sectionThree: ["close"],
      sectionThreeOpenAll: false,
      sectionFour: ["close", "close"],
      sectionFourOpenAll: false,
      sectionFive: ["close", "close"],
      sectionFiveOpenAll: false,
      sectionSix: ["close"],
      sectionSixOpenAll: false,
      openAll: false,
    };
  }
  checkIsOpenAll() {
    return (
      this.state.sectionOneOpenAll &&
      this.state.sectionTwoOpenAll &&
      this.state.sectionThreeOpenAll &&
      this.state.sectionFourOpenAll &&
      this.state.sectionFiveOpenAll &&
      this.state.sectionFiveOpenAll &&
      this.state.sectionSixOpenAll
    );
  }
  toggleQuestion(section, index) {
    const newSectionState = [...this.state[section]];
    if (newSectionState[index] === "close") {
      newSectionState[index] = "open";
    } else {
      newSectionState[index] = "close";
    }
    let openCount = 0;
    let isOpenAllInSection = null;
    for (let section of newSectionState) {
      if (section === "open") openCount += 1;
    }
    if (openCount === newSectionState.length) isOpenAllInSection = true;
    else if (openCount === 0) isOpenAllInSection = false;

    if (isOpenAllInSection !== null) {
      this.setState({
        ...this.state,
        [section]: newSectionState,
        [`${section}OpenAll`]: isOpenAllInSection,
      });
    } else {
      this.setState({
        ...this.state,
        [section]: newSectionState,
      });
    }
  }

  toggleSectionQuestions(section) {
    const openAllstatus = this.state[`${section}OpenAll`];
    const newSectionState = [...this.state[section]];
    console.log(newSectionState);
    newSectionState.forEach(function (item, index) {
      if (openAllstatus) {
        newSectionState[index] = "close";
      } else {
        newSectionState[index] = "open";
      }
    });
    this.setState(
      {
        ...this.state,
        [section]: newSectionState,
        [`${section}OpenAll`]: !this.state[`${section}OpenAll`],
      },
      () => {
        let newOpenAll = this.checkIsOpenAll();

        console.log(this.state.openAll, newOpenAll);
        if (newOpenAll !== this.state.openAll) {
          this.setState({
            ...this.state,
            openAll: newOpenAll,
          });
        }
      }
    );
  }
  toggleAll() {
    const newState = {};
    const isOpen = this.state.openAll;
    console.log(isOpen);
    const sections = [
      "sectionOne",
      "sectionTwo",
      "sectionThree",
      "sectionFour",
      "sectionFive",
      "sectionFive",
      "sectionSix",
    ];
    sections.forEach((section) => {
      const newSectionState = [...this.state[section]];
      newSectionState.forEach(function (item, index) {
        if (isOpen) {
          newSectionState[index] = "close";
        } else {
          newSectionState[index] = "open";
        }
      });
      newState[section] = newSectionState;
      newState[`${section}OpenAll`] = !isOpen;
    });
    this.setState({
      ...this.state,
      ...newState,
      openAll: !this.state.openAll,
    });
  }

  render() {
    const { locale, t, languageCodeMap } = this.props;
    const { sectionOne } = this.state;
    const { sectionTwo } = this.state;
    const { sectionThree } = this.state;
    const { sectionFour } = this.state;
    const { sectionFive } = this.state;
    const { sectionSix } = this.state;
    let { eHealthSite, registerLink } = this.props;

    if (locale === "en") {
      registerLink =
        "https://www.ehealth.gov.hk/en/you-and-your-family/how-to-register/index.html";
      eHealthSite = "https://app.ehealth.gov.hk/app/index.html?lang=en";
    } else if (locale === "zh-CN") {
      registerLink =
        "https://www.ehealth.gov.hk/sc/you-and-your-family/how-to-register/index.html";
      eHealthSite = "https://app.ehealth.gov.hk/app/index.html?lang=sc";
    } else if (locale === "zh-HK") {
      registerLink =
        "https://www.ehealth.gov.hk/tc/you-and-your-family/how-to-register/index.html";
      eHealthSite = "https://app.ehealth.gov.hk/app/index.html?lang=tc";
    }

    return (
      <div
        className={`page-container pro-faq page-container-${locale}`}
        id="MainContent"
      >
        <div className="static_page_header static_page_header_download">
          <div
            className="static_page_header_bg"
            style={{
              background: `url(${load(
                "./resources/images/ehealth-pro/ehealthpro_faq-banner-bg.jpg"
              )}) no-repeat 0%`,
            }}
          ></div>
          <div className="container ">
            <div className="row vertical-align">
              <div className="col-sm-12 download-banner-graphic">
                <img
                  id="imgCovidAdolescentTopBannerImage"
                  src={load(
                    `./resources/images/${locale}/ehealthpro_faq-banner-graphic.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container faq-container">
          <div className="row">
            <div className="faq-expand-all">
              <span className="faq-expand" onClick={() => this.toggleAll()}>
                {this.state.openAll
                  ? t("Collapse all section")
                  : t("Expand all section")}
              </span>
            </div>
            <div className="faq-section first-faq-section">
              <div className="faq-title">
                <h3>{t("General")}</h3>
                <span
                  className="faq-expand"
                  onClick={() => {
                    this.toggleSectionQuestions("sectionOne");
                  }}
                >
                  {this.state.sectionOneOpenAll
                    ? t("Collapse this section")
                    : t("Expand this section")}
                </span>
              </div>

              <div className="faq-question-container">
                <div
                  className="faq-question"
                  onClick={() => this.toggleQuestion("sectionOne", 0)}
                >
                  {t("pro-faq-section1-q01-question")}
                  <img
                    className="faq-expand-icon"
                    src={load(
                      `${
                        sectionOne[0] === "close"
                          ? "./resources/images/collapse-button-open.png"
                          : "./resources/images/collapse-button.png"
                      }`
                    )}
                  />
                </div>
                {sectionOne[0] === "open" && (
                  <div className="faq-answer">
                    {t("pro-faq-section1-q01-answer")}
                  </div>
                )}
              </div>

              <div className="faq-question-container">
                <div
                  className="faq-question"
                  onClick={() => this.toggleQuestion("sectionOne", 1)}
                >
                  {t("pro-faq-section1-q02-question")}
                  <img
                    className="faq-expand-icon"
                    src={load(
                      `${
                        sectionOne[1] === "close"
                          ? "./resources/images/collapse-button-open.png"
                          : "./resources/images/collapse-button.png"
                      }`
                    )}
                  />
                </div>
                {sectionOne[1] === "open" && (
                  <div className="faq-answer">
                    {t("pro-faq-section1-q02-answer")}
                  </div>
                )}
              </div>
            </div>

            <div className="faq-section">
              <div className="faq-title">
                <h3>{t("Get Started/ Login")}</h3>
                <span
                  className="faq-expand"
                  onClick={() => this.toggleSectionQuestions("sectionTwo")}
                >
                  {this.state.sectionTwoOpenAll
                    ? t("Collapse this section")
                    : t("Expand this section")}
                </span>
              </div>

              <div className="faq-question-container">
                <div
                  className="faq-question"
                  onClick={() => this.toggleQuestion("sectionTwo", 0)}
                >
                  {t("pro-faq-section2-q01-question")}
                  <img
                    className="faq-expand-icon"
                    src={load(
                      `${
                        sectionTwo[0] === "close"
                          ? "./resources/images/collapse-button-open.png"
                          : "./resources/images/collapse-button.png"
                      }`
                    )}
                  />
                </div>
                {sectionTwo[0] === "open" && (
                  <div className="faq-answer">
                    {t("pro-faq-section2-q01-answer")}
                  </div>
                )}
              </div>

              <div className="faq-question-container">
                <div
                  className="faq-question"
                  onClick={() => this.toggleQuestion("sectionTwo", 1)}
                >
                  {t("pro-faq-section2-q02-question")}
                  <img
                    className="faq-expand-icon"
                    src={load(
                      `${
                        sectionTwo[1] === "close"
                          ? "./resources/images/collapse-button-open.png"
                          : "./resources/images/collapse-button.png"
                      }`
                    )}
                  />
                </div>
                {sectionTwo[1] === "open" && (
                  <div className="faq-answer">
                    {t("pro-faq-section2-q02-answer")}
                  </div>
                )}
              </div>

              <div className="faq-question-container">
                <div
                  className="faq-question"
                  onClick={() => this.toggleQuestion("sectionTwo", 2)}
                >
                  {t("pro-faq-section2-q03-question")}
                  <img
                    className="faq-expand-icon"
                    src={load(
                      `${
                        sectionTwo[2] === "close"
                          ? "./resources/images/collapse-button-open.png"
                          : "./resources/images/collapse-button.png"
                      }`
                    )}
                  />
                </div>
                {sectionTwo[2] === "open" && (
                  <div className="faq-answer">
                    {t("pro-faq-section2-q03-answer")}
                  </div>
                )}
              </div>

              <div className="faq-question-container">
                <div
                  className="faq-question"
                  onClick={() => this.toggleQuestion("sectionTwo", 3)}
                >
                  {t("pro-faq-section2-q04-question")}
                  <img
                    className="faq-expand-icon"
                    src={load(
                      `${
                        sectionTwo[3] === "close"
                          ? "./resources/images/collapse-button-open.png"
                          : "./resources/images/collapse-button.png"
                      }`
                    )}
                  />
                </div>
                {sectionTwo[3] === "open" && (
                  <div className="faq-answer">
                    {t("pro-faq-section2-q04-answer")}
                  </div>
                )}
              </div>
            </div>

            <div className="faq-section">
              <div className="faq-title">
                <h3>{t("eHRSS Healthcare Professional Account Management")}</h3>
                <span
                  className="faq-expand"
                  onClick={() => this.toggleSectionQuestions("sectionThree")}
                >
                  {this.state.sectionThreeOpenAll
                    ? t("Collapse this section")
                    : t("Expand this section")}
                </span>
              </div>

              <div className="faq-question-container">
                <div
                  className="faq-question"
                  onClick={() => this.toggleQuestion("sectionThree", 0)}
                >
                  {t("pro-faq-section3-q01-question")}
                  <img
                    className="faq-expand-icon"
                    src={load(
                      `${
                        sectionThree[0] === "close"
                          ? "./resources/images/collapse-button-open.png"
                          : "./resources/images/collapse-button.png"
                      }`
                    )}
                  />
                </div>
                {sectionThree[0] === "open" && (
                  <div className="faq-answer">
                    {t("pro-faq-section3-q01-answer")}
                  </div>
                )}
              </div>
            </div>

            <div className="faq-section">
              <div className="faq-title">
                <h3>
                  {t(
                    "Log in to desktop version of Electronic Health Record Sharing System (eHRSS) with QR code"
                  )}
                </h3>
                <span
                  className="faq-expand"
                  onClick={() => this.toggleSectionQuestions("sectionFour")}
                >
                  {this.state.sectionFourOpenAll
                    ? t("Collapse this section")
                    : t("Expand this section")}
                </span>
              </div>

              <div className="faq-question-container">
                <div
                  className="faq-question"
                  onClick={() => this.toggleQuestion("sectionFour", 0)}
                >
                  {t("pro-faq-section4-q01-question")}
                  <img
                    className="faq-expand-icon"
                    src={load(
                      `${
                        sectionFour[0] === "close"
                          ? "./resources/images/collapse-button-open.png"
                          : "./resources/images/collapse-button.png"
                      }`
                    )}
                  />
                </div>
                {sectionFour[0] === "open" && (
                  <div className="faq-answer">
                    {t("pro-faq-section4-q01-answer")}
                  </div>
                )}
              </div>

              <div className="faq-question-container">
                <div
                  className="faq-question"
                  onClick={() => this.toggleQuestion("sectionFour", 1)}
                >
                  {t("pro-faq-section4-q02-question")}
                  <img
                    className="faq-expand-icon"
                    src={load(
                      `${
                        sectionFour[1] === "close"
                          ? "./resources/images/collapse-button-open.png"
                          : "./resources/images/collapse-button.png"
                      }`
                    )}
                  />
                </div>
                {sectionFour[1] === "open" && (
                  <div className="faq-answer">
                    {t("pro-faq-section4-q02-answer")}
                  </div>
                )}
              </div>
            </div>

            <div className="faq-section">
              <div className="faq-title">
                <h3>{t("Technical")}</h3>
                <span
                  className="faq-expand"
                  onClick={() => this.toggleSectionQuestions("sectionFive")}
                >
                  {this.state.sectionFiveOpenAll
                    ? t("Collapse this section")
                    : t("Expand this section")}
                </span>
              </div>

              <div className="faq-question-container">
                <div
                  className="faq-question"
                  onClick={() => this.toggleQuestion("sectionFive", 0)}
                >
                  {t("pro-faq-section5-q01-question")}
                  <img
                    className="faq-expand-icon"
                    src={load(
                      `${
                        sectionFive[0] === "close"
                          ? "./resources/images/collapse-button-open.png"
                          : "./resources/images/collapse-button.png"
                      }`
                    )}
                  />
                </div>
                {sectionFive[0] === "open" && (
                  <div className="faq-answer">
                    {t("pro-faq-section5-q01-answer")}
                  </div>
                )}
              </div>

              <div className="faq-question-container">
                <div
                  className="faq-question"
                  onClick={() => this.toggleQuestion("sectionFive", 1)}
                >
                  {t("pro-faq-section5-q02-question")}
                  <img
                    className="faq-expand-icon"
                    src={load(
                      `${
                        sectionFive[1] === "close"
                          ? "./resources/images/collapse-button-open.png"
                          : "./resources/images/collapse-button.png"
                      }`
                    )}
                  />
                </div>
                {sectionFive[1] === "open" && (
                  <div className="faq-answer">
                    {t("pro-faq-section5-q02-answer")}
                  </div>
                )}
              </div>
            </div>
            <div className="faq-section">
              <div className="faq-title">
                <h3>{t("Enquiry")}</h3>
                <span
                  className="faq-expand"
                  onClick={() => this.toggleSectionQuestions("sectionSix")}
                >
                  {this.state.sectionSixOpenAll
                    ? t("Collapse this section")
                    : t("Expand this section")}
                </span>
              </div>
              <div className="faq-question-container">
                <div
                  className="faq-question"
                  onClick={() => this.toggleQuestion("sectionSix", 0)}
                >
                  {t("pro-faq-section6-q01-question")}
                  <img
                    className="faq-expand-icon"
                    src={load(
                      `${
                        sectionSix[0] === "close"
                          ? "./resources/images/collapse-button-open.png"
                          : "./resources/images/collapse-button.png"
                      }`
                    )}
                  />
                </div>
                {sectionSix[0] === "open" && (
                  <div className="faq-answer">
                    {t("pro-faq-section6-q01-answer")}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(EhealthProFAQ);

import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import Footer from "../Footer";
import Bg1_16_9 from "../../resources/images/download-item/background/ehealth-bg1-16-9.jpg";
import Bg1_4_3 from "../../resources/images/download-item/background/ehealth-bg1-4-3.jpg";
import Bg1_mobile from "../../resources/images/download-item/background/ehealth-bg1-mobile.jpg";

import Bg2_16_9 from "../../resources/images/download-item/background/ehealth-bg2-16-9.jpg";
import Bg2_4_3 from "../../resources/images/download-item/background/ehealth-bg2-4-3.jpg";
import Bg2_mobile from "../../resources/images/download-item/background/ehealth-bg2-mobile.jpg";

import Bg3_16_9 from "../../resources/images/download-item/background/ehealth-bg3-16-9.jpg";
import Bg3_4_3 from "../../resources/images/download-item/background/ehealth-bg3-4-3.jpg";
import Bg3_mobile from "../../resources/images/download-item/background/ehealth-bg3-mobile.jpg";

class downloadBgPage extends Component {
  render() {
    const { locale, t, languageCodeMap } = this.props;
    return (
      <div
        className={`page-container page-container-${locale}`}
        id="MainContent"
      >
        <div className="static_page_header static_page_header_download">
          <div
            class="static_page_header_bg"
            style={{
              background: `url(${load(
                "./resources/images/download-banner-bg.jpg"
              )}) no-repeat 0%`,
            }}
          ></div>
          <div className="container ">
            <div className="row vertical-align">
              <div className="col-sm-12 download-banner-graphic">
                <img
                  id="imgCovidAdolescentTopBannerImage"
                  src={load(
                    `./resources/images/${locale}/download-bg-banner-graphic.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container download-container">
          <div className="row">
            <div className="col-md-6 col-sm-6 download-item background">
              <div className="background-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/background/ehealth-bg1-preview.jpg`
                  )}
                />
              </div>
              <div className="background-item-download">
                <span>{t("Select size")}</span>
                <a href={Bg1_4_3} className="item-download" target="_blank">
                  4:3
                </a>
                <a href={Bg1_16_9} className="item-download" target="_blank">
                  16:9
                </a>
                <a href={Bg1_mobile} className="item-download" target="_blank">
                  {t("Mobile")}
                </a>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 download-item background">
              <div className="background-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/background/ehealth-bg2-preview.jpg`
                  )}
                />
              </div>
              <div className="background-item-download">
                <span>{t("Select size")}</span>
                <a href={Bg2_4_3} className="item-download" target="_blank">
                  4:3
                </a>
                <a href={Bg2_16_9} className="item-download" target="_blank">
                  16:9
                </a>
                <a href={Bg2_mobile} className="item-download" target="_blank">
                  {t("Mobile")}
                </a>
              </div>
            </div>
            <div className="col-md-6 col-sm-6 download-item background">
              <div className="background-item-preview">
                <img
                  src={load(
                    `./resources/images/download-item/background/ehealth-bg3-preview.jpg`
                  )}
                />
              </div>
              <div className="background-item-download">
                <span>{t("Select size")}</span>
                <a href={Bg3_4_3} className="item-download" target="_blank">
                  4:3
                </a>
                <a href={Bg3_16_9} className="item-download" target="_blank">
                  16:9
                </a>
                <a href={Bg3_mobile} className="item-download" target="_blank">
                  {t("Mobile")}
                </a>
              </div>
            </div>
          </div>

          <div className="promulgation-back">
            <a href="/promulgation">{t("Back")}</a>
          </div>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(downloadBgPage);

import enVaccineCalculator from "./vaccineCalculator/en.json";
import hkVaccineCalculator from "./vaccineCalculator/zh-HK.json";
import cnVaccineCalculator from "./vaccineCalculator/zh-CN.json";

import enAccessibility from "./accessibility/en.json";
import hkAccessibility from "./accessibility/zh-HK.json";
import cnAccessibility from "./accessibility/zh-CN.json";

import enPrivacyGame from "./privacyGame/en.json";
import hkPrivacyGame from "./privacyGame/zh-HK.json";
import cnPrivacyGame from "./privacyGame/zh-CN.json";

import en from "./en.json";
import hk from "./zh-HK.json";
import cn from "./zh-CN.json";

const flattenMessages = (nestedMessages, prefix = "") => {
  if (nestedMessages === null) {
    return {};
  }
  return Object.keys(nestedMessages).reduce((messages, key) => {
    const value = nestedMessages[key];
    const prefixedKey = prefix ? `${prefix}.${key}` : key;

    if (typeof value === "string") {
      Object.assign(messages, { [prefixedKey]: value });
    } else {
      Object.assign(messages, flattenMessages(value, prefixedKey));
    }

    return messages;
  }, {});
};

export default {
  en: {
    ...en,
    ...enVaccineCalculator,
    ...flattenMessages(enAccessibility),
    ...flattenMessages(enPrivacyGame),
  },
  "zh-HK": {
    ...hk,
    ...hkVaccineCalculator,
    ...flattenMessages(hkAccessibility),
    ...flattenMessages(hkPrivacyGame),
  },
  "zh-CN": {
    ...cn,
    ...cnVaccineCalculator,
    ...flattenMessages(cnAccessibility),
    ...flattenMessages(cnPrivacyGame),
  },
};

import React, { Component } from "react";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

function pageRedirect() {
  var delay = 10000; // time in milliseconds
  setTimeout(function () {
    window.location = "https://app.ehealth.gov.hk";
  }, delay);
}

class NotFoundPage extends Component {
  render() {
    pageRedirect();
    const { locale, t } = this.props;
    return (
      <div
        className={`page404-content page-container page-container-${locale}`}
        id="MainContent"
      >
        <div className="container page404-container">
          <div className="">
            <img
              src={load(`./resources/images/${locale}/page-not-found.jpg`)}
              alt=" "
            />
            <div className="empty-space-40"></div>
            <h1>{t("Sorry!")}</h1>
            <p>
              <span>{t("We cannot find the page you are looking for...")}</span>
              <br />
              {t("You will be redirected to")}
              <a href="https://app.ehealth.gov.hk">
                https://app.ehealth.gov.hk
              </a>{" "}
              {t("automatically after 10 seconds.")}
            </p>
            <a href="https://app.ehealth.gov.hk" className="not-found-btn">
              {t("Back to Home")}
            </a>
          </div>
        </div>
      </div>
    );
  }
}
export default PageHOC(NotFoundPage);

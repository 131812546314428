import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class EhealthProPage extends Component {
  render() {
    const { locale, t } = this.props;
    const sliderImages = ["ehealthpro_slider_phone1.png"];
    const sliderImageAlts = ["Screenshot of eHealthPro and Medications"];
    const sliderImageIds = ["img1eHealthProSlider"];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"ehealth-pro-bg.png"}
        sliderID="eHealthProSlider"
        sliderTitle={t("eHealthPro Header")}
        sliderTitleID={"texteHealthProSliderTitle"}
        sliderText={t(
          "One-stop management of Electronic Health Record Sharing System (eHRSS) accounts of Healthcare Professionals"
        )}
        sliderTextID="texteHealthProSliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );
    return (
      <div
        className={`page-container page-container-${locale} ehealthpro-color single-phone`}
        id="MainContent"
      >
        {phoneSlider}
        <div className="container-fluid ehealthpro-section">
          <div className="container">
            <div className="row vertical-align ">
              <div data-aos="" className="col-sm-12 align-self-center">
                <div className="ehealthpro-title">
                  {t("eHealth Pro is an extended feature in 醫健通eHealth App")}
                </div>
                <div className="ehealthpro-title">
                  {t("You may activate to:")}
                </div>
                <div className="row col-sm-12 ehealthpro-feature-row">
                  <div className="row clear ">
                    <div className="col-xs-8 col-sm-4 ehealthpro-feature">
                      <img
                        className="pro-feature-img"
                        alt={t(
                          "Manage your eHRSS healthcare professional account"
                        )}
                        src={load(
                          `./resources/images/ehealth-pro/ehealth_pro_feature_1.png`
                        )}
                      />
                      {t("Manage your eHRSS healthcare professional account")}
                    </div>
                    <div className="col-xs-8 col-sm-4 ehealthpro-feature">
                      <img
                        className="pro-feature-img"
                        alt={t(
                          "Login to the desktop version of eHRSS by scanning QR code"
                        )}
                        src={load(
                          `./resources/images/ehealth-pro/ehealth_pro_feature_2.png`
                        )}
                      />
                      {t(
                        "Login to the desktop version of eHRSS by scanning QR code"
                      )}
                    </div>
                    <div className="col-xs-8 col-sm-4 ehealthpro-feature">
                      <img
                        className="pro-feature-img"
                        alt={t(
                          "Obtain a series of promotional information and news"
                        )}
                        src={load(
                          `./resources/images/ehealth-pro/ehealth_pro_feature_3.png`
                        )}
                      />
                      {t("Obtain a series of promotional information and news")}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid ehealthpro-2nd-section">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="" className="col-sm-12 align-self-center">
                <div className="ehealthpro-title">
                  {t("Activate eHealth Pro in 3 simple steps")}
                </div>
              </div>
            </div>
            <div className="row ehealthpro-step vertical-align">
              <div className="col-md-4 align-self-center">
                <img
                  className="pro-feature-img"
                  alt={t(
                    "If you are an eligible healthcare professional, the eHealth Pro’s activation page will be prompted automatically when you log in to 醫健通eHealth App."
                  )}
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_activiate_step_1.png`
                  )}
                />
              </div>
              <div className="col-md-8 align-self-center">
                <span className="pro-step-no">{t("Step 1")}</span>
                {t(
                  "If you are an eligible healthcare professional, the eHealth Pro’s activation page will be prompted automatically when you log in to 醫健通eHealth App."
                )}
              </div>
            </div>

            <div className="row ehealthpro-step vertical-align">
              <div className="col-md-4">
                <img
                  className="pro-feature-img"
                  alt={t("'Activate Now'")}
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_activiate_step_2.png`
                  )}
                />
              </div>
              <div className="col-md-8">
                <span className="pro-step-no">{t("Step 2")}</span>
                {t("Tap")}
                <b>{t("'Activate Now'")}</b>
              </div>
            </div>

            <div className="row ehealthpro-step vertical-align">
              <div className="col-md-4">
                <img
                  className="pro-feature-img"
                  alt={t("Input the One Time Password (OTP)")}
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_activiate_step_3.png`
                  )}
                />
              </div>
              <div className="col-md-8">
                <span className="pro-step-no">{t("Step 3")}</span>
                {t("Input the One Time Password (OTP)")}
              </div>
            </div>

            <div className="row ehealthpro-step vertical-align done-step">
              <div className="col-md-4">
                <img
                  className="pro-feature-img"
                  alt={t("You can use eHealth Pro now!")}
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_activiate_step_4.png`
                  )}
                />
              </div>
              <div className="col-md-8">
                <span className="pro-step-no">{t("DONE!")}</span>
                <span className="pro-donestep-des">
                  {t("You can use eHealth Pro now!")}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid  ehealthpro-bg">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="texteHealthProSection1Title"
                  className="second-feature-title"
                >
                  {t("Make Login to eHRSS Safe, Simple and Fast")}
                </div>
                <div
                  id="texteHealthProSection1Content"
                  className="second-feature-text"
                >
                  {t(
                    "Login to the desktop version of eHRSS by scanning QR code"
                  )}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1eHealthProSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_p1_1.png`
                  )}
                  alt={t("Make Login to eHRSS Safe, Simple and Fast")}
                />
                <img
                  id="img2eHealthProSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_p1_2.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid ehealthpro-light-bg ">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textMyFamilySection2Title"
                  className="second-feature-title"
                >
                  {t("One-tap to switch between your eHRSS accounts")}
                </div>
                <div
                  id="textMyFamilySection2Content"
                  className="second-feature-text"
                >
                  {t(
                    "Switch between your eHealth accounts for Healthcare Recipients and Healthcare Professional for easy access to different functions"
                  )}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1eHealthProSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_p2_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img2eHealthProSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_p2_2.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid  ehealthpro-bg last-section">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="texteHealthProSection1Title"
                  className="second-feature-title"
                >
                  {t("Simplify reset password or update profile process")}
                </div>
                <div
                  id="texteHealthProSection1Content"
                  className="second-feature-text"
                >
                  {t(
                    "Reset your password or update your contact information anytime and anywhere via eHealth Pro"
                  )}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1eHealthProSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_p3_1.png`
                  )}
                  alt={t("Make Login to eHRSS Safe, Simple and Fast")}
                />
                <img
                  id="img2eHealthProSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_p3_2.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>
        <div
          data-aos="fade-down"
          data-aos-offset="-200"
          className="second-video"
          alt={t("Video")}
        >
          <div ref={this.youtubeContainer} className="second-youtube-container">
            <iframe
              id="videoeHealthPro2"
              key={locale}
              className="second-responsive-iframe"
              title={t("Video des")}
              src="https://www.youtube.com/embed/QylTQEawXkM"
              alt={t("Video")}
              tabIndex="0"
            ></iframe>
          </div>
        </div>

        <div className="container-fluid ">
          <div className="container">
            <div className="ehealthpro-faq-entry ">
              <div>
                <img
                  className="pro-faq-img"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_faq_graphic_1.png`
                  )}
                />
                <span className="pro-faq-entry-text">
                  {t("For more FAQs on eHealth Pro, please refer to this ")}
                  <NavLink to="/ehealth-pro-faq">{t("web page")}.</NavLink>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="empty-space-100"></div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(EhealthProPage);

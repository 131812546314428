import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import load from "../../ImageLoder";
import PageHOC from "../../PageHOC";
import CommonPhoneSlider from "../CommonPhoneSlider";
import Footer from "../Footer";

class EhealthProInfoSharingPage extends Component {
  render() {
    const { locale, t } = this.props;
    const sliderImages = ["ehealthpro_info_share_slider_phone1.png"];
    const sliderImageAlts = ["Screenshot of eHealthPro and Medications"];
    const sliderImageIds = ["img1eHealthProSlider"];
    const phoneSlider = (
      <CommonPhoneSlider
        backgoundImg={"pro-info-sharing-bg.png"}
        sliderID="eHealthProSlider"
        sliderTitle={t("Pro Information Sharing")}
        sliderTitleID={"texteHealthProAccSliderTitle"}
        sliderText={t(
          "Pro Access to eHealth relevant information anytime, anywhere"
        )}
        sliderTextID="texteHealthProSliderContent"
        sliderImages={sliderImages}
        sliderImageAlts={sliderImageAlts}
        sliderImageIds={sliderImageIds}
        autoPlay={5}
        {...this.props}
      />
    );
    return (
      <div
        className={`page-container page-container-${locale} ehealthpro-color single-phone`}
        id="MainContent"
      >
        {phoneSlider}

        <div className="container-fluid  ehealthpro-bg">
          <div className="container">
            <div className="row vertical-align">
              <div
                data-aos="fade-left"
                className="col-md-6 col-sm-12 col-sm-push-6"
              >
                <div
                  id="texteHealthProSection1Title"
                  className="second-feature-title"
                >
                  {t("Stay updated with the latest eHealth information")}
                </div>
              </div>

              <div className="col-md-6 col-sm-12 col-sm-pull-6">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1eHealthProAccSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="100"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_info_share_p1_1.png`
                  )}
                  alt={t("Make Login to eHRSS Safe, Simple and Fast")}
                />
                <img
                  id="img2eHealthProAccSection1"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_info_share_p1_2.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <div className="container-fluid  ehealthpro-light-bg ">
          <div className="container">
            <div className="row vertical-align">
              <div data-aos="fade-right" className="col-md-6 col-sm-12">
                <div
                  id="textMyFamilySection2Title"
                  className="second-feature-title"
                >
                  {t("Share eHealth documents more easily with your partners")}
                </div>
              </div>
              <div className="col-md-6 col-sm-12">
                <img src={load(`./resources/images/empty.png`)} alt="" />
                <img
                  id="img1eHealthProSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  className="absolute-image back"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_info_share_p2_1.png`
                  )}
                  alt=" "
                />
                <img
                  id="img2eHealthProSection2"
                  data-aos="fade-up"
                  data-aos-anchor-placement="center-center"
                  data-aos-offset="-300"
                  data-aos-delay="300"
                  className="absolute-image middle"
                  src={load(
                    `./resources/images/ehealth-pro/ehealth_pro_info_share_p2_2.png`
                  )}
                  alt=" "
                />
              </div>
            </div>
          </div>
        </div>

        <Footer {...this.props} />
      </div>
    );
  }
}

export default PageHOC(EhealthProInfoSharingPage);
